<template>
    <div class="h-full p-4">
        <div class="flex justify-center mt-8 md:mt-4">
            <div class="w-full max-w-5xl desktop:max-w-7xl">
                <h2 class="font-medium text-left text-2xl mb-5">Overview</h2>
                <div class="card">
                    <div class="flex justify-center">
                        <div class="w-full max-w-5xl desktop:max-w-7xl">
                            <div
                                v-if="displayType === 0"
                                class="
                                    grid grid-cols-1
                                    md:grid-cols-2
                                    justify-between
                                    gap-4
                                    md:gap-x-24
                                    desktop:gap-x-36
                                    my-4
                                "
                            >
                                <div
                                    v-if="displayType === 0"
                                    class="
                                        grid grid-cols-1
                                        md:grid-cols-2
                                        gap-4
                                        md:gap-2
                                    "
                                >
                                    <div class="yellow-card">
                                        {{ $t("overview.confirmed_booking") }}
                                        <div
                                            v-if="!isLoading"
                                            class="font-bold text-lg md:text-xl"
                                        >
                                            {{
                                                tableData.totalBooking > 0
                                                    ? tableData.totalBooking
                                                    : "0"
                                            }}
                                        </div>
                                        <div
                                            v-else
                                            class="
                                                flex
                                                justify-center
                                                items-center
                                            "
                                        >
                                            <div
                                                class="
                                                    bg-white
                                                    h-7
                                                    w-6
                                                    animate-pulse
                                                "
                                            ></div>
                                        </div>
                                    </div>
                                    <div class="gray-card">
                                        {{ $t("overview.cancelled_booking") }}
                                        <div
                                            v-if="!isLoading"
                                            class="font-bold text-lg md:text-xl"
                                        >
                                            {{
                                                tableData.totalCancelledBooking >
                                                0
                                                    ? tableData.totalCancelledBooking
                                                    : "0"
                                            }}
                                        </div>
                                        <div
                                            v-else
                                            class="
                                                flex
                                                justify-center
                                                items-center
                                            "
                                        >
                                            <div
                                                class="
                                                    bg-white
                                                    h-7
                                                    w-6
                                                    animate-pulse
                                                "
                                            ></div>
                                        </div>
                                    </div>
                                </div>
                                <div class="yellow-card">
                                    {{ $t("overview.total_price") }}
                                    <div
                                        v-if="!isLoading"
                                        class="font-bold text-lg md:text-xl"
                                    >
                                        {{
                                            tableData.totalBookedPrice > 0
                                                ? tableData.totalBookedPrice
                                                : "0.00"
                                        }}
                                    </div>
                                    <div
                                        v-else
                                        class="flex justify-center items-center"
                                    >
                                        <div
                                            class="
                                                bg-white
                                                h-7
                                                w-6
                                                animate-pulse
                                            "
                                        ></div>
                                    </div>
                                </div>
                                <div
                                    v-if="displayType === 0"
                                    class="
                                        grid grid-cols-1
                                        md:grid-cols-2
                                        gap-4
                                        md:gap-2
                                    "
                                >
                                    <div
                                        class="
                                            desktop:text-lg
                                            font-medium
                                            flex
                                            items-center
                                        "
                                    >
                                        Total Bookings:
                                        <span v-if="!isLoading" class="ml-1">{{
                                            getTotalBookings()
                                        }}</span>
                                        <span v-else class="ml-1"
                                            ><Loader type="xs"
                                        /></span>
                                    </div>
                                    <div
                                        class="desktop:text-lg font-medium flex"
                                    >
                                        Cancellation Rate:
                                        <span v-if="!isLoading" class="ml-1">{{
                                            getCancellationRate()
                                        }}</span>
                                        <span v-else class="ml-1"
                                            ><Loader type="xs"
                                        /></span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <PriceComparison
                        v-if="isReady"
                        class="border rounded-md py-1 px-2 mb-4"
                        :country_code="params.region"
                        :id="params.spaceID"
                    />
                    <div class="flex justify-center">
                        <div
                            class="
                                w-full
                                max-w-5xl
                                desktop:max-w-7xl
                                mx-auto
                                rounded
                                border border-gray-300
                            "
                        >
                            <DateRangeCalendar
                                :time-mode="timeToggle"
                                :custom-range="customRange"
                                :availability="availability"
                                @onSelect="selectDate"
                                @onLoad="monthChange"
                                @onChange="monthChange"
                                @modeChange="modeChange"
                            />
                        </div>
                    </div>
                    <div class="flex justify-end mt-2">
                        <button
                            @click="changeType(0)"
                            class="mr-2"
                            :class="{
                                'small-btn': displayType === 0,
                                'small-btn-inactive': displayType !== 0
                            }"
                            :disabled="displayType === 0"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-list
                                "
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <line x1="9" y1="6" x2="20" y2="6" />
                                <line x1="9" y1="12" x2="20" y2="12" />
                                <line x1="9" y1="18" x2="20" y2="18" />
                                <line x1="5" y1="6" x2="5" y2="6.01" />
                                <line x1="5" y1="12" x2="5" y2="12.01" />
                                <line x1="5" y1="18" x2="5" y2="18.01" />
                            </svg>
                        </button>
                        <button
                            @click="changeType(1)"
                            :class="{
                                'small-btn': displayType === 1,
                                'small-btn-inactive': displayType !== 1
                            }"
                            :disabled="displayType === 1"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-chart-bar
                                "
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <rect
                                    x="3"
                                    y="12"
                                    width="6"
                                    height="8"
                                    rx="1"
                                />
                                <rect
                                    x="9"
                                    y="8"
                                    width="6"
                                    height="12"
                                    rx="1"
                                />
                                <rect
                                    x="15"
                                    y="4"
                                    width="6"
                                    height="16"
                                    rx="1"
                                />
                                <line x1="4" y1="20" x2="18" y2="20" />
                            </svg>
                        </button>
                        <button
                            @click="changeType(2)"
                            class="ml-2"
                            :class="{
                                'small-btn': displayType === 2,
                                'small-btn-inactive': displayType !== 2
                            }"
                            :disabled="displayType === 2"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-list-details
                                "
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M13 5h8" />
                                <path d="M13 9h5" />
                                <path d="M13 15h8" />
                                <path d="M13 19h5" />
                                <rect x="3" y="4" width="6" height="6" rx="1" />
                                <rect
                                    x="3"
                                    y="14"
                                    width="6"
                                    height="6"
                                    rx="1"
                                />
                            </svg>
                        </button>
                        <button
                            @click="changeType(3)"
                            class="ml-2"
                            :class="{
                                'small-btn': displayType === 3,
                                'small-btn-inactive': displayType !== 3,
                                'filter grayscale': selectedMode !== 0
                            }"
                            :disabled="displayType === 3 || selectedMode !== 0"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-home-2
                                "
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path d="M5 12l-2 0l9 -9l9 9l-2 0" />
                                <path
                                    d="M5 12v7a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-7"
                                />
                                <path d="M10 12h4v4h-4z" />
                            </svg>
                        </button>
                        <button
                            @click="changeType(4)"
                            class="ml-2"
                            :class="{
                                'small-btn': displayType === 4,
                                'small-btn-inactive': displayType !== 4,
                                'filter grayscale': selectedMode !== 0
                            }"
                            :disabled="displayType === 4 || selectedMode !== 0"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    stroke-current
                                    icon icon-tabler icon-tabler-home-dollar
                                "
                                width="28"
                                height="28"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <path
                                    d="M19 10l-7 -7l-9 9h2v7a2 2 0 0 0 2 2h6"
                                />
                                <path
                                    d="M9 21v-6a2 2 0 0 1 2 -2h2c.387 0 .748 .11 1.054 .3"
                                />
                                <path
                                    d="M21 15h-2.5a1.5 1.5 0 0 0 0 3h1a1.5 1.5 0 0 1 0 3h-2.5"
                                />
                                <path d="M19 21v1m0 -8v1" />
                            </svg>
                        </button>
                    </div>
                    <div v-if="displayType === 0" class="overflow-scroll">
                        <table
                            v-if="params.spaceID !== 'All'"
                            class="mt-8 table-fixed min-w-full divide-gray-600"
                        >
                            <thead>
                                <tr class="border-b">
                                    <th
                                        class="
                                            w-1/6
                                            pl-4
                                            py-3
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            tracking-wider
                                        "
                                    >
                                        Check-in Date
                                    </th>
                                    <th
                                        class="
                                            w-1/6
                                            px-4
                                            py-3
                                            rounded-r-md
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            tracking-wider
                                        "
                                    >
                                        Number of Bookings (#)
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="
                                    !isLoading &&
                                    customRange.strDate &&
                                    customRange.endDate
                                "
                                class="bg-white"
                            >
                                <tr
                                    class="
                                        bg-white
                                        text-left
                                        font-medium
                                        text-tiny
                                        ease-linear
                                        duration-200
                                        transition
                                        border-b border-gray-200
                                    "
                                    v-for="(
                                        record, index
                                    ) in tableData.hotel_detail_list.sort(
                                        (a, b) =>
                                            a.CHECK_IN.localeCompare(b.CHECK_IN)
                                    )"
                                    :key="index"
                                >
                                    <td class="pl-4 py-3">
                                        {{ record.CHECK_IN }}
                                    </td>
                                    <td class="pl-4">
                                        {{ record.NUMBER_OF_BOOKING }}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            class="mt-4 font-medium text-xl text-center"
                            v-if="
                                !isLoading &&
                                tableData.statisticsRecord &&
                                tableData.statisticsRecord.length < 1
                            "
                        >
                            No result found
                        </div>
                        <div
                            v-if="isLoading"
                            class="
                                relative
                                flex
                                justify-center
                                items-center
                                py-8
                            "
                        >
                            <Loader type="lg" />
                        </div>
                    </div>
                    <div
                        v-if="
                            displayType === 1 &&
                            customRange.strDate &&
                            customRange.endDate
                        "
                        class="overflow-scroll"
                    >
                        <div v-if="!isLoading">
                            <div
                                id="revenueChart"
                                class="
                                    flex
                                    md:justify-center
                                    mt-4
                                    h-80
                                    overflow-x-scroll
                                "
                            ></div>
                            <div
                                id="numChart"
                                class="
                                    flex
                                    md:justify-center
                                    mt-4
                                    h-80
                                    overflow-x-scroll
                                "
                            ></div>
                        </div>
                        <div
                            v-else
                            class="
                                relative
                                flex
                                justify-center
                                items-center
                                py-8
                            "
                        >
                            <Loader type="lg" />
                        </div>
                    </div>
                    <div v-if="displayType === 2" class="overflow-scroll">
                        <table
                            v-if="params.spaceID !== 'All'"
                            class="mt-8 table-auto min-w-full divide-gray-600"
                        >
                            <thead>
                                <tr class="border-b">
                                    <th
                                        class="
                                            pl-4
                                            py-3
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            tracking-wider
                                        "
                                    >
                                        Room Type
                                    </th>
                                    <th
                                        v-for="(type, index) in bookType"
                                        :key="index"
                                        class="
                                            pl-4
                                            py-3
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            tracking-wider
                                        "
                                    >
                                        {{ type }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody
                                v-if="
                                    !isLoading &&
                                    customRange.strDate &&
                                    customRange.endDate
                                "
                                class="bg-white"
                            >
                                <tr
                                    class="
                                        bg-white
                                        text-left
                                        font-medium
                                        text-tiny
                                        ease-linear
                                        duration-200
                                        transition
                                        border-b border-gray-200
                                    "
                                    v-for="(room, name, index) in breakdownData"
                                    :key="index"
                                >
                                    <td class="pl-4 py-3">
                                        {{ name }}
                                    </td>
                                    <td
                                        v-for="(session, idx) in bookType"
                                        :key="idx"
                                        class="pl-4 py-3"
                                    >
                                        <div v-if="room[session]">
                                            <div class="flex items-center">
                                                Bookings:
                                                {{
                                                    room[session]
                                                        .reservation_count
                                                }}
                                            </div>
                                            <div class="flex items-center">
                                                Availability:
                                                {{ room[session].availability }}
                                            </div>
                                        </div>
                                        <div v-else>N/A</div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        <div
                            class="mt-4 font-medium text-xl"
                            v-if="!isLoading && bookType && bookType.length < 1"
                        >
                            No result found
                        </div>
                        <div
                            v-if="isLoading"
                            class="
                                relative
                                flex
                                justify-center
                                items-center
                                py-8
                            "
                        >
                            <Loader type="lg" />
                        </div>
                    </div>
                    <AuditLog
                        v-if="displayType === 3 || displayType === 4"
                        :type="auditLogType"
                        :user="props.user"
                        :selected-space="props.selectedSpace"
                        :date="customRange.strDate"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, reactive, watch } from "vue"
import axios from "axios"
import { GoogleCharts } from "google-charts"
import Loader from "@/components/Loader"
import DateRangeCalendar from "@/components/DateRangeCalendar"
import PriceComparison from "@/components/PriceComparison"
import AuditLog from "@/components/AuditLog"

const props = defineProps({
    user: { required: true, type: Object },
    selectedSpace: { required: true, type: Number },
    spaceID: { required: false, type: String },
    spaceType: { required: false, type: Number }
})

let spaceList = ref()
let filteredList = ref()
let timeToggle = ref(0)
let params = reactive({
    region: null,
    spaceID: "",
    spaceType: "1",
    calMonth: ""
})

let isLoading = ref(true)

let displayType = ref(0)

let selectedMode = ref(1)

let tableData = ref({})
let chartData = ref({})
let availability = ref({})
let bookType = ref([])
let breakdownData = ref({})
let isReady = ref(false)
let customRange = reactive({ strDate: null, endDate: null })

let auditLogType = ref("")

onMounted(async () => {
    getToday()
    await initialize()
})

async function initialize() {
    params.spaceID = props.spaceID
    params.spaceType = props.spaceType
    params.region = props.spaceID.substring(0, 2)
    isReady.value = true
    setTimeout(async () => {
        await getData(1)
    }, 300)
}

function getToday() {
    let date = new Date()
    let year = date.getFullYear()
    let month = date.getMonth()
    let max = new Date(year, month + 1, 0).getDate()
    customRange.strDate = `${year}-${
        month + 1 < 10 ? "0" + (month + 1) : month + 1
    }-01`
    customRange.endDate = `${year}-${
        month + 1 < 10 ? "0" + (month + 1) : month + 1
    }-${max < 10 ? "0" + max : max}`
}

function getData(val) {
    clearData()
    val ? (availability.value = {}) : null
    if (Object.keys(availability.value).length === 0) {
        getAvailability()
    }
    switch (displayType.value) {
        case 0:
            return getStatsInfo()
        case 1:
            return getChartData()
        case 2:
            return getStatsBreakdown()
    }
}

function clearData() {
    chartData.value = {}
    tableData.value = {}
    breakdownData.value = {}
    bookType.value = []
    // availability.value = {}
}

function modeChange(val) {
    let d = `${val.year}-${val.month < 10 ? "0" + val.month : val.month}`
    selectedMode.value = val.mode
    if (
        val.mode !== 0 &&
        (displayType.value === 3 || displayType.value === 4)
    ) {
        displayType.value = 0
    }
    if (val.mode === 1) {
        customRange.strDate = d + "-01"
        customRange.endDate = d + `-${val.max}`
        getData()
    } else {
        clearData()
        customRange.strDate = customRange.endDate = null
        getAvailability()
    }
}

function monthChange(val) {
    let d = `${val.year}-${val.month < 10 ? "0" + val.month : val.month}`
    params.calMonth = d
    if (val.mode === 1) {
        customRange.strDate = d + "-01"
        customRange.endDate = d + `-${val.max}`
        getData(1)
    } else {
        availability.value = {}
        getAvailability()
    }
}

function selectDate(val) {
    let d = `${val.year}-${val.month < 10 ? "0" + val.month : val.month}-${
        val.date < 10 ? "0" + val.date : val.date
    }`
    if (val.mode === 0) {
        customRange.strDate = customRange.endDate = d
    }
    if (val.mode === 2) {
        if (!customRange.strDate || d < customRange.strDate) {
            customRange.strDate = d
        } else if (customRange.endDate !== d) {
            customRange.endDate = d
        } else {
            customRange.strDate = d
            customRange.endDate = null
        }
    }
    getData()
}

function changeType(val) {
    if (val === 1) {
        displayType.value = 1
        getChartData()
    }
    if (val === 0) {
        displayType.value = 0
        getStatsInfo()
    }
    if (val === 2) {
        displayType.value = 2
        getStatsBreakdown()
    }
    if (val === 3) {
        displayType.value = val
        auditLogType.value = "availability"
    }
    if (val === 4) {
        displayType.value = val
        auditLogType.value = "pricing"
    }
}

async function getStatsInfo() {
    if (customRange.strDate && customRange.endDate) {
        isLoading.value = true
        const url = `${process.env.VUE_APP_API_BASE_URL}/overview/infoStatistics?space_type=${params.spaceType}&space_id=${params.spaceID}&selected_from=${customRange.strDate}&selected_to=${customRange.endDate}&country_code=${params.region}`
        const r = await axios.get(url)
        if (r.data.Success) {
            tableData.value = r.data.data
            isLoading.value = false
        }
    }
}

async function getChartData() {
    if (customRange.strDate && customRange.endDate) {
        isLoading.value = true
        const url = `${process.env.VUE_APP_API_BASE_URL}/overview/chartDisplay?space_id=${params.spaceID}&space_type=${params.spaceType}&selected_from=${customRange.strDate}&selected_to=${customRange.endDate}&country_code=${params.region}&range=6`
        const r = await axios.get(url)
        if (r.data.Success) {
            chartData.value = r.data.data
            drawChart()
            isLoading.value = false
        }
    }
}

async function getAvailability() {
    const url = `${process.env.VUE_APP_API_BASE_URL}/overview/getMonthlyAvailability?space_id=${params.spaceID}&space_type=${params.spaceType}&selected_month=${params.calMonth}&country_code=${params.region}`
    const r = await axios.get(url)
    availability.value = r.data.data.each_day_spec
}

async function getStatsBreakdown() {
    if (customRange.strDate && customRange.endDate) {
        const url = `${process.env.VUE_APP_API_BASE_URL}/overview/getPeriodBreakdown?space_id=${params.spaceID}&space_type=${params.spaceType}&selected_start_date=${customRange.strDate}&selected_end_date=${customRange.endDate}&country_code=${params.region}`
        isLoading.value = true
        const r = await axios.get(url)
        if (
            r.data.Success &&
            Object.keys(r.data.data.period_total_spec).length > 0
        ) {
            for (
                let i = 0;
                i < Object.keys(r.data.data.period_total_spec).length;
                i++
            ) {
                let tempObj =
                    r.data.data.period_total_spec[
                        Object.keys(r.data.data.period_total_spec)[i]
                    ]
                for (let j = 0; j < Object.keys(tempObj).length; j++) {
                    if (!bookType.value.includes(Object.keys(tempObj)[j])) {
                        bookType.value.push(Object.keys(tempObj)[j])
                    }
                }
            }
            breakdownData.value = r.data.data.period_total_spec
            isLoading.value = false
        }
    }
}

function drawChart() {
    let gData = chartData.value
    GoogleCharts.load(
        function () {
            {
                // Standard google charts functionality is available as GoogleCharts.api after load
                const data = new GoogleCharts.api.visualization.DataTable()
                data.addColumn("string", "Date")
                data.addColumn("number", "Revenue")
                data.addColumn({ type: "string", role: "annotation" })

                let rows = []
                let max = gData[Object.keys(gData)[0]]
                for (let i in gData) {
                    if (gData[i].total_book_price > max.total_book_price) {
                        max = gData[i]
                    }
                    let d
                    if (selectedMode.value === 0) {
                        d = i.split(" ")[0]
                    }
                    if (selectedMode.value === 1) {
                        d =
                            i.split(" ")[0].split("-")[0] +
                            "-" +
                            i.split(" ")[0].split("-")[1]
                    }
                    if (selectedMode.value === 2) {
                        d = i.split(" ")[0] + " - " + i.split(" ")[3]
                    }
                    rows.push([d, gData[i].total_book_price, null])
                }
                rows = rows.reverse()
                for (let i in rows) {
                    if (
                        i > 0 &&
                        rows[i][1] !== rows[i - 1][1] &&
                        rows[i - 1][1] !== 0
                    ) {
                        rows[i][2] =
                            (
                                (((rows[i][1] === 0 ? 1 : rows[i][1]) -
                                    (rows[i - 1][1] === 0
                                        ? 1
                                        : rows[i - 1][1])) /
                                    (rows[i - 1][1] === 0
                                        ? 1
                                        : rows[i - 1][1])) *
                                100
                            ).toFixed(3) + "%"
                    }
                }
                data.addRows(rows)
                const chart = new GoogleCharts.api.visualization.LineChart(
                    document.getElementById("revenueChart")
                )
                let options = {
                    annotations: {
                        textStyle: {
                            color: "#ca2c2c"
                        }
                    },
                    legend: {
                        position: "top",
                        alignment: "center"
                    },
                    vAxis: {
                        viewWindow: {
                            max: max.total_book_price * 1.5
                        }
                    }
                }
                chart.draw(data, options)
                let container = document.getElementById("revenueChart")
                for (
                    let i = 0;
                    i < container.getElementsByTagName("text").length;
                    i++
                ) {
                    let text = container.getElementsByTagName("text")[i]
                    if (
                        text.getAttribute("text-anchor") === "middle" &&
                        text.getAttribute("fill") === "#ca2c2c" &&
                        parseFloat(text.innerHTML) >= 0
                    ) {
                        text.setAttribute("fill", "green")
                    }
                }
                let timer
                window.addEventListener("resize", () => {
                    clearTimeout(timer)
                    timer = setTimeout(chart.draw(data, options), 200)
                })
            }
        },
        { packages: ["corechart", "line"] }
    )
    GoogleCharts.load(
        function () {
            {
                // Standard google charts functionality is available as GoogleCharts.api after load
                const data = new GoogleCharts.api.visualization.DataTable()
                data.addColumn("string", "Date")
                data.addColumn("number", "Number of Booking(s)")
                data.addColumn({ type: "string", role: "annotation" })

                let rows = []
                let max = gData[Object.keys(gData)[0]]
                for (let i in gData) {
                    if (gData[i].num_of_booking > max.num_of_booking) {
                        max = gData[i]
                    }
                    let d
                    if (selectedMode.value === 0) {
                        d = i.split(" ")[0]
                    }
                    if (selectedMode.value === 1) {
                        d =
                            i.split(" ")[0].split("-")[0] +
                            "-" +
                            i.split(" ")[0].split("-")[1]
                    }
                    if (selectedMode.value === 2) {
                        d = i.split(" ")[0] + " - " + i.split(" ")[3]
                    }
                    rows.push([d, gData[i].num_of_booking, null])
                }
                rows = rows.reverse()
                for (let i in rows) {
                    if (
                        i > 0 &&
                        rows[i][1] !== rows[i - 1][1] &&
                        rows[i - 1][1] !== 0
                    ) {
                        rows[i][2] =
                            (
                                (((rows[i][1] === 0 ? 1 : rows[i][1]) -
                                    (rows[i - 1][1] === 0
                                        ? 1
                                        : rows[i - 1][1])) /
                                    (rows[i - 1][1] === 0
                                        ? 1
                                        : rows[i - 1][1])) *
                                100
                            ).toFixed(3) + "%"
                    }
                }
                data.addRows(rows)
                const chart = new GoogleCharts.api.visualization.LineChart(
                    document.getElementById("numChart")
                )
                let options = {
                    annotations: {
                        textStyle: {
                            color: "#ca2c2c"
                        }
                    },
                    legend: {
                        position: "top",
                        alignment: "center"
                    },
                    vAxis: {
                        viewWindow: {
                            max: max.num_of_booking * 1.5
                        }
                    }
                }
                chart.draw(data, options)
                let container = document.getElementById("numChart")
                for (
                    let i = 0;
                    i < container.getElementsByTagName("text").length;
                    i++
                ) {
                    let text = container.getElementsByTagName("text")[i]
                    if (
                        text.getAttribute("text-anchor") === "middle" &&
                        text.getAttribute("fill") === "#ca2c2c" &&
                        parseFloat(text.innerHTML) >= 0
                    ) {
                        text.setAttribute("fill", "green")
                    }
                }
                let timer
                window.addEventListener("resize", () => {
                    clearTimeout(timer)
                    timer = setTimeout(chart.draw(data, options), 200)
                })
            }
        },
        { packages: ["corechart", "line"] }
    )
}

function getTotalBookings() {
    let a = 0,
        b = 0
    if (!isNaN(parseFloat(tableData.value.totalBooking))) {
        a = parseFloat(tableData.value.totalBooking)
    }
    if (!isNaN(parseFloat(tableData.value.totalCancelledBooking))) {
        b = parseFloat(tableData.value.totalCancelledBooking)
    }
    return a + b
}

function getCancellationRate() {
    let a = 0
    if (!isNaN(parseFloat(tableData.value.totalCancelledBooking))) {
        a = parseFloat(tableData.value.totalCancelledBooking)
    }
    if (getTotalBookings() > 0) {
        return ((a / getTotalBookings()) * 100).toFixed(1) + "%"
    } else return "N/A"
}

watch(props, async (newSpace, oldSpace) => {
    await initialize()
})
</script>

<style scoped></style>
