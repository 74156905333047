<template>
    <div class="mt-4 grid grid-cols-3 text-sm md:text-base items-center">
        <div
            class="w-full flex items-center col-span-1"
            :class="{ invisible: !props.pageSize }"
        >
            <input
                v-model="pg"
                @change="updatePageSize(pg)"
                class="
                    border
                    w-full
                    pl-1
                    border-gray-300
                    rounded-md
                    mr-2
                    focus:outline-none
                "
            />
            <span class="whitespace-nowrap">per page</span>
        </div>
        <div class="mx-auto flex items-center bg-yellow-400 rounded-md my-0">
            <button
                v-if="props.totalPage"
                @click="firstPage"
                class="hidden md:block border-r border-white focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevrons-left"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="11 7 6 12 11 17" />
                    <polyline points="17 7 12 12 17 17" />
                </svg>
            </button>
            <button
                @click="prevPage"
                class="border-r border-white focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-left"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="15 6 9 12 15 18" />
                </svg>
            </button>
            <div class="bg-yellow-400 text-white px-2">
                {{ props.currentPage
                }}{{ props.totalPage ? ` / ${props.totalPage}` : "" }}
            </div>
            <button
                @click="nextPage"
                class="border-l border-white focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevron-right"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                </svg>
            </button>
            <button
                v-if="props.totalPage"
                @click="lastPage"
                class="hidden md:block border-l border-white focus:outline-none"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="icon icon-tabler icon-tabler-chevrons-right"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    stroke-width="1.5"
                    stroke="#ffffff"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="7 7 12 12 7 17" />
                    <polyline points="13 7 18 12 13 17" />
                </svg>
            </button>
        </div>
        <div class="flex w-full justify-end">
            <span class="whitespace-nowrap">Go to page:</span>
            <input
                ref="pageNum"
                :value="props.currentPage"
                @change="specificPage($event.target.value)"
                class="
                    border
                    pl-1
                    w-full
                    border-gray-300
                    rounded-md
                    ml-2
                    focus:outline-none
                "
            />
        </div>
    </div>
</template>

<script setup>
import { ref, defineProps, defineEmits } from "vue"

const props = defineProps({
    pageSize: { required: false, type: Number },
    currentPage: { required: true, type: Number },
    totalPage: { required: false, type: Number }
})

let pg = ref(props.pageSize)
const emit = defineEmits([
    "prevPage",
    "nextPage",
    "firstPage",
    "lastPage",
    "updatePageSize",
    "specificPage"
])

function updatePageSize(val) {
    emit("updatePageSize", val)
}

function prevPage() {
    emit("prevPage")
}

function nextPage() {
    emit("nextPage")
}

function firstPage() {
    emit("firstPage")
}

function lastPage() {
    emit("lastPage")
}

function specificPage(val) {
    emit("specificPage", val)
}
</script>

<style scoped></style>
