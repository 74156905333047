<template>
    <div>
        <div class="flex justify-center">
            <div class="w-full">
                <div class="mt-4 md:flex items-center">
                    <div class="flex items-center mr-2">
                        <div class="h-4 w-4 bg-green-500 mr-1"></div>
                        Outstanding
                    </div>
                    <div class="flex items-center mr-2">
                        <div class="h-4 w-4 bg-yellow-400 mr-1"></div>
                        Very Satisfactory
                    </div>
                    <div class="flex items-center mr-2">
                        <div class="h-4 w-4 bg-orange-500 mr-1"></div>
                        Satisfactory
                    </div>
                    <div class="flex items-center mr-2">
                        <div class="h-4 w-4 bg-red-500 mr-1"></div>
                        Unsatisfactory
                    </div>
                </div>
                <div>
                    <div class="overflow-scroll pb-4">
                        <table class="table-fixed min-w-full divide-gray-600">
                            <thead>
                                <tr class="border-b">
                                    <th
                                        class="
                                            py-3
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            whitespace-nowrap
                                            tracking-wider
                                        "
                                    >
                                        Price Comparison
                                    </th>
                                    <th
                                        v-for="(date, index) in days"
                                        :key="index"
                                        class="
                                            pr-4
                                            py-3
                                            text-left text-sm
                                            font-medium
                                            text-gray-500
                                            whitespace-nowrap
                                            tracking-wider
                                        "
                                        :class="{
                                            'rounded-r-md':
                                                index === days.length - 1
                                        }"
                                    >
                                        {{ date }}
                                    </th>
                                </tr>
                            </thead>
                            <tbody v-if="!isLoading" id="table">
                                <tr
                                    v-for="(space, index) in tableData"
                                    :key="index"
                                    class="h-full"
                                    :class="{
                                        'border-b':
                                            index !== tableData.length - 1
                                    }"
                                >
                                    <td
                                        class="
                                            text-left text-sm
                                            whitespace-nowrap
                                        "
                                    >
                                        <div>
                                            {{ space.space_name }}
                                        </div>
                                        <div
                                            v-if="space.flow_last_update"
                                            class="text-xs"
                                        >
                                            Flow last update:<span
                                                class="font-bold text-sm"
                                                >{{
                                                    space.flow_last_update
                                                }}</span
                                            >
                                        </div>
                                        <div
                                            v-if="space.eps_last_update"
                                            class="text-xs"
                                        >
                                            Eps last update:<span
                                                class="font-bold text-sm"
                                                >{{
                                                    space.eps_last_update
                                                }}</span
                                            >
                                        </div>
                                    </td>
                                    <td
                                        v-for="(date, i) in days"
                                        :key="i"
                                        class="h-full"
                                    >
                                        <ComparisonCell
                                            class="h-full"
                                            v-if="
                                                space.pricesByDate &&
                                                space.pricesByDate.some(
                                                    (e) => e.date === date
                                                )
                                            "
                                            :country="country_code"
                                            :price="
                                                space.pricesByDate.filter(
                                                    (e) => e.date === date
                                                )[0]
                                            "
                                        />
                                        <div
                                            v-else
                                            class="
                                                bg-gray-300
                                                text-white
                                                h-full
                                                py-0.5
                                            "
                                        >
                                            &nbsp;
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div
                        class="my-2 text-center font-medium text-xl"
                        v-if="!isLoading && tableData.length === 0"
                    >
                        No result found
                    </div>
                    <div
                        v-if="isLoading"
                        class="relative flex justify-center items-center py-8"
                    >
                        <Loader />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, reactive, watch } from "vue"
import axios from "axios"
import Loader from "@/components/Loader"
import ComparisonCell from "@/components/ComparisonCell"

const props = defineProps({
    id: { required: true, type: String },
    country_code: { required: true, type: String }
})

let isLoading = ref(true)
let params = reactive({
    region: null,
    spaceID: ["All"],
    spaceType: "1",
    calMonth: ""
})
let tableData = ref([])
let days = ref([])
let customRange = reactive({ strDate: null, endDate: null })

onMounted(async () => {
    getToday()
})

function formatDate(date) {
    let year = date.getFullYear()
    let month = date.getMonth()
    let d = date.getDate()
    return `${year}-${month + 1 < 10 ? "0" + (month + 1) : month + 1}-${
        d < 10 ? "0" + d : d
    }`
}

function getToday() {
    let date = new Date()
    let end_date = new Date()
    end_date = new Date(end_date.setDate(end_date.getDate() + 28))
    customRange.strDate = formatDate(date)
    customRange.endDate = formatDate(end_date)
    getDays()
    getData()
}

function getDays() {
    days.value = getDaysArray(
        new Date(customRange.strDate),
        new Date(customRange.endDate)
    )
}

function getDaysArray(start, end) {
    let arr = []
    for (let dt = start; dt <= end; dt.setDate(dt.getDate() + 1)) {
        let newDate = new Date(dt)
        arr.push(formatDate(new Date(newDate.setHours(23, 59, 59, 0))))
    }
    return arr
}

async function getData() {
    tableData.value = []
    if (customRange.strDate && customRange.endDate) {
        isLoading.value = true
        const url = `${process.env.VUE_APP_API_BASE_URL}/price/getpriceComparison?country_code=${props.country_code}&space_id_list=${props.id}&start_date=${customRange.strDate}&end_date=${customRange.endDate}`
        const r = await axios.get(url)
        if (r.data.Success) {
            if (r.data.data) {
                tableData.value = r.data.data
            }
            isLoading.value = false
        }
    }
}

watch(props, async (newSpace, oldSpace) => {
    await getData()
})
</script>

<style scoped>
td {
    height: 0;
}

@-moz-document url-prefix() {
    td {
        height: 100%;
    }
}
</style>
