<template>
    <div class="p-8 mt-4 lg:mt-0 lg:px-20 desktop:px-36 bg-gray-100">
        <h2 class="flex font-medium text-2xl mb-5">Upcoming Booking</h2>
        <div class="flex justify-center">
            <div
                class="bg-white mt-8 p-8 w-full max-w-7xl rounded-md shadow-lg"
            >
                <div
                    class="
                        hidden
                        lg:grid
                        grid-cols-2
                        w-full
                        grid-flow-row
                        gap-x-20
                    "
                >
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">Room Type</h2>
                        <select class="border w-full"></select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">Book Type</h2>
                        <select class="border w-full"></select>
                    </div>
                </div>
                <div class="block lg:hidden w-full">
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">Room Type</h2>
                        <select class="border w-full"></select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">Book Type</h2>
                        <select class="border w-full"></select>
                    </div>
                </div>
                <div
                    v-for="index in 3"
                    :key="index"
                    class="border-gray-200 py-4 border-b"
                >
                    <div class="hidden lg:grid grid-cols-4 items-center">
                        <div class="flex items-center">
                            <div
                                class="
                                    bg-gray-300
                                    w-8
                                    h-8
                                    mr-2
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                            <div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        mb-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="flex items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        my-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        my-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                        <div>
                            <div
                                class="
                                    bg-gray-300
                                    w-40
                                    h-8
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                        </div>
                        <div class="flex flex-col items-center">
                            <div
                                class="
                                    h-14
                                    w-14
                                    mb-2
                                    bg-gray-300
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                            <div
                                class="
                                    bg-gray-300
                                    w-40
                                    h-4
                                    mb-2
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                            <div
                                class="
                                    bg-gray-300
                                    w-40
                                    h-4
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                        </div>
                    </div>
                    <div class="block lg:hidden">
                        <div class="flex items-center">
                            <div
                                class="
                                    bg-gray-300
                                    w-8
                                    h-6
                                    mr-2
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                            <div
                                class="
                                    bg-gray-300
                                    w-40
                                    h-6
                                    max-w-sm
                                    animate-pulse
                                "
                            >
                                &nbsp;
                            </div>
                        </div>
                        <div class="mt-4">
                            <div class="flex items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex items-center my-2">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-5
                                        h-4
                                        mr-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-40
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex items-center justify-center mt-4">
                                <div
                                    class="
                                        bg-gray-300
                                        w-20
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                            <div class="flex flex-col items-center">
                                <div
                                    class="
                                        bg-gray-300
                                        w-12
                                        h-12
                                        mt-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-20
                                        h-4
                                        my-2
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                                <div
                                    class="
                                        bg-gray-300
                                        w-20
                                        h-4
                                        max-w-sm
                                        animate-pulse
                                    "
                                >
                                    &nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "UpcomingBookingLoader"
}
</script>

<style scoped></style>
