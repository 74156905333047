<template>
    <div
        tabindex="0"
        ref="tab"
        class="relative focus:outline-none"
        @keydown.left="navigateCal('left')"
        @keydown.right="navigateCal('right')"
    >
        <Loader
            v-if="isLoading"
            class="flex h-screen py-40 justify-center items-center"
            type="lg"
        />
        <div v-else class="py-8 px-4 mt-8 lg:mt-0 lg:px-8 bg-gray-100">
            <div class="flex justify-center">
                <div class="w-full max-w-6xl">
                    <div class="flex justify-center">
                        <nav class="flex flex-1" aria-label="Breadcrumb">
                            <ol
                                role="list"
                                class="
                                    flex flex-1
                                    overflow-hidden
                                    text-gray-700
                                    border border-gray-200
                                "
                            >
                                <li class="flex flex-1 items-center">
                                    <button
                                        @mouseover="hoverMode = 0"
                                        @mouseleave="hoverMode = -1"
                                        @click="selectMode(0)"
                                        class="
                                            flex flex-1
                                            h-full
                                            font-medium
                                            justify-center
                                            items-center
                                            px-4
                                            transition
                                            ease-linear
                                            duration-300
                                            text-xs
                                            sm:text-base
                                        "
                                        :class="{
                                            'nav1 text-white':
                                                selectedMode !== 0 &&
                                                hoverMode !== 0,
                                            'bg-inkgreendark text-white':
                                                selectedMode === 0 ||
                                                hoverMode === 0
                                        }"
                                    >
                                        {{
                                            $t(
                                                "room_specification.custom_pricing"
                                            )
                                        }}
                                    </button>
                                </li>

                                <li class="relative flex flex-1 items-center">
                                    <button
                                        @mouseover="hoverMode = 0"
                                        @mouseleave="hoverMode = -1"
                                        @click="selectMode(0)"
                                        class="
                                            absolute
                                            inset-y-0
                                            w-4
                                            h-full
                                            -left-px
                                            clip
                                            transition
                                            ease-linear
                                            duration-300
                                        "
                                        :class="{
                                            'nav1 text-white':
                                                selectedMode !== 0 &&
                                                hoverMode !== 0,
                                            'bg-inkgreendark text-white':
                                                selectedMode === 0 ||
                                                hoverMode === 0
                                        }"
                                    ></button>

                                    <button
                                        @mouseover="hoverMode = 1"
                                        @mouseleave="hoverMode = -1"
                                        @click="selectMode(1)"
                                        class="
                                            flex flex-1
                                            items-center
                                            justify-center
                                            h-full
                                            pl-8
                                            pr-4
                                            font-medium
                                            transition
                                            ease-linear
                                            duration-300
                                            text-xs
                                            sm:text-base
                                        "
                                        :class="{
                                            'nav2 text-white':
                                                selectedMode !== 1 &&
                                                hoverMode !== 1,
                                            'bg-inkgreenlight text-white':
                                                selectedMode === 1 ||
                                                hoverMode === 1
                                        }"
                                    >
                                        {{
                                            $t(
                                                "room_specification.dotm_pricing"
                                            )
                                        }}
                                    </button>
                                </li>
                                <li class="relative flex flex-1 items-center">
                                    <button
                                        @mouseover="hoverMode = 1"
                                        @mouseleave="hoverMode = -1"
                                        @click="selectMode(1)"
                                        class="
                                            absolute
                                            inset-y-0
                                            w-4
                                            h-full
                                            -left-px
                                            clip
                                            transition
                                            ease-linear
                                            duration-300
                                        "
                                        :class="{
                                            'nav2 text-white':
                                                selectedMode !== 1 &&
                                                hoverMode !== 1,
                                            'bg-inkgreenlight text-white':
                                                selectedMode === 1 ||
                                                hoverMode === 1
                                        }"
                                    ></button>

                                    <button
                                        @mouseover="hoverMode = 2"
                                        @mouseleave="hoverMode = -1"
                                        @click="selectMode(2)"
                                        class="
                                            flex flex-1
                                            py-2
                                            items-center
                                            justify-center
                                            h-full
                                            pl-8
                                            pr-4
                                            font-medium
                                            transition
                                            ease-linear
                                            duration-300
                                            text-xs
                                            sm:text-base
                                        "
                                        :class="{
                                            'nav3 text-white':
                                                selectedMode !== 2 &&
                                                hoverMode !== 2,
                                            'bg-matchagreen text-white':
                                                selectedMode === 2 ||
                                                hoverMode === 2
                                        }"
                                    >
                                        {{
                                            $t(
                                                "room_specification.default_pricing"
                                            )
                                        }}
                                    </button>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <button
                        @click="modalType = 1"
                        class="
                            text-yellow-400
                            underline
                            text-sm
                            focus:outline-none
                        "
                    >
                        {{ $t("room_specification.how_to_use") }}
                    </button>
                    <div class="flex justify-between items-center">
                        <div
                            class="
                                flex
                                my-3
                                font-medium
                                text-lg text-gray-500
                                items-center
                            "
                        >
                            {{ showPricingType() }}
                            <button class="ml-1" @click="modalType = 2">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    class="
                                        stroke-current
                                        icon icon-tabler icon-tabler-info-circle
                                    "
                                    width="20"
                                    height="20"
                                    viewBox="0 0 24 24"
                                    stroke-width="1.5"
                                    stroke="#000000"
                                    fill="none"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                >
                                    <path
                                        stroke="none"
                                        d="M0 0h24v24H0z"
                                        fill="none"
                                    />
                                    <circle cx="12" cy="12" r="9" />
                                    <line x1="12" y1="8" x2="12.01" y2="8" />
                                    <polyline
                                        points="11 12 12 12 12 16 13 16"
                                    />
                                </svg>
                            </button>
                        </div>
                        <div
                            v-if="selectedMode === 0"
                            class="flex items-center text-sm"
                        >
                            {{ $t("room_specification.edit_mode") }}
                            <Switch
                                class="ml-1 mr-2"
                                :toggle="editMode"
                                @onPress="switchMode"
                            />
                        </div>
                    </div>
                    <div class="block lg:flex">
                        <div
                            class="
                                flex flex-col flex-1
                                w-full
                                overflow-x-scroll
                                mb-8
                                lg:mb-0
                                bg-white
                                rounded-md
                                shadow-md
                            "
                            :class="{
                                'max-h-screen overflow-y-scroll lg:mr-8':
                                    selectedMode !== 2 && showCal,
                                'max-h-full': selectedMode !== 2 && !showCal
                            }"
                        >
                            <div
                                class="
                                    flex
                                    justify-between
                                    items-center
                                    py-1
                                    mb-2
                                    border-b
                                "
                            >
                                <div class="text-gray-500 font-medium">
                                    <span class="ml-2">
                                        {{
                                            !editMode && selectedMode === 0
                                                ? $t(
                                                      "room_specification.view_pricing"
                                                  )
                                                : $t(
                                                      "room_specification.edit_pricing"
                                                  )
                                        }}
                                    </span>
                                </div>
                                <div
                                    v-if="selectedMode === 1"
                                    class="text-gray-500 font-medium"
                                >
                                    {{
                                        month_names[
                                            parseInt(
                                                selectedMonth.split("-")[1]
                                            ) - 1
                                        ]
                                    }}
                                    {{ selectedMonth.split("-")[0] }}
                                </div>
                                <div
                                    v-if="
                                        selectedMode === 1 || selectedMode === 0
                                    "
                                >
                                    <button
                                        class="
                                            text-yellow-400
                                            underline
                                            font-medium
                                            mr-2
                                        "
                                        @click="showCal = !showCal"
                                    >
                                        {{ showCal ? "Hide" : "Show" }} Calendar
                                        {{ showCal ? ">" : null }}
                                    </button>
                                </div>
                            </div>
                            <div class="flex justify-between items-start">
                                <div
                                    v-if="selectedDates.length > 0"
                                    class="flex flex-wrap"
                                >
                                    <button
                                        v-for="(date, index) in selectedDates"
                                        :key="index"
                                        :disabled="editMode"
                                        @click="viewModeSelect(index)"
                                        class="
                                            text-white text-sm
                                            rounded-full
                                            px-2
                                            py-1
                                            ml-2
                                            transition
                                            mb-1.5
                                            duration-200
                                            ease-linear
                                        "
                                        :class="{
                                            'opacity-40':
                                                index !== viewModeIndex &&
                                                !editMode,
                                            'bg-yellow-400': editMode,
                                            'bg-gray-500': !editMode
                                        }"
                                    >
                                        {{ date }}
                                    </button>
                                </div>
                                <button
                                    v-if="selectedDates.length > 0"
                                    @click="clearData"
                                    class="
                                        bg-red-500
                                        text-white
                                        rounded-md
                                        shadow-md
                                        px-2
                                        py-1
                                        mr-2
                                    "
                                >
                                    Clear
                                </button>
                            </div>
                            <div class="overflow-x-scroll">
                                <table
                                    v-if="!tableLoading && selectedMode === 0"
                                    class="
                                        table-auto
                                        min-w-full
                                        divide-gray-600
                                    "
                                >
                                    <thead>
                                        <tr>
                                            <th
                                                v-if="
                                                    !editMode &&
                                                    selectedDates.length > 0
                                                "
                                                class="
                                                    pl-4
                                                    py-3
                                                    bg-white
                                                    text-left text-sm
                                                    font-medium
                                                    text-gray-500
                                                    tracking-wider
                                                "
                                            >
                                                {{
                                                    $t(
                                                        "room_specification.room_type"
                                                    )
                                                }}
                                            </th>
                                            <th
                                                v-if="
                                                    editMode &&
                                                    selectedDates.length > 0
                                                "
                                                class="
                                                    pl-4
                                                    py-3
                                                    bg-white
                                                    text-left text-sm
                                                    font-medium
                                                    text-gray-500
                                                    tracking-wider
                                                    flex
                                                    items-center
                                                "
                                            >
                                                <input
                                                    class="cb"
                                                    ref="select_all"
                                                    @change="
                                                        selectAll(
                                                            $event.target
                                                                .checked
                                                        )
                                                    "
                                                    type="checkbox"
                                                />
                                                <span class="ml-1">{{
                                                    $t("booking.all")
                                                }}</span>
                                            </th>
                                            <th
                                                v-for="(ses, index) in flexible"
                                                :key="index"
                                                class="
                                                    pl-4
                                                    py-3
                                                    bg-white
                                                    text-sm
                                                    font-medium
                                                    text-gray-500
                                                    tracking-wider
                                                "
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            stroke-current
                                                            mr-1
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-clock
                                                        "
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#9e9e9e"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <circle
                                                            cx="12"
                                                            cy="12"
                                                            r="9"
                                                        />
                                                        <polyline
                                                            points="12 7 12 12 15 15"
                                                        /></svg
                                                    ><FreeCancel
                                                        v-if="
                                                            flexibleRefund.filter(
                                                                (e) =>
                                                                    e.type ===
                                                                        ses &&
                                                                    e.refund !==
                                                                        null &&
                                                                    e.refund >=
                                                                        0
                                                            ).length > 0
                                                        "
                                                        class="text-green-600"
                                                    />
                                                    <NoCancel v-else />
                                                </div>
                                                <span
                                                    class="
                                                        flex flex-wrap
                                                        items-center
                                                        justify-center
                                                    "
                                                >
                                                    {{ ses }}</span
                                                >
                                            </th>
                                            <th
                                                v-for="(ses, index) in sessions"
                                                :key="index"
                                                class="
                                                    pl-4
                                                    py-3
                                                    bg-white
                                                    text-sm
                                                    font-medium
                                                    text-gray-500
                                                    tracking-wider
                                                "
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            stroke-current
                                                            mr-1
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-hourglass
                                                        "
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#9e9e9e"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path d="M6.5 7h11" />
                                                        <path d="M6.5 17h11" />
                                                        <path
                                                            d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                        />
                                                        <path
                                                            d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                        /></svg
                                                    ><FreeCancel
                                                        v-if="
                                                            sessionRefund.filter(
                                                                (e) =>
                                                                    e.type ===
                                                                        ses &&
                                                                    e.refund !==
                                                                        null &&
                                                                    e.refund >=
                                                                        0
                                                            ).length > 0
                                                        "
                                                        class="text-green-600"
                                                    />
                                                    <NoCancel v-else />
                                                </div>
                                                <span
                                                    class="
                                                        flex flex-wrap
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    {{
                                                        `${ses
                                                            .split("-")[0]
                                                            .split(":", 2)
                                                            .join(":")}-${ses
                                                            .split("-")[1]
                                                            .split(":", 2)
                                                            .join(":")}`
                                                    }}</span
                                                >
                                            </th>
                                            <th
                                                v-for="(
                                                    ses, index
                                                ) in overnight"
                                                :key="index"
                                                class="
                                                    pl-4
                                                    py-3
                                                    bg-white
                                                    text-sm
                                                    font-medium
                                                    text-gray-500
                                                    tracking-wider
                                                "
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <svg
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        class="
                                                            stroke-current
                                                            mr-1
                                                            icon
                                                            icon-tabler
                                                            icon-tabler-moon
                                                        "
                                                        width="20"
                                                        height="20"
                                                        viewBox="0 0 24 24"
                                                        stroke-width="1.5"
                                                        stroke="#9e9e9e"
                                                        fill="none"
                                                        stroke-linecap="round"
                                                        stroke-linejoin="round"
                                                    >
                                                        <path
                                                            stroke="none"
                                                            d="M0 0h24v24H0z"
                                                            fill="none"
                                                        />
                                                        <path
                                                            d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                        /></svg
                                                    ><FreeCancel
                                                        v-if="
                                                            overnightRefund.filter(
                                                                (e) =>
                                                                    e.type ===
                                                                        ses &&
                                                                    e.refund !==
                                                                        null &&
                                                                    e.refund >=
                                                                        0
                                                            ).length > 0
                                                        "
                                                        class="text-green-600"
                                                    />
                                                    <NoCancel v-else />
                                                </div>
                                                <span
                                                    class="
                                                        flex flex-wrap
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    {{
                                                        `${ses
                                                            .split("-")[0]
                                                            .split(":", 2)
                                                            .join(":")}-${ses
                                                            .split("-")[1]
                                                            .split(":", 2)
                                                            .join(":")}`
                                                    }}</span
                                                >
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr
                                            v-for="(
                                                room, name, index
                                            ) in roomData"
                                            class="text-sm md:text-base"
                                            :key="index"
                                        >
                                            <td
                                                class="
                                                    flex
                                                    items-center
                                                    w-auto
                                                    overflow-x-hidden
                                                "
                                                :class="{
                                                    'lg:w-60': showCal
                                                }"
                                            >
                                                <input
                                                    v-if="editMode"
                                                    class="ml-4 cb"
                                                    type="checkbox"
                                                    v-model="room.isSelected"
                                                />
                                                <span
                                                    :title="room.name"
                                                    class="
                                                        ml-1
                                                        whitespace-nowrap
                                                        truncate
                                                    "
                                                    :class="{
                                                        'ml-4': !editMode
                                                    }"
                                                    >{{ room.name }}
                                                </span>
                                            </td>
                                            <td
                                                v-for="(ses, idx) in flexible"
                                                :key="idx"
                                                class="pl-4 py-1 text-center"
                                                :class="{
                                                    'opacity-30':
                                                        !room.isSelected &&
                                                        editMode
                                                }"
                                            >
                                                <div v-if="!room['hour']"></div>
                                                <div v-else>
                                                    <div
                                                        :ref="
                                                            room.name +
                                                            'Hour_' +
                                                            [s]
                                                        "
                                                        v-for="(
                                                            s, i
                                                        ) in Object.keys(
                                                            room['hour']
                                                        ).filter(
                                                            (x) => x === ses
                                                        )"
                                                        :key="i"
                                                        @click="
                                                            focusInput(
                                                                room.name +
                                                                    'hour_' +
                                                                    [s]
                                                            )
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                flex
                                                                items-start
                                                                border
                                                                border-gray-300
                                                            "
                                                            v-if="editMode"
                                                        >
                                                            <span
                                                                class="mr-1"
                                                                >{{
                                                                    room[
                                                                        "hour"
                                                                    ][s]
                                                                        .currency
                                                                }}</span
                                                            >
                                                            <input
                                                                @keydown.stop="
                                                                    isNumberKey(
                                                                        $event
                                                                    )
                                                                "
                                                                :ref="
                                                                    room.name +
                                                                    'hour_' +
                                                                    [s]
                                                                "
                                                                :readonly="
                                                                    !editMode ||
                                                                    !room.isSelected
                                                                "
                                                                class="
                                                                    w-16
                                                                    focus:outline-none
                                                                "
                                                                v-model="
                                                                    room[
                                                                        'hour'
                                                                    ][s].price
                                                                "
                                                                @change="
                                                                    updatePrice(
                                                                        room[
                                                                            'hour'
                                                                        ][s],
                                                                        name,
                                                                        room.name +
                                                                            'Hour_' +
                                                                            [s]
                                                                    )
                                                                "
                                                            />
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'hour'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="
                                                                    flex flex-1
                                                                    mr-2
                                                                    justify-end
                                                                    font-bold
                                                                "
                                                                :class="{
                                                                    invisible:
                                                                        !room[
                                                                            'hour'
                                                                        ][s]
                                                                            .low_price
                                                                }"
                                                            >
                                                                !
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            class="
                                                                text-center
                                                                flex
                                                            "
                                                        >
                                                            {{
                                                                room["hour"][s]
                                                                    .currency
                                                            }}<span
                                                                class="ml-1"
                                                                >{{
                                                                    room[
                                                                        "hour"
                                                                    ][s].price
                                                                }}</span
                                                            >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'hour'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                v-for="(ses, idx) in sessions"
                                                :key="idx"
                                                class="pl-4 py-1 text-center"
                                                :class="{
                                                    'opacity-30':
                                                        !room.isSelected &&
                                                        editMode
                                                }"
                                            >
                                                <div
                                                    v-if="!room['session']"
                                                ></div>
                                                <div v-else>
                                                    <div
                                                        :ref="
                                                            room.name +
                                                            'Session_' +
                                                            [s]
                                                        "
                                                        v-for="(
                                                            s, i
                                                        ) in Object.keys(
                                                            room['session']
                                                        ).filter(
                                                            (x) => x === ses
                                                        )"
                                                        :key="i"
                                                        @click="
                                                            focusInput(
                                                                room.name +
                                                                    'session_' +
                                                                    [s]
                                                            )
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                flex
                                                                items-start
                                                                border
                                                                border-gray-300
                                                            "
                                                            v-if="editMode"
                                                        >
                                                            <span
                                                                class="mr-1"
                                                                >{{
                                                                    room[
                                                                        "session"
                                                                    ][s]
                                                                        .currency
                                                                }}</span
                                                            >
                                                            <input
                                                                @keydown.stop="
                                                                    isNumberKey(
                                                                        $event
                                                                    )
                                                                "
                                                                :ref="
                                                                    room.name +
                                                                    'session_' +
                                                                    [s]
                                                                "
                                                                :readonly="
                                                                    !editMode ||
                                                                    !room.isSelected
                                                                "
                                                                class="
                                                                    w-16
                                                                    focus:outline-none
                                                                "
                                                                v-model="
                                                                    room[
                                                                        'session'
                                                                    ][s].price
                                                                "
                                                                @change="
                                                                    updatePrice(
                                                                        room[
                                                                            'session'
                                                                        ][s],
                                                                        name,
                                                                        room.name +
                                                                            'Session_' +
                                                                            [s]
                                                                    )
                                                                "
                                                            />
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'session'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="
                                                                    flex flex-1
                                                                    mr-2
                                                                    justify-end
                                                                    font-bold
                                                                "
                                                                :class="{
                                                                    invisible:
                                                                        !room[
                                                                            'session'
                                                                        ][s]
                                                                            .low_price
                                                                }"
                                                            >
                                                                !
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            class="
                                                                text-center
                                                                flex
                                                            "
                                                        >
                                                            {{
                                                                room["session"][
                                                                    s
                                                                ].currency
                                                            }}<span
                                                                class="ml-1"
                                                                >{{
                                                                    room[
                                                                        "session"
                                                                    ][s].price
                                                                }}</span
                                                            >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'session'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                            <td
                                                v-for="(ses, idx) in overnight"
                                                :key="idx"
                                                class="pl-4 py-1 text-center"
                                                :class="{
                                                    'pr-4':
                                                        idx ===
                                                        overnight.length - 1,
                                                    'opacity-30':
                                                        !room.isSelected &&
                                                        editMode
                                                }"
                                            >
                                                <div
                                                    v-if="!room['overnight']"
                                                ></div>
                                                <div v-else>
                                                    <div
                                                        :ref="
                                                            room.name +
                                                            'Overnight_' +
                                                            [s]
                                                        "
                                                        v-for="(
                                                            s, i
                                                        ) in Object.keys(
                                                            room['overnight']
                                                        ).filter(
                                                            (x) => x === ses
                                                        )"
                                                        :key="i"
                                                        @click="
                                                            focusInput(
                                                                room.name +
                                                                    'overnight_' +
                                                                    [s]
                                                            )
                                                        "
                                                    >
                                                        <div
                                                            class="
                                                                flex
                                                                items-start
                                                                border
                                                                border-gray-300
                                                            "
                                                            v-if="editMode"
                                                        >
                                                            <span
                                                                class="mr-1"
                                                                >{{
                                                                    room[
                                                                        "overnight"
                                                                    ][s]
                                                                        .currency
                                                                }}</span
                                                            >
                                                            <input
                                                                @keydown.stop="
                                                                    isNumberKey(
                                                                        $event
                                                                    )
                                                                "
                                                                :ref="
                                                                    room.name +
                                                                    'overnight_' +
                                                                    [s]
                                                                "
                                                                :readonly="
                                                                    !editMode ||
                                                                    !room.isSelected
                                                                "
                                                                class="
                                                                    w-16
                                                                    focus:outline-none
                                                                "
                                                                v-model="
                                                                    room[
                                                                        'overnight'
                                                                    ][s].price
                                                                "
                                                                @change="
                                                                    updatePrice(
                                                                        room[
                                                                            'overnight'
                                                                        ][s],
                                                                        name,
                                                                        room.name +
                                                                            'Overnight_' +
                                                                            [s]
                                                                    )
                                                                "
                                                            />
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'overnight'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                            <div
                                                                class="
                                                                    flex flex-1
                                                                    mr-2
                                                                    justify-end
                                                                    font-bold
                                                                "
                                                                :class="{
                                                                    invisible:
                                                                        !room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .low_price
                                                                }"
                                                            >
                                                                !
                                                            </div>
                                                        </div>
                                                        <div
                                                            v-else
                                                            class="
                                                                text-center
                                                                flex
                                                            "
                                                        >
                                                            {{
                                                                room[
                                                                    "overnight"
                                                                ][s].currency
                                                            }}<span
                                                                class="ml-1"
                                                                >{{
                                                                    room[
                                                                        "overnight"
                                                                    ][s].price
                                                                }}</span
                                                            >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s].costs
                                                                "
                                                                class="ml-1"
                                                            >
                                                                <div
                                                                    v-for="(
                                                                        c, index
                                                                    ) in room[
                                                                        'overnight'
                                                                    ][s].costs"
                                                                    class="ml-1"
                                                                    :key="index"
                                                                >
                                                                    (${{
                                                                        c.cost
                                                                    }}
                                                                    {{
                                                                        c.quota
                                                                    }})
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                                <div
                                    v-else-if="
                                        !tableLoading && selectedMode !== 0
                                    "
                                >
                                    <div class="mt-1 ml-2">
                                        <span
                                            class="
                                                bg-yellow-400
                                                text-white text-base
                                                rounded-full
                                                px-3
                                                py-1
                                                mr-2
                                            "
                                            v-for="(
                                                day, index
                                            ) in weekDayEnd.weekday"
                                            :key="index"
                                            >{{ days[day] }}</span
                                        >
                                    </div>
                                    <table
                                        v-if="weekDayEnd.weekday"
                                        class="
                                            table-auto
                                            min-w-full
                                            divide-gray-600
                                        "
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="
                                                        ml-4
                                                        py-3
                                                        bg-white
                                                        text-left text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                        flex
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        class="cb"
                                                        ref="select_all0"
                                                        @change="
                                                            selectAll(
                                                                $event.target
                                                                    .checked,
                                                                0
                                                            )
                                                        "
                                                        type="checkbox"
                                                    />
                                                    <span class="ml-1">{{
                                                        $t("booking.all")
                                                    }}</span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in flexible"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-clock
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />
                                                            <polyline
                                                                points="12 7 12 12 15 15"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                flexibleRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{ ses }}
                                                    </span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in sessions"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-hourglass
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M6.5 7h11"
                                                            />
                                                            <path
                                                                d="M6.5 17h11"
                                                            />
                                                            <path
                                                                d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                            />
                                                            <path
                                                                d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                sessionRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in overnight"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-moon
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                overnightRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    room, name, index
                                                ) in roomData"
                                                class="text-sm md:text-base"
                                                :key="index"
                                            >
                                                <td
                                                    class="
                                                        flex
                                                        items-center
                                                        w-auto
                                                        overflow-x-hidden
                                                    "
                                                    :class="{
                                                        'lg:w-60': showCal
                                                    }"
                                                >
                                                    <input
                                                        class="ml-4 cb"
                                                        type="checkbox"
                                                        v-model="
                                                            room.isSelected_0
                                                        "
                                                    />
                                                    <span
                                                        :title="room.name"
                                                        class="
                                                            ml-1
                                                            truncate
                                                            whitespace-nowrap
                                                        "
                                                        >{{ room.name }}
                                                    </span>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in flexible"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_0
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['hour']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Hour0_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['hour']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'hour0_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s]
                                                                        .weekday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "hour"
                                                                        ][s]
                                                                            .weekday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'hour0_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_0
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'hour'
                                                                        ][s]
                                                                            .weekday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'hour'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Hour0_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            0
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'hour'
                                                                            ][s]
                                                                                .weekday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in sessions"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_0
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['session']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Session0_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['session']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'session0_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .weekday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "session"
                                                                        ][s]
                                                                            .weekday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'session0_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_0
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'session'
                                                                        ][s]
                                                                            .weekday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'session'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Session0_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            0
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'session'
                                                                            ][s]
                                                                                .weekday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in overnight"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'pr-4':
                                                            idx ===
                                                            overnight.length -
                                                                1,
                                                        'opacity-30':
                                                            !room.isSelected_0
                                                    }"
                                                >
                                                    <div
                                                        v-if="
                                                            !room['overnight']
                                                        "
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Overnight0_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room[
                                                                    'overnight'
                                                                ]
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'overnight0_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s]
                                                                        .weekday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "overnight"
                                                                        ][s]
                                                                            .weekday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'overnight0_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_0
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .weekday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'overnight'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Overnight0_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            0
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'overnight'
                                                                            ][s]
                                                                                .weekday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="mt-1 ml-2">
                                        <span
                                            class="
                                                bg-yellow-400
                                                text-white text-base
                                                rounded-full
                                                px-3
                                                py-1
                                                mr-2
                                            "
                                            v-for="(
                                                day, index
                                            ) in weekDayEnd.weekend_1"
                                            :key="index"
                                            >{{ days[day] }}</span
                                        >
                                    </div>
                                    <table
                                        v-if="weekDayEnd.weekend_1"
                                        class="
                                            table-auto
                                            min-w-full
                                            divide-gray-600
                                        "
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-left text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                        flex
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        class="cb"
                                                        ref="select_all1"
                                                        @change="
                                                            selectAll(
                                                                $event.target
                                                                    .checked,
                                                                1
                                                            )
                                                        "
                                                        type="checkbox"
                                                    />
                                                    <span class="ml-1">{{
                                                        $t("booking.all")
                                                    }}</span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in flexible"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-clock
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />
                                                            <polyline
                                                                points="12 7 12 12 15 15"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                flexibleRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{ ses }}
                                                    </span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in sessions"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-hourglass
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M6.5 7h11"
                                                            />
                                                            <path
                                                                d="M6.5 17h11"
                                                            />
                                                            <path
                                                                d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                            />
                                                            <path
                                                                d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                sessionRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in overnight"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-moon
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                overnightRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    room, name, index
                                                ) in roomData"
                                                class="text-sm md:text-base"
                                                :key="index"
                                            >
                                                <td
                                                    class="
                                                        flex
                                                        items-center
                                                        w-auto
                                                        overflow-x-hidden
                                                    "
                                                    :class="{
                                                        'lg:w-60': showCal
                                                    }"
                                                >
                                                    <input
                                                        class="ml-4 cb"
                                                        type="checkbox"
                                                        v-model="
                                                            room.isSelected_1
                                                        "
                                                    />
                                                    <span
                                                        :title="room.name"
                                                        class="
                                                            ml-1
                                                            truncate
                                                            whitespace-nowrap
                                                        "
                                                        >{{ room.name }}
                                                    </span>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in flexible"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_1
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['hour']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Hour1_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['hour']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'hour1_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s]
                                                                        .weekend_price_1
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "hour"
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'hour1_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_1
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'hour'
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'hour'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Hour1_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            1
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'hour'
                                                                            ][s]
                                                                                .weekend_price_1
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in sessions"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_1
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['session']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Session1_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['session']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'session1_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .weekend_price_1
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "session"
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'session1_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_1
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'session'
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'session'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Session1_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            1
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'session'
                                                                            ][s]
                                                                                .weekend_price_1
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in overnight"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'pr-4':
                                                            idx ===
                                                            overnight.length -
                                                                1,
                                                        'opacity-30':
                                                            !room.isSelected_1
                                                    }"
                                                >
                                                    <div
                                                        v-if="
                                                            !room['overnight']
                                                        "
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Overnight1_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room[
                                                                    'overnight'
                                                                ]
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'overnight1_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s]
                                                                        .weekend_price_1
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "overnight"
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'overnight1_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_1
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .weekend_price_1
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'overnight'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Overnight1_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            1
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'overnight'
                                                                            ][s]
                                                                                .weekend_price_1
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="mt-1 ml-2">
                                        <span
                                            class="
                                                bg-yellow-400
                                                text-white text-base
                                                rounded-full
                                                px-3
                                                py-1
                                                mr-2
                                            "
                                            v-for="(
                                                day, index
                                            ) in weekDayEnd.weekend_2"
                                            :key="index"
                                            >{{ days[day] }}</span
                                        >
                                    </div>
                                    <table
                                        v-if="weekDayEnd.weekend_2"
                                        class="
                                            table-auto
                                            min-w-full
                                            divide-gray-600
                                        "
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-left text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                        flex
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        class="cb"
                                                        ref="select_all2"
                                                        @change="
                                                            selectAll(
                                                                $event.target
                                                                    .checked,
                                                                2
                                                            )
                                                        "
                                                        type="checkbox"
                                                    />
                                                    <span class="ml-1">{{
                                                        $t("booking.all")
                                                    }}</span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in flexible"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-clock
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />
                                                            <polyline
                                                                points="12 7 12 12 15 15"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                flexibleRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{ ses }}
                                                    </span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in sessions"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-hourglass
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M6.5 7h11"
                                                            />
                                                            <path
                                                                d="M6.5 17h11"
                                                            />
                                                            <path
                                                                d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                            />
                                                            <path
                                                                d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                sessionRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in overnight"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-moon
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                overnightRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    room, name, index
                                                ) in roomData"
                                                class="text-sm md:text-base"
                                                :key="index"
                                            >
                                                <td
                                                    class="
                                                        flex
                                                        items-center
                                                        w-auto
                                                        overflow-x-hidden
                                                    "
                                                    :class="{
                                                        'lg:w-60': showCal
                                                    }"
                                                >
                                                    <input
                                                        class="ml-4 cb"
                                                        type="checkbox"
                                                        v-model="
                                                            room.isSelected_2
                                                        "
                                                    />
                                                    <span
                                                        :title="room.name"
                                                        class="
                                                            ml-1
                                                            truncate
                                                            whitespace-nowrap
                                                        "
                                                        >{{ room.name }}
                                                    </span>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in flexible"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_2
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['hour']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Hour2_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['hour']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'hour2_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s]
                                                                        .weekend_price_2
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "hour"
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'hour2_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_2
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'hour'
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'hour'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Hour2_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            2
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'hour'
                                                                            ][s]
                                                                                .weekend_price_2
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in sessions"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_2
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['session']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Session2_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['session']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'session2_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .weekend_price_2
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "session"
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'session2_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_2
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'session'
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'session'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Session2_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            2
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'session'
                                                                            ][s]
                                                                                .weekend_price_2
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in overnight"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'pr-4':
                                                            idx ===
                                                            overnight.length -
                                                                1,
                                                        'opacity-30':
                                                            !room.isSelected_2
                                                    }"
                                                >
                                                    <div
                                                        v-if="
                                                            !room['overnight']
                                                        "
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Overnight2_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room[
                                                                    'overnight'
                                                                ]
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'overnight2_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s]
                                                                        .weekend_price_2
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "overnight"
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'overnight2_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_2
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .weekend_price_2
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'overnight'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Overnight2_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            2
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'overnight'
                                                                            ][s]
                                                                                .weekend_price_2
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="mt-1 ml-2">
                                        <span
                                            class="
                                                bg-yellow-400
                                                text-white text-base
                                                rounded-full
                                                px-3
                                                py-1
                                                mr-2
                                            "
                                            v-for="(
                                                day, index
                                            ) in weekDayEnd.weekend_3"
                                            :key="index"
                                            >{{ days[day] }}</span
                                        >
                                    </div>
                                    <table
                                        v-if="weekDayEnd.weekend_3"
                                        class="
                                            table-auto
                                            min-w-full
                                            divide-gray-600
                                        "
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-left text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                        flex
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        class="cb"
                                                        ref="select_all3"
                                                        @change="
                                                            selectAll(
                                                                $event.target
                                                                    .checked,
                                                                3
                                                            )
                                                        "
                                                        type="checkbox"
                                                    />
                                                    <span class="ml-1">{{
                                                        $t("booking.all")
                                                    }}</span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in flexible"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-clock
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />
                                                            <polyline
                                                                points="12 7 12 12 15 15"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                flexibleRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{ ses }}
                                                    </span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in sessions"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-hourglass
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M6.5 7h11"
                                                            />
                                                            <path
                                                                d="M6.5 17h11"
                                                            />
                                                            <path
                                                                d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                            />
                                                            <path
                                                                d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                sessionRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in overnight"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-moon
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                overnightRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    room, name, index
                                                ) in roomData"
                                                class="text-sm md:text-base"
                                                :key="index"
                                            >
                                                <td
                                                    class="
                                                        flex
                                                        items-center
                                                        w-auto
                                                        overflow-x-hidden
                                                    "
                                                    :class="{
                                                        'lg:w-60': showCal
                                                    }"
                                                >
                                                    <input
                                                        class="ml-4 cb"
                                                        type="checkbox"
                                                        v-model="
                                                            room.isSelected_3
                                                        "
                                                    />
                                                    <span
                                                        :title="room.name"
                                                        class="
                                                            ml-1
                                                            truncate
                                                            whitespace-nowrap
                                                        "
                                                        >{{ room.name }}
                                                    </span>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in flexible"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_3
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['hour']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Hour3_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['hour']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'hour3_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s]
                                                                        .weekend_price_3
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "hour"
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'hour3_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_3
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'hour'
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'hour'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Hour3_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            3
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'hour'
                                                                            ][s]
                                                                                .weekend_price_3
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in sessions"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_3
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['session']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Session3_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['session']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'session3_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .weekend_price_3
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "session"
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'session3_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_3
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'session'
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'session'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Session3_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            3
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'session'
                                                                            ][s]
                                                                                .weekend_price_3
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in overnight"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'pr-4':
                                                            idx ===
                                                            overnight.length -
                                                                1,
                                                        'opacity-30':
                                                            !room.isSelected_3
                                                    }"
                                                >
                                                    <div
                                                        v-if="
                                                            !room['overnight']
                                                        "
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Overnight3_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room[
                                                                    'overnight'
                                                                ]
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'overnight3_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s]
                                                                        .weekend_price_3
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "overnight"
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'overnight3_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_3
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .weekend_price_3
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'overnight'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Overnight3_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            3
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'overnight'
                                                                            ][s]
                                                                                .weekend_price_3
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="mt-1 ml-2">
                                        <span
                                            class="
                                                bg-yellow-400
                                                text-white text-base
                                                rounded-full
                                                px-2
                                                py-1
                                                mr-2
                                            "
                                            >{{
                                                $t(
                                                    "room_specification.public_holiday"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <table
                                        class="
                                            table-auto
                                            min-w-full
                                            divide-gray-600
                                        "
                                    >
                                        <thead>
                                            <tr>
                                                <th
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-left text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                        flex
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        class="cb"
                                                        ref="select_all4"
                                                        @change="
                                                            selectAll(
                                                                $event.target
                                                                    .checked,
                                                                4
                                                            )
                                                        "
                                                        type="checkbox"
                                                    />
                                                    <span class="ml-1">{{
                                                        $t("booking.all")
                                                    }}</span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in flexible"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-clock
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <circle
                                                                cx="12"
                                                                cy="12"
                                                                r="9"
                                                            />
                                                            <polyline
                                                                points="12 7 12 12 15 15"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                flexibleRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{ ses }}
                                                    </span>
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in sessions"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-hourglass
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M6.5 7h11"
                                                            />
                                                            <path
                                                                d="M6.5 17h11"
                                                            />
                                                            <path
                                                                d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                            />
                                                            <path
                                                                d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                sessionRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                                <th
                                                    v-for="(
                                                        ses, index
                                                    ) in overnight"
                                                    :key="index"
                                                    class="
                                                        pl-4
                                                        py-3
                                                        bg-white
                                                        text-sm
                                                        font-medium
                                                        text-gray-500
                                                        tracking-wider
                                                    "
                                                >
                                                    <div
                                                        class="
                                                            flex
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                stroke-current
                                                                mr-1
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-moon
                                                            "
                                                            width="20"
                                                            height="20"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#9e9e9e"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <path
                                                                d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                            /></svg
                                                        ><FreeCancel
                                                            v-if="
                                                                overnightRefund.filter(
                                                                    (e) =>
                                                                        e.type ===
                                                                            ses &&
                                                                        e.refund !==
                                                                            null &&
                                                                        e.refund >=
                                                                            0
                                                                ).length > 0
                                                            "
                                                            class="
                                                                text-green-600
                                                            "
                                                        />
                                                        <NoCancel v-else />
                                                    </div>
                                                    <span
                                                        class="
                                                            flex flex-wrap
                                                            justify-center
                                                            items-center
                                                        "
                                                    >
                                                        {{
                                                            `${ses
                                                                .split("-")[0]
                                                                .split(":", 2)
                                                                .join(
                                                                    ":"
                                                                )}-${ses
                                                                .split("-")[1]
                                                                .split(":", 2)
                                                                .join(":")}`
                                                        }}</span
                                                    >
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                v-for="(
                                                    room, name, index
                                                ) in roomData"
                                                class="text-sm md:text-base"
                                                :key="index"
                                            >
                                                <td
                                                    class="
                                                        flex
                                                        items-center
                                                        w-auto
                                                        overflow-x-hidden
                                                    "
                                                    :class="{
                                                        'lg:w-60': showCal
                                                    }"
                                                >
                                                    <input
                                                        class="ml-4 cb"
                                                        type="checkbox"
                                                        v-model="
                                                            room.isSelected_4
                                                        "
                                                    />
                                                    <span
                                                        :title="room.name"
                                                        class="
                                                            ml-1
                                                            truncate
                                                            whitespace-nowrap
                                                        "
                                                        >{{ room.name }}
                                                    </span>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in flexible"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_4
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['hour']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Hour4_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['hour']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'hour4_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'hour'
                                                                    ][s]
                                                                        .public_holiday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "hour"
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'hour4_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_4
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'hour'
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'hour'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Hour4_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            4
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'hour'
                                                                            ][s]
                                                                                .public_holiday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in sessions"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'opacity-30':
                                                            !room.isSelected_4
                                                    }"
                                                >
                                                    <div
                                                        v-if="!room['session']"
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Session4_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room['session']
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'session4_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .public_holiday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "session"
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'session4_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_4
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'session'
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'session'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Session4_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            4
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'session'
                                                                            ][s]
                                                                                .public_holiday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                                <td
                                                    v-for="(
                                                        ses, idx
                                                    ) in overnight"
                                                    :key="idx"
                                                    class="
                                                        pl-4
                                                        py-1
                                                        text-center
                                                    "
                                                    :class="{
                                                        'pr-4':
                                                            idx ===
                                                            overnight.length -
                                                                1,
                                                        'opacity-30':
                                                            !room.isSelected_4
                                                    }"
                                                >
                                                    <div
                                                        v-if="
                                                            !room['overnight']
                                                        "
                                                    ></div>
                                                    <div v-else>
                                                        <div
                                                            :ref="
                                                                room.name +
                                                                'Overnight4_' +
                                                                [s]
                                                            "
                                                            v-for="(
                                                                s, i
                                                            ) in Object.keys(
                                                                room[
                                                                    'overnight'
                                                                ]
                                                            ).filter(
                                                                (x) => x === ses
                                                            )"
                                                            :key="i"
                                                            @click="
                                                                focusInput(
                                                                    room.name +
                                                                        'overnight4_' +
                                                                        [s]
                                                                )
                                                            "
                                                        >
                                                            <div
                                                                v-if="
                                                                    room[
                                                                        'overnight'
                                                                    ][s]
                                                                        .public_holiday_price
                                                                "
                                                                class="
                                                                    flex
                                                                    justify-center
                                                                    border
                                                                    border-gray-300
                                                                "
                                                            >
                                                                <span
                                                                    class="mr-1"
                                                                    >{{
                                                                        room[
                                                                            "overnight"
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .currency
                                                                    }}</span
                                                                >
                                                                <input
                                                                    @keydown.stop="
                                                                        isNumberKey(
                                                                            $event
                                                                        )
                                                                    "
                                                                    :ref="
                                                                        room.name +
                                                                        'overnight4_' +
                                                                        [s]
                                                                    "
                                                                    :readonly="
                                                                        !room.isSelected_4
                                                                    "
                                                                    class="
                                                                        w-16
                                                                        focus:outline-none
                                                                    "
                                                                    v-model="
                                                                        room[
                                                                            'overnight'
                                                                        ][s]
                                                                            .public_holiday_price
                                                                            .price
                                                                    "
                                                                    @change="
                                                                        updatePrice(
                                                                            room[
                                                                                'overnight'
                                                                            ][
                                                                                s
                                                                            ],
                                                                            name,
                                                                            room.name +
                                                                                'Overnight4_' +
                                                                                [
                                                                                    s
                                                                                ],
                                                                            4
                                                                        )
                                                                    "
                                                                />
                                                                <div
                                                                    class="
                                                                        flex
                                                                        flex-1
                                                                        mr-2
                                                                        justify-end
                                                                        font-bold
                                                                    "
                                                                    :class="{
                                                                        invisible:
                                                                            !room[
                                                                                'overnight'
                                                                            ][s]
                                                                                .public_holiday_price
                                                                                .low_price
                                                                    }"
                                                                >
                                                                    !
                                                                </div>
                                                            </div>
                                                            <div v-else>
                                                                N/A
                                                            </div>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <Loader
                                    v-else
                                    type="md"
                                    class="
                                        flex flex-1
                                        justify-center
                                        w-full
                                        my-20
                                    "
                                />
                                <span
                                    v-if="editMode && selectedDates.length > 0"
                                    class="
                                        ml-4
                                        text-sm text-red-500
                                        flex-wrap flex
                                    "
                                    >*above pricing are based on
                                    {{
                                        this.user.space_info_list[
                                            this.selectedSpace
                                        ].mode === 0
                                            ? "book price"
                                            : "final price"
                                    }}
                                    of {{ selectedDates[0] }}</span
                                >
                            </div>
                            <div class="md:flex items-end ml-2 mb-2 mt-auto">
                                <div>
                                    <Legend :type="0" />
                                    <div
                                        class="
                                            grid grid-cols-2
                                            md:flex md:items-center
                                        "
                                    >
                                        <div
                                            class="
                                                flex
                                                items-center
                                                text-gray-500
                                                mr-2
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    mr-1
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-clock
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#9e9e9e"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <circle cx="12" cy="12" r="9" />
                                                <polyline
                                                    points="12 7 12 12 15 15"
                                                />
                                            </svg>
                                            <span class="text-sm">Hour</span>
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                text-gray-500
                                                mr-2
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    mr-1
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-hourglass
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#9e9e9e"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path d="M6.5 7h11" />
                                                <path d="M6.5 17h11" />
                                                <path
                                                    d="M6 20v-2a6 6 0 1 1 12 0v2a1 1 0 0 1 -1 1h-10a1 1 0 0 1 -1 -1z"
                                                />
                                                <path
                                                    d="M6 4v2a6 6 0 1 0 12 0v-2a1 1 0 0 0 -1 -1h-10a1 1 0 0 0 -1 1z"
                                                />
                                            </svg>
                                            <span class="text-sm">Session</span>
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                text-gray-500
                                                mr-2
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    mr-1
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-moon
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#9e9e9e"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <path
                                                    d="M12 3c.132 0 .263 0 .393 0a7.5 7.5 0 0 0 7.92 12.446a9 9 0 1 1 -8.313 -12.454z"
                                                />
                                            </svg>
                                            <span class="text-sm"
                                                >Overnight</span
                                            >
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                text-gray-500
                                                mr-2
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    mr-1
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-sun
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#9e9e9e"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <circle cx="12" cy="12" r="4" />
                                                <path
                                                    d="M3 12h1m8 -9v1m8 8h1m-9 8v1m-6.4 -15.4l.7 .7m12.1 -.7l-.7 .7m0 11.4l.7 .7m-12.1 -.7l-.7 .7"
                                                />
                                            </svg>
                                            <span class="text-sm">Day</span>
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                text-gray-500
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    mr-1
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-ticket
                                                "
                                                width="20"
                                                height="20"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#9e9e9e"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <line
                                                    x1="15"
                                                    y1="5"
                                                    x2="15"
                                                    y2="7"
                                                />
                                                <line
                                                    x1="15"
                                                    y1="11"
                                                    x2="15"
                                                    y2="13"
                                                />
                                                <line
                                                    x1="15"
                                                    y1="17"
                                                    x2="15"
                                                    y2="19"
                                                />
                                                <path
                                                    d="M5 5h14a2 2 0 0 1 2 2v3a2 2 0 0 0 0 4v3a2 2 0 0 1 -2 2h-14a2 2 0 0 1 -2 -2v-3a2 2 0 0 0 0 -4v-3a2 2 0 0 1 2 -2"
                                                />
                                            </svg>
                                            <span class="text-sm">Package</span>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    v-if="editMode || selectedMode !== 0"
                                    class="flex flex-1 justify-end"
                                >
                                    <button
                                        :disabled="!saveButton"
                                        @click="saveChanges"
                                        class="cfn-btn text-lg px-5 py-1 mr-2"
                                        :class="{
                                            'filter grayscale': !saveButton
                                        }"
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="selectedMode !== 2"
                            v-show="showCal"
                            class="h-full max-h-cal"
                        >
                            <div class="bg-white rounded-md shadow-md">
                                <div
                                    class="
                                        flex
                                        justify-between
                                        text-gray-500
                                        font-medium
                                        py-1
                                        mb-2
                                        border-b
                                        px-2
                                        items-center
                                    "
                                    :class="{ 'py-2': selectedMode === 0 }"
                                >
                                    <span>
                                        {{
                                            $t("room_specification.select_date")
                                        }}
                                    </span>
                                </div>
                                <Calendar
                                    ref="cal"
                                    v-show="!calLoading"
                                    class="w-full lg:w-96 lg:max-w-md"
                                    :custom-dates="
                                        selectedMode === 0
                                            ? customDates.specific_date
                                            : customDates.monthly_date
                                    "
                                    :cal-holiday="holidayDates"
                                    :is-toggle="true"
                                    :toggle-mode="selectedMode"
                                    :selected-dates="selectedDates"
                                    @onLoad="onCalendarLoad"
                                    @onChange="onCalendarLoad"
                                    @onSelect="selectDate"
                                />
                                <Loader
                                    v-if="calLoading"
                                    class="
                                        flex
                                        justify-center
                                        w-full
                                        lg:w-96 lg:max-w-md
                                        border-b
                                        py-10
                                    "
                                    type="md"
                                />
                                <div class="flex ml-2 items-center">
                                    <div class="flex items-center py-1">
                                        <span class="text-red-600 font-bold"
                                            >18</span
                                        >
                                        <span
                                            class="
                                                ml-1
                                                text-xs text-gray-500
                                                font-medium
                                            "
                                            >{{
                                                $t(
                                                    "room_specification.public_holiday"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div class="flex items-center ml-3">
                                        <div
                                            class="
                                                h-5
                                                w-5
                                                rounded-full
                                                bg-yellow-400
                                                flex
                                                justify-center
                                                items-center
                                                text-sm text-white
                                            "
                                        >
                                            15
                                        </div>
                                        <span
                                            class="
                                                ml-1
                                                text-xs text-gray-500
                                                font-medium
                                            "
                                            >{{
                                                $t(
                                                    "room_specification.selected_date"
                                                )
                                            }}</span
                                        >
                                    </div>
                                    <div class="flex items-center ml-3">
                                        <div
                                            v-if="selectedMode === 0"
                                            class="
                                                h-2
                                                w-2
                                                rounded-full
                                                bg-green-700
                                            "
                                        ></div>
                                        <div
                                            v-if="selectedMode === 1"
                                            class="h-2 w-2 rounded-full dot"
                                        ></div>
                                        <span
                                            class="
                                                ml-1
                                                text-xs text-gray-500
                                                font-medium
                                            "
                                            >{{
                                                selectedMode === 0
                                                    ? $t(
                                                          "room_specification.custom_pricing"
                                                      )
                                                    : $t(
                                                          "room_specification.dotm_pricing"
                                                      )
                                            }}</span
                                        >
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    user.space_info_list[this.selectedSpace]
                                        .country_code === 'MY'
                                "
                                class="
                                    text-gray-500 text-sm
                                    font-light
                                    lg:w-96 lg:max-w-md
                                "
                            >
                                *Public holiday refers solely to national
                                holidays and does not include any state
                                holidays.
                            </div>
                        </div>
                    </div>
                    <PriceComparison
                        v-if="isReady"
                        class="border rounded-md py-1 px-2 mt-4 bg-white w-full"
                        :country_code="
                            this.user.space_info_list[this.selectedSpace]
                                .country_code
                        "
                        :id="
                            this.user.space_info_list[this.selectedSpace]
                                .space_id
                        "
                    />
                    <AuditLog
                        has-container
                        type="pricing"
                        :show-calendar="true"
                        :user="user"
                        :selected-space="selectedSpace"
                    />
                </div>
            </div>
        </div>
        <Modal v-if="modalType > 0"
            ><div class="flex justify-end">
                <button @click="modalType = 0">X</button>
            </div>
            <div class="py-3">
                <div class="whitespace-pre-line" v-if="modalType === 1">
                    {{ $t("room_specification.how_to_use_description") }}
                </div>
                <div v-else>
                    <div v-if="selectedMode === 0">
                        {{ $t("room_specification.specific_description") }}
                    </div>
                    <div v-if="selectedMode === 1">
                        {{ $t("room_specification.month_description") }}
                    </div>
                    <div v-if="selectedMode === 2">
                        {{ $t("room_specification.default_description") }}
                    </div>
                </div>
            </div>
        </Modal>
    </div>
</template>

<script>
import Loader from "@/components/Loader"
import Modal from "@/components/Modal"
import Calendar from "@/components/Calendar"
import Switch from "@/components/Switch"
import Legend from "@/components/Legend"
import FreeCancel from "@/components/Svg/FreeCancel"
import NoCancel from "@/components/Svg/NoCancel"
import PriceComparison from "@/components/PriceComparison"
import AuditLog from "@/components/AuditLog"

export default {
    name: "RoomSpecification",
    components: {
        AuditLog,
        PriceComparison,
        NoCancel,
        FreeCancel,
        Legend,
        Switch,
        Calendar,
        Modal,
        Loader
    },
    props: {
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number }
    },
    data() {
        return {
            isLoading: false,
            calLoading: false,
            tableLoading: false,
            showCal: true,
            days: [
                this.$t("calendar.sun"),
                this.$t("calendar.mon"),
                this.$t("calendar.tue"),
                this.$t("calendar.wed"),
                this.$t("calendar.thu"),
                this.$t("calendar.fri"),
                this.$t("calendar.sat")
            ],
            month_names: [
                this.$t("calendar.january"),
                this.$t("calendar.february"),
                this.$t("calendar.march"),
                this.$t("calendar.april"),
                this.$t("calendar.may"),
                this.$t("calendar.june"),
                this.$t("calendar.july"),
                this.$t("calendar.august"),
                this.$t("calendar.september"),
                this.$t("calendar.october"),
                this.$t("calendar.november"),
                this.$t("calendar.december")
            ],
            selectedMode: 0,
            editMode: true,
            hoverMode: -1,
            modalType: 0,
            selectedMonth: "",
            selectedDates: [],
            viewModeIndex: 0,
            customDates: [],
            holidayDates: [],
            roomData: {},
            flexible: [],
            flexibleRefund: [],
            sessions: [],
            sessionRefund: [],
            overnight: [],
            overnightRefund: [],
            updateList: [],
            weekDayEnd: [],
            saveButton: true,
            isReady: false,
            debounce: this.createDebounce()
        }
    },
    methods: {
        showPricingType() {
            switch (this.selectedMode) {
                case 0:
                    return this.$t("room_specification.custom_pricing")
                case 1:
                    return this.$t("room_specification.dotm_pricing")
                case 2:
                    return this.$t("room_specification.default_pricing")
            }
        },
        navigateCal(type) {
            if (this.selectedMode !== 2) {
                type === "left"
                    ? this.$refs.cal.changeMonth("prev")
                    : this.$refs.cal.changeMonth("next")
            }
        },
        async selectMode(num) {
            if (this.selectedMode !== num) {
                await this.clearData()
                if (num === 0) {
                    this.editMode = true
                }
                this.showCal = true
                if (num === 1) {
                    await this.getPerMonth()
                }
                if (num === 2) {
                    this.showCal = false
                    await this.getDefaultPricing()
                }
                this.selectedMode = num
            }
        },
        clearData() {
            this.selectedDates = []
            if (this.selectedMode !== 2 && this.showCal) {
                this.$refs.cal.clearSelected()
            }
            this.roomData = {}
            this.flexible = []
            this.sessions = []
            this.overnight = []
            this.updateList = []
        },
        async getCustomPriceDates(val) {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/price/getCustomPriceInMonth/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&month=${`${val.year}-${
                parseInt(val.month) > 9
                    ? parseInt(val.month)
                    : "0" + parseInt(val.month)
            }`}&lang=${this.$i18n.locale}`
            const res = await this.$axios.get(url)
            this.customDates = res.data.data
        },
        async getPublicHoliday(val) {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/utils/getPublicHoliday?month=${
                parseInt(val.month) > 9
                    ? parseInt(val.month)
                    : "0" + parseInt(val.month)
            }&year=${val.year}&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }`
            const res = await this.$axios.get(url)
            this.holidayDates = res.data.data.public_holiday
        },
        async onCalendarLoad(val) {
            this.calLoading = true
            this.selectedMonth = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }`
            if (this.selectedMode === 1 && this.showCal) {
                this.saveButton = false
                this.updateList = []
                await this.getPerMonth()
                this.saveButton = true
            }
            await this.getCustomPriceDates(val)
            await this.getPublicHoliday(val)
            this.calLoading = false
        },
        async selectDate(val) {
            this.tableLoading = true
            let date = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }-${val.date > 9 ? val.date : "0" + val.date}`
            if (this.selectedDates.includes(date)) {
                this.selectedDates.splice(this.selectedDates.indexOf(date), 1)
            } else this.selectedDates.push(date)
            this.selectedDates.sort()
            this.viewModeIndex = 0
            this.updateList = []
            if (this.selectedDates.length > 0) {
                this.debounce(async () => {
                    await this.getPerDay()
                })
            } else {
                this.clearData()
            }
        },
        viewModeSelect(num) {
            this.viewModeIndex = num
            this.getPerDay()
        },
        switchMode() {
            this.editMode = !this.editMode
            this.viewModeIndex = 0
            this.updateList = []
            this.getPerDay()
        },
        iterate(obj) {
            const stack = [obj]
            while (stack?.length > 0) {
                const currentObj = stack.pop()
                Object.keys(currentObj).forEach((key) => {
                    if (key === "price") {
                        currentObj.original_price = currentObj.price
                    }
                    if (
                        typeof currentObj[key] === "object" &&
                        currentObj[key] !== null
                    ) {
                        stack.push(currentObj[key])
                    }
                })
            }
        },
        async getPerDay() {
            if (
                this.selectedDates.length > 0 &&
                (this.editMode || this.viewModeIndex > -1)
            ) {
                this.tableLoading = true
                const url = `${
                    process.env.VUE_APP_API_BASE_URL
                }/price/getPricePerDay/${
                    this.user.space_info_list[this.selectedSpace].space_id
                }?space_type=${
                    this.user.space_info_list[this.selectedSpace].space_type
                }&mode=${
                    this.user.space_info_list[this.selectedSpace].mode
                }&date=${
                    this.editMode
                        ? this.selectedDates[0]
                        : this.selectedDates[this.viewModeIndex]
                }&lang=${this.$i18n.locale}&country_code=${
                    this.user.space_info_list[this.selectedSpace].country_code
                }`
                const res = await this.$axios.get(url)
                this.roomData = Object.fromEntries(
                    Object.entries(res.data.data).sort((x, y) =>
                        x[1].name.localeCompare(y[1].name)
                    )
                )
                this.iterate(this.roomData)
                this.getType()
                this.tableLoading = false
                setTimeout(() => {
                    this.$refs.select_all.checked = true
                    this.selectAll(true)
                }, 100)
                // this.selectedTable = 0
            }
        },
        async getPerMonth() {
            this.tableLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/price/getPricePerMonth/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&mode=${
                this.user.space_info_list[this.selectedSpace].mode
            }&month=${this.selectedMonth}&lang=${this.$i18n.locale}`
            const res = await this.$axios.get(url)
            this.weekDayEnd = res.data.data.weekday_weekend
            this.roomData = Object.fromEntries(
                Object.entries(res.data.data.result).sort((x, y) =>
                    x[1].name.localeCompare(y[1].name)
                )
            )
            this.iterate(this.roomData)
            this.getType()
            this.tableLoading = false
            setTimeout(() => {
                if (this.weekDayEnd.weekday) {
                    this.$refs.select_all0.checked = true
                    this.selectAll(true, 0)
                }
                if (this.weekDayEnd.weekend_1) {
                    this.$refs.select_all1.checked = true
                    this.selectAll(true, 1)
                }
                if (this.weekDayEnd.weekend_2) {
                    this.$refs.select_all2.checked = true
                    this.selectAll(true, 2)
                }
                if (this.weekDayEnd.weekend_3) {
                    this.$refs.select_all3.checked = true
                    this.selectAll(true, 3)
                }
                this.$refs.select_all4.checked = true
                this.selectAll(true, 4)
            }, 100)
        },
        async getDefaultPricing() {
            this.tableLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/price/getDefaultPrice/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&mode=${this.user.space_info_list[this.selectedSpace].mode}&lang=${
                this.$i18n.locale
            }`
            const res = await this.$axios.get(url)
            this.roomData = Object.fromEntries(
                Object.entries(res.data.data.result).sort((x, y) =>
                    x[1].name.localeCompare(y[1].name)
                )
            )
            this.iterate(this.roomData)
            this.weekDayEnd = res.data.data.weekday_weekend
            this.getType()
            this.tableLoading = false
            setTimeout(() => {
                if (this.weekDayEnd.weekday) {
                    this.$refs.select_all0.checked = true
                    this.selectAll(true, 0)
                }
                if (this.weekDayEnd.weekend_1) {
                    this.$refs.select_all1.checked = true
                    this.selectAll(true, 1)
                }
                if (this.weekDayEnd.weekend_2) {
                    this.$refs.select_all2.checked = true
                    this.selectAll(true, 2)
                }
                if (this.weekDayEnd.weekend_3) {
                    this.$refs.select_all3.checked = true
                    this.selectAll(true, 3)
                }
                this.$refs.select_all4.checked = true
                this.selectAll(true, 4)
            }, 100)
        },
        selectAll(val, type) {
            for (let i = 0; i < Object.keys(this.roomData).length; i++) {
                if (this.selectedMode === 0) {
                    this.roomData[Object.keys(this.roomData)[i]].isSelected =
                        val
                } else {
                    this.roomData[Object.keys(this.roomData)[i]][
                        `isSelected_${type}`
                    ] = val
                }
            }
        },
        getType() {
            for (let i = 0; i < Object.keys(this.roomData).length; i++) {
                if (this.roomData[Object.keys(this.roomData)[i]].hour) {
                    this.sortHour(i)
                }
                if (this.roomData[Object.keys(this.roomData)[i]].session) {
                    this.sortSession(i)
                }
                if (this.roomData[Object.keys(this.roomData)[i]].overnight) {
                    this.sortOvernight(i)
                }
                if (this.roomData[Object.keys(this.roomData)[i]].day) {
                    // this.sortOvernight(i)
                }
                if (this.roomData[Object.keys(this.roomData)[i]].hourly) {
                    // this.sortOvernight(i)
                }
                if (this.roomData[Object.keys(this.roomData)[i]].package) {
                    //
                }
            }
            this.flexible.sort((a, b) => a.localeCompare(b))
            this.sessions.sort((a, b) => a.localeCompare(b))
            this.overnight.sort((a, b) => a.localeCompare(b))
            // this.isSelected = true
        },
        sortHour(i) {
            for (let a in Object.keys(
                this.roomData[Object.keys(this.roomData)[i]].hour
            )) {
                if (
                    !this.flexible.includes(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].hour
                        )[a]
                    )
                ) {
                    this.flexible.push(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].hour
                        )[a]
                    )
                    this.flexibleRefund.push({
                        type: Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].hour
                        )[a],
                        refund: this.roomData[Object.keys(this.roomData)[i]]
                            .hour[
                            Object.keys(
                                this.roomData[Object.keys(this.roomData)[i]]
                                    .hour
                            )[a]
                        ].weekday_price
                            ? this.roomData[Object.keys(this.roomData)[i]].hour[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].hour
                                  )[a]
                              ].weekday_price.refund_min
                            : this.roomData[Object.keys(this.roomData)[i]].hour[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].hour
                                  )[a]
                              ].refund_min
                    })
                }
            }
        },
        sortSession(i) {
            for (let a in Object.keys(
                this.roomData[Object.keys(this.roomData)[i]].session
            )) {
                if (
                    !this.sessions.includes(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].session
                        )[a]
                    )
                ) {
                    this.sessions.push(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].session
                        )[a]
                    )
                    this.sessionRefund.push({
                        type: Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]].session
                        )[a],
                        refund: this.roomData[Object.keys(this.roomData)[i]]
                            .session[
                            Object.keys(
                                this.roomData[Object.keys(this.roomData)[i]]
                                    .session
                            )[a]
                        ].weekday_price
                            ? this.roomData[Object.keys(this.roomData)[i]]
                                  .session[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].session
                                  )[a]
                              ].weekday_price.refund_min
                            : this.roomData[Object.keys(this.roomData)[i]]
                                  .session[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].session
                                  )[a]
                              ].refund_min
                    })
                }
            }
        },
        sortOvernight(i) {
            for (let a in Object.keys(
                this.roomData[Object.keys(this.roomData)[i]].overnight
            )) {
                if (
                    !this.overnight.includes(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]]
                                .overnight
                        )[a]
                    )
                ) {
                    this.overnight.push(
                        Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]]
                                .overnight
                        )[a]
                    )
                    this.overnightRefund.push({
                        type: Object.keys(
                            this.roomData[Object.keys(this.roomData)[i]]
                                .overnight
                        )[a],
                        refund: this.roomData[Object.keys(this.roomData)[i]]
                            .overnight[
                            Object.keys(
                                this.roomData[Object.keys(this.roomData)[i]]
                                    .overnight
                            )[a]
                        ].weekday_price
                            ? this.roomData[Object.keys(this.roomData)[i]]
                                  .overnight[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].overnight
                                  )[a]
                              ].weekday_price.refund_min
                            : this.roomData[Object.keys(this.roomData)[i]]
                                  .overnight[
                                  Object.keys(
                                      this.roomData[
                                          Object.keys(this.roomData)[i]
                                      ].overnight
                                  )[a]
                              ].refund_min
                    })
                }
            }
        },
        focusInput(str) {
            if (this.editMode || this.selectedMode > 0) {
                this.$refs[str][0].focus()
            }
        },
        getWeekType(val) {
            switch (val) {
                case 0:
                    return "weekday"
                case 1:
                    return "weekend_1"
                case 2:
                    return "weekend_2"
                case 3:
                    return "weekend_3"
                case 4:
                    return "public_holiday"
            }
        },
        getWeekPrice(val) {
            switch (val) {
                case 0:
                    return "weekday_price"
                case 1:
                    return "weekend_price_1"
                case 2:
                    return "weekend_price_2"
                case 3:
                    return "weekend_price_3"
                case 4:
                    return "public_holiday_price"
            }
        },
        showUpdateType() {
            switch (this.selectedMode) {
                case 0:
                    return "per_day"
                case 1:
                    return "per_month"
                case 2:
                    return "default"
            }
        },
        updatePrice(obj, venue, str, day) {
            let $ref = this.$refs[str][0]
            if (this.selectedMode === 0) {
                if (parseFloat(obj.price) < 0.8 * obj.original_price) {
                    $ref.style.color = "#ff0000"
                    obj.low_price = true
                } else {
                    $ref.style.color = "#fcd34d"
                    obj.low_price = false
                }
                for (let i in this.selectedDates) {
                    let idx = this.updateList.findIndex(
                        (e) =>
                            e.available_book_id === obj.available_book_id &&
                            e.selected_time === this.selectedDates[i] &&
                            e.venue_type === venue
                    )
                    if (idx === -1) {
                        this.updateList.push({
                            selected_time: this.selectedDates[i],
                            available_book_id: obj.available_book_id,
                            venue_type: venue,
                            price: parseFloat(obj.price.replaceAll(",", "")),
                            original_price: obj.original_price,
                            currency: obj.currency,
                            is_holiday: this.holidayDates.includes(
                                this.selectedDates[i]
                            )
                                ? 1
                                : 0
                        })
                    } else
                        this.updateList[idx].price = parseFloat(
                            obj.price.replaceAll(",", "")
                        )
                }
            } else {
                let time = this.getWeekType(day)
                let time_price = this.getWeekPrice(day)
                if (
                    parseFloat(obj[time_price].price) <
                    0.8 * obj[time_price].original_price
                ) {
                    $ref.style.color = "#ff0000"
                    obj[time_price].low_price = true
                } else {
                    $ref.style.color = "#fcd34d"
                    obj[time_price].low_price = false
                }
                let idx = this.updateList.findIndex(
                    (e) =>
                        e.available_book_id === obj.available_book_id &&
                        e.selected_time === time &&
                        e.venue_type === venue
                )
                if (idx === -1) {
                    if (this.selectedMode === 2) {
                        this.updateList.push({
                            selected_time: time,
                            available_book_id: obj.available_book_id,
                            venue_type: venue,
                            price: parseFloat(
                                obj[time_price].price.replaceAll(",", "")
                            ),
                            original_price: obj[time_price].original_price,
                            currency: obj[time_price].currency,
                            is_holiday: time === "public_holiday" ? 1 : 0
                        })
                    } else {
                        this.updateList.push({
                            selected_time: time,
                            selected_month: this.selectedMonth,
                            available_book_id: obj.available_book_id,
                            venue_type: venue,
                            price: parseFloat(
                                obj[time_price].price.replaceAll(",", "")
                            ),
                            original_price: obj[time_price].original_price,
                            currency: obj[time_price].currency,
                            is_holiday: time === "public_holiday" ? 1 : 0
                        })
                    }
                } else
                    this.updateList[idx].price = parseFloat(
                        obj[time_price].price.replaceAll(",", "")
                    )
            }
        },
        async saveChanges() {
            if (this.updateList.length > 0) {
                this.saveButton = false
                this.tableLoading = true
                this.calLoading = true
                let data = {
                    type: this.showUpdateType(),
                    modify_list: this.updateList
                }
                if (data.modify_list.some((e) => isNaN(e.price))) {
                    this.saveButton = true
                    this.tableLoading = false
                    this.calLoading = false
                    return alert("Please fill in the empty field first.")
                }
                if (
                    data.modify_list.some(
                        (e) => parseFloat(e.price) < 0.8 * e.original_price
                    )
                ) {
                    const answer = window.confirm(
                        "Low price detected. Proceed?"
                    )
                    if (!answer) {
                        this.saveButton = true
                        this.tableLoading = false
                        this.calLoading = false
                        return
                    }
                }
                const url = `${
                    process.env.VUE_APP_API_BASE_URL
                }/price/updatePrice/${
                    this.user.space_info_list[this.selectedSpace].space_id
                }?space_type=${
                    this.user.space_info_list[this.selectedSpace].space_type
                }&mode=${
                    this.user.space_info_list[this.selectedSpace].mode
                }&lang=${this.$i18n.locale}`
                await this.$axios
                    .put(url, data)
                    .then((res) => {
                        if (res.data.Success) {
                            this.updateList = []
                            this.saveButton = true
                            this.tableLoading = false
                            this.calLoading = false
                            alert("Success!")
                            if (this.selectedMode === 0) {
                                this.$refs.cal.onLoad()
                                this.getPerDay()
                            }
                            if (this.selectedMode === 1) {
                                this.$refs.cal.onLoad()
                                this.getPerMonth()
                            }
                            if (this.selectedMode === 2) {
                                this.getDefaultPricing()
                            }
                        }
                    })
                    .catch((error) => {
                        this.saveButton = true
                        this.tableLoading = false
                        this.calLoading = false
                        if (error.response) {
                            // Request made and server responded
                            alert(error.response.data.data.err_message)
                        } else if (error.request) {
                            // The request was made but no response was received
                        } else {
                            // Something happened in setting up the request that triggered an Error
                        }
                    })
                setTimeout(() => {
                    this.saveButton = true
                    this.tableLoading = false
                    this.calLoading = false
                }, 10000)
            } else {
                this.saveButton = true
                this.tableLoading = false
                this.calLoading = false
                return alert("Please change something first.")
            }
        },
        isNumberKey(e) {
            let isModifierkeyPressed = e.metaKey || e.ctrlKey || e.shiftKey
            let isCursorMoveOrDeleteAction =
                [46, 8, 37, 38, 39, 40, 9].indexOf(e.keyCode) !== -1
            let isNumKeyPressed =
                (e.keyCode >= 48 && e.keyCode <= 58) ||
                (e.keyCode >= 96 && e.keyCode <= 105) ||
                e.keyCode === 110 ||
                e.keyCode === 190
            let vKey = 86,
                cKey = 67,
                aKey = 65
            switch (true) {
                case isCursorMoveOrDeleteAction:
                case isModifierkeyPressed === false && isNumKeyPressed:
                case (e.metaKey || e.ctrlKey) &&
                    [vKey, cKey, aKey].indexOf(e.keyCode) !== -1:
                    break
                default:
                    e.preventDefault()
            }
        },
        createDebounce() {
            let timeout = null
            return function (fnc, delayMs) {
                clearTimeout(timeout)
                timeout = setTimeout(() => {
                    fnc()
                }, delayMs || 1000)
            }
        }
    },
    watch: {
        async selectedSpace(to, from) {
            this.calLoading = true
            this.clearData()
            let val = {
                year: this.selectedMonth.split("-")[0],
                month: this.selectedMonth.split("-")[1]
            }
            await this.getCustomPriceDates(val)
            await this.getPublicHoliday(val)
            this.calLoading = false
            if (this.selectedMode === 1) {
                await this.getPerMonth()
            }
            if (this.selectedMode === 2) {
                await this.getDefaultPricing()
            }
            this.isReady = true
        }
    },
    mounted() {
        this.$refs.tab.focus()
        setTimeout(() => {
            this.isReady = true
        }, 500)
    }
}
</script>

<style>
.clip {
    clip-path: polygon(0 0, 0% 100%, 100% 50%);
}
.nav1 {
    background-color: rgb(166, 188, 177);
}
.nav2 {
    background-color: rgb(205, 214, 210);
}
.nav3 {
    background-color: rgb(216, 222, 216);
}
.cb {
    accent-color: #fbbf24;
}
.dot {
    background-color: rgb(192, 202, 73);
}
</style>
