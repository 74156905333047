<template>
    <div class="p-8 mt-4 lg:mt-0 lg:px-20 desktop:px-36 bg-gray-100">
        <h2 class="flex font-medium text-2xl mb-5">
            {{ $t("setting.setting") }}
        </h2>
        <div class="flex justify-center">
            <div
                class="
                    bg-white
                    mt-8
                    px-8
                    pt-8
                    pb-4
                    w-full
                    max-w-7xl
                    rounded-md
                    shadow-lg
                "
            >
                <div
                    class="
                        hidden
                        lg:grid lg:grid-cols-2
                        w-full
                        grid-flow-row
                        gap-4
                    "
                >
                    <div class="flex">
                        First Name:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex">
                        Last Name:<input
                            class="ml-1 border border-gray-400 rounded-md"
                        />
                    </div>
                    <div class="flex">
                        Email:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex">
                        Job Title:<input
                            class="ml-1 border border-gray-400 rounded-md"
                        />
                    </div>
                    <div class="flex">
                        {{ $t("setting.language") }}:<select
                            @change="setLang($event.target.value)"
                            class="ml-1 border border-gray-400 rounded-md"
                        >
                            <option
                                v-for="(locale, index) in locales"
                                :value="locale.code"
                                :key="index"
                                :selected="locale.code === $i18n.locale"
                            >
                                {{ $t(`${locale.code}`) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="block lg:hidden text-sm">
                    <div class="flex mb-4 justify-end">
                        First Name:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex mb-4 justify-end">
                        Last Name:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex mb-4 justify-end">
                        Email:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex mb-4 justify-end">
                        Job Title:
                        <input class="ml-1 border border-gray-400 rounded-md" />
                    </div>
                    <div class="flex mb-4 justify-end">
                        {{ $t("setting.language") }}:
                        <select
                            @change="setLang($event.target.value)"
                            class="ml-1 border border-gray-400 rounded-md"
                        >
                            <option
                                v-for="(locale, index) in locales"
                                :value="locale.code"
                                :key="index"
                                :selected="locale.code === $i18n.locale"
                            >
                                {{ $t(`${locale.code}`) }}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="flex justify-end w-full">
                    <div class="flex flex-wrap">
                        <button
                            @click="resetPW"
                            class="cfn-btn px-2 py-1 mr-4 mt-2"
                            :class="{ 'filter grayscale': pwBtn }"
                            :disabled="pwBtn"
                        >
                            {{ $t("setting.change_pw") }}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Loader from "@/components/Loader"
import { setSavedLocale } from "@/util/locate"
import { Locale } from "@/constants"
export default {
    name: "Setting",
    components: { Loader },
    props: {
        user: { required: true, type: Object },
        mode: { required: false, type: Number }
    },
    emits: ["localeUpdate"],
    data() {
        return {
            isLoading: true,
            pwBtn: false
        }
    },
    computed: {
        locales() {
            return Locale.SUPPORTED_LOCALES
        }
    },
    methods: {
        resetPW() {
            this.pwBtn = true
            const data = {
                client_id: process.env.VUE_APP_AUTH0_CLIENT_ID,
                email: this.$auth0.user.value.email,
                connection: "Username-Password-Authentication"
            }
            this.$axios
                .post(
                    `https://${process.env.VUE_APP_AUTH0_DOMAIN}/dbconnections/change_password`,
                    data,
                    {
                        headers: {
                            "content-type": "application/json"
                        }
                    }
                )
                .then((r) => {
                    this.pwBtn = false
                    alert(r.data)
                })
                .catch((error) => {
                    this.pwBtn = false
                    if (error.response) {
                        // Request made and server responded
                        alert(error.response)
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        },
        notifyMe() {
            // Let's check if the browser supports notifications
            if (!("Notification" in window)) {
                alert("This browser does not support desktop notification")
            }

            // Let's check whether notification permissions have already been granted
            else if (Notification.permission === "granted") {
                const myNoti = new Notification("Notification Title", {
                    body: "This is my notification",
                    icon: "ICON_URL",
                    image: "IMAGE_URL"
                })
            }

            // Otherwise, we need to ask the user for permission
            else if (Notification.permission !== "denied") {
                Notification.requestPermission().then(function (permission) {
                    // If the user accepts, let's create a notification
                    if (permission === "granted") {
                        let notification = new Notification("Hi there!")
                    }
                })
            } else {
                // console.log("Denied")
            }

            // At last, if the user has denied notifications, and you
            // want to be respectful there is no need to bother them any more.
        },
        async setLang(lang) {
            this.$i18n.locale = lang
            setSavedLocale(lang)
            await this.$router.push({
                name: this.$route.name,
                params: { lang: lang, role: this.user.portal_user_role_level }
            })
            this.$emit("localeUpdate")
        }
    }
}
</script>

<style scoped></style>
