<template>
    <div
        v-if="price"
        class="py-0.5 text-white text-sm text-right pr-1 h-full"
        :class="{
            'bg-green-500':
                getComparisonPercentage(price.percentage_difference) === 'g',
            'bg-yellow-400':
                getComparisonPercentage(price.percentage_difference) === 'y',
            'bg-orange-500':
                getComparisonPercentage(price.percentage_difference) === 'o',
            'bg-red-500':
                getComparisonPercentage(price.percentage_difference) === 'r',
            'bg-gray-400':
                getComparisonPercentage(price.percentage_difference) === null
        }"
    >
        <div v-if="price.flow_min_price != null" class="whitespace-nowrap">
            FLOW: {{ price.flow_min_price }}
        </div>
        <div v-if="price.eps_min_price != null" class="whitespace-nowrap">
            EPS: {{ price.eps_min_price }}
        </div>
        <div
            v-if="price.percentage_difference != null"
            class="whitespace-nowrap"
        >
            {{ price.percentage_difference.toFixed(2) }}%
        </div>
    </div>
</template>

<script>
export default {
    name: "ComparisonCell",
    props: {
        price: { required: true, type: Object },
        country: { required: true, type: String }
    },
    data() {
        return {
            rate: {
                HK: {
                    g: 35,
                    y: 17.5
                },
                MO: {
                    g: 35,
                    y: 17.5
                },
                MY: {
                    g: 45,
                    y: 25
                },
                SG: {
                    g: 40,
                    y: 25
                },
                VN: {
                    g: 45,
                    y: 25
                }
            }
        }
    },
    methods: {
        getComparisonPercentage(pct) {
            if (pct != null) {
                if (pct >= this.rate[this.country]["g"]) {
                    return "g"
                } else if (
                    pct < this.rate[this.country]["g"] &&
                    pct >= this.rate[this.country]["y"]
                ) {
                    return "y"
                } else if (pct < this.rate[this.country]["y"] && pct > 0) {
                    return "o"
                } else {
                    return "r"
                }
            } else return null
        }
    }
}
</script>

<style scoped></style>
