<template>
    <svg
        xmlns="http://www.w3.org/2000/svg"
        class="stroke-current icon icon-tabler icon-tabler-cash"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="#00b341"
        fill="none"
        stroke-linecap="round"
        stroke-linejoin="round"
    >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="7" y="9" width="14" height="10" rx="2" />
        <circle cx="14" cy="14" r="2" />
        <path
            d="M17 9v-2a2 2 0 0 0 -2 -2h-10a2 2 0 0 0 -2 2v6a2 2 0 0 0 2 2h2"
        />
    </svg>
</template>

<script>
export default {
    name: "FreeCancel"
}
</script>

<style scoped></style>
