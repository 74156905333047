<template>
    <div class="p-4 lg:p-8 mt-8 lg:mt-0 lg:px-20 desktop:px-36 bg-gray-100">
        <h2 class="flex font-medium text-2xl mb-5">
            {{ $t("dashboard.dashboard") }}
        </h2>
        <div class="bg-white p-8 rounded-md shadow-lg">
            <div class="flex justify-between">
                <h2 class="flex font-medium text-xl mb-5">
                    {{ $t("dashboard.room_available") }}
                </h2>
                <button v-if="!showCalendar" @click="showCalendar = true">
                    {{ $t("dashboard.show_calendar") }}
                </button>
                <button v-if="showCalendar" @click="showCalendar = false">
                    {{ $t("dashboard.hide_calendar") }}
                </button>
            </div>
            <div class="flex justify-center">
                <div class="w-full max-w-5xl desktop:max-w-7xl">
                    <PriceComparison
                        v-if="!isLoading"
                        class="border rounded-md py-1 px-2 mb-4 bg-white w-full"
                        :country_code="
                            this.user.space_info_list[this.selectedSpace]
                                .country_code
                        "
                        :id="
                            this.user.space_info_list[this.selectedSpace]
                                .space_id
                        "
                    />
                    <div
                        class="
                            flex
                            justify-around
                            mb-4
                            text-xl
                            font-medium
                            items-center
                        "
                    >
                        <button
                            @click="changeMonth('prev')"
                            v-if="!showCalendar"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    icon icon-tabler icon-tabler-chevrons-left
                                "
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <polyline points="11 7 6 12 11 17" />
                                <polyline points="17 7 12 12 17 17" />
                            </svg>
                        </button>
                        <button
                            @click="changeDate('prev')"
                            v-if="!showCalendar"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    icon icon-tabler icon-tabler-chevron-left
                                "
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <polyline points="15 6 9 12 15 18" />
                            </svg>
                        </button>

                        {{ displayDate }}
                        <button
                            @click="changeDate('next')"
                            v-if="!showCalendar"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    icon icon-tabler icon-tabler-chevron-right
                                "
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <polyline points="9 6 15 12 9 18" />
                            </svg>
                        </button>
                        <button
                            @click="changeMonth('next')"
                            v-if="!showCalendar"
                        >
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                class="
                                    icon icon-tabler icon-tabler-chevrons-right
                                "
                                width="24"
                                height="24"
                                viewBox="0 0 24 24"
                                stroke-width="1.5"
                                stroke="#000000"
                                fill="none"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            >
                                <path
                                    stroke="none"
                                    d="M0 0h24v24H0z"
                                    fill="none"
                                />
                                <polyline points="7 7 12 12 7 17" />
                                <polyline points="13 7 18 12 13 17" />
                            </svg>
                        </button>
                    </div>
                </div>
            </div>
            <transition
                enter-active-class="transition-opacity ease-linear duration-200"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-linear duration-200"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                ><div v-if="showCalendar" class="flex justify-center">
                    <Calendar
                        @onSelect="selectDate"
                        @onLoad="getHolidayDate"
                        @onChange="getHolidayDate"
                        :cal-holiday="calHoliday"
                        :sel-date="selectedDate"
                        class="
                            -mx-6
                            lg:-mx-0
                            w-full
                            max-w-7xl
                            bg-gray-300
                            rounded-md
                        "
                    /></div
            ></transition>
            <div class="flex justify-center">
                <div class="w-full max-w-7xl mt-4">
                    <div
                        v-if="operation && !operation.open"
                        class="
                            bg-red-100
                            border border-red-200
                            text-red-600
                            rounded
                            p-2
                        "
                    >
                        Room suspended today
                    </div>
                </div>
            </div>
            <div class="flex justify-center">
                <div class="mt-4 rounded-md flex flex-1 max-w-7xl">
                    <div v-if="showTab" class="flex">
                        <button
                            @click="selectedTab = 0"
                            class="
                                bg-white
                                border
                                px-3
                                py-1
                                rounded-t-md
                                focus:outline-none
                                transition
                                ease-linear
                                duration-200
                            "
                            :class="{
                                ' bg-gray-300': selectedTab !== 0
                            }"
                        >
                            {{ $t("dashboard.flexible") }}
                        </button>
                        <button
                            @click="selectTab"
                            class="
                                bg-white
                                border
                                px-3
                                py-1
                                rounded-t-md
                                -ml-1
                                focus:outline-none
                                transition
                                ease-linear
                                duration-200
                            "
                            :class="{
                                ' bg-gray-300': selectedTab !== 1
                            }"
                        >
                            {{ $t("dashboard.others") }}
                        </button>
                    </div>
                </div>
            </div>
            <div class="flex justify-center">
                <div class="w-full max-w-7xl">
                    <div v-if="selectedTab === 0">
                        <div
                            v-for="(room, room_name, index) in roomType"
                            :key="index"
                            class="mt-4"
                            :class="{ hidden: !room.hour }"
                        >
                            <button
                                @click="openAccordion(index)"
                                class="
                                    w-full
                                    rounded-t-md
                                    py-1
                                    bg-gray-300
                                    flex
                                    items-center
                                    justify-center
                                "
                                :class="{
                                    'rounded-b-md':
                                        !this.openIndex.includes(index)
                                }"
                            >
                                {{ room.name }}
                            </button>
                            <transition
                                enter-active-class="transition-all ease-linear transform"
                                enter-from-class="max-h-0"
                                enter-to-class="max-h-40"
                                leave-active-class="transition-all ease-linear transform"
                                leave-from-class="max-h-40"
                                leave-to-class="max-h-0"
                            >
                                <div
                                    class="overflow-hidden text-center"
                                    v-if="this.openIndex.includes(index)"
                                >
                                    <div
                                        v-for="(duration, name, idx) in sortObj(
                                            room.hour
                                        )"
                                        :key="idx"
                                    >
                                        <div
                                            class="
                                                p-4
                                                flex
                                                border-r
                                                border-l
                                                border-b
                                                border-gray-300
                                                hover:bg-gray-100
                                                transition
                                                duration-300
                                                ease-linear
                                                justify-center
                                                items-center
                                            "
                                            :class="{
                                                'rounded-b-md b-1':
                                                    idx ===
                                                    Object.keys(room.hour)
                                                        .length -
                                                        1
                                            }"
                                        >
                                            <div v-if="duration.length > 0">
                                                <div
                                                    class="flex justify-center"
                                                >
                                                    <div
                                                        v-if="
                                                            (duration[0]
                                                                .refund_min ||
                                                                duration[0]
                                                                    .refund_min ===
                                                                    0) &&
                                                            duration[0]
                                                                .refund_min >= 0
                                                        "
                                                    >
                                                        <FreeCancel
                                                            class="
                                                                text-green-600
                                                                mr-1
                                                            "
                                                        />
                                                    </div>
                                                    <div v-else>
                                                        <NoCancel />
                                                    </div>
                                                    <img
                                                        v-if="
                                                            duration[0]
                                                                .dist_logo
                                                        "
                                                        :src="
                                                            duration[0]
                                                                .dist_logo
                                                        "
                                                        class="
                                                            w-6
                                                            h-auto
                                                            object-contain
                                                        "
                                                    />
                                                </div>
                                                <div class="flex items-center">
                                                    {{ name }}
                                                </div>
                                                <div
                                                    v-if="
                                                        Object.keys(priceData)
                                                            .length > 0 &&
                                                        priceData[room_name] &&
                                                        priceData[room_name][
                                                            duration[0]
                                                                .book_type
                                                        ] &&
                                                        priceData[room_name][
                                                            duration[0]
                                                                .book_type
                                                        ][name]
                                                    "
                                                    class="flex justify-center"
                                                >
                                                    <div
                                                        class="
                                                            text-white
                                                            px-4
                                                            py-0.5
                                                            bg-yellow-400
                                                            rounded-md
                                                        "
                                                    >
                                                        $
                                                        <span
                                                            class="font-medium"
                                                            >{{
                                                                priceData[
                                                                    room_name
                                                                ][
                                                                    duration[0]
                                                                        .book_type
                                                                ][name].price
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div v-else>
                                                <div class="flex items-center">
                                                    {{ name }}
                                                </div>
                                                <div
                                                    v-if="
                                                        Object.keys(priceData)
                                                            .length > 0 &&
                                                        priceData[room_name] &&
                                                        priceData[room_name][
                                                            'hour'
                                                        ] &&
                                                        priceData[room_name][
                                                            'hour'
                                                        ][name]
                                                    "
                                                    class="flex justify-center"
                                                >
                                                    <div
                                                        class="
                                                            text-white
                                                            px-4
                                                            py-0.5
                                                            bg-yellow-400
                                                            rounded-md
                                                        "
                                                    >
                                                        $
                                                        <span
                                                            class="font-medium"
                                                            >{{
                                                                priceData[
                                                                    room_name
                                                                ]["hour"][name]
                                                                    .price
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                            <div
                                                v-if="duration.length > 0"
                                                class="ml-3 w-5/6 h-full"
                                                :id="`chart_${index}_${idx}`"
                                            ></div>
                                            <div
                                                v-else
                                                class="ml-3 w-5/6 h-full"
                                            >
                                                N/A
                                            </div>
                                        </div>
                                    </div>
                                </div></transition
                            >
                        </div>
                    </div>
                    <div class="overflow-x-scroll" v-if="selectedTab === 1">
                        <table class="table-auto min-w-full divide-gray-600">
                            <thead>
                                <tr>
                                    <th
                                        class="
                                            pl-4
                                            py-5
                                            text-left text-2xl
                                            font-medium
                                            text-black
                                            tracking-wider
                                        "
                                    >
                                        {{ $t("dashboard.room_type") }}
                                    </th>
                                    <th
                                        v-for="(session, index) in sessions"
                                        :key="index"
                                        class="
                                            pl-4
                                            py-3
                                            text-center text-lg
                                            font-medium
                                            text-black
                                            tracking-wider
                                            align-bottom
                                        "
                                    >
                                        <div
                                            v-if="
                                                (session.refund ||
                                                    session.refund === 0) &&
                                                session.refund >= 0
                                            "
                                            class="flex justify-center"
                                        >
                                            <FreeCancel
                                                class="text-green-600 mt-1 mr-1"
                                            />
                                        </div>
                                        <div v-else class="flex justify-center">
                                            <NoCancel />
                                        </div>
                                        <div class="flex justify-center">
                                            Session
                                            <br />
                                            {{ formatTimeString(session.type) }}
                                            <br />
                                            {{
                                                `(${session.type.substring(
                                                    session.type.indexOf("(") +
                                                        1
                                                )}`
                                            }}
                                        </div>
                                    </th>
                                    <th
                                        v-for="(night, index) in overnight"
                                        :key="index"
                                        class="
                                            pl-4
                                            py-3
                                            text-center text-lg
                                            font-medium
                                            text-black
                                            tracking-wider
                                            align-bottom
                                        "
                                    >
                                        <div
                                            v-if="
                                                (night.refund ||
                                                    night.refund === 0) &&
                                                night.refund >= 0
                                            "
                                            class="flex justify-center"
                                        >
                                            <FreeCancel
                                                class="text-green-600 mt-1 mr-1"
                                            />
                                        </div>
                                        <div v-else class="flex justify-center">
                                            <NoCancel />
                                        </div>
                                        <div class="flex justify-center">
                                            {{ $t("dashboard.overnight") }}
                                            <br />
                                            {{ formatTimeString(night.type) }}
                                            <br />
                                            {{
                                                `(${night.type.substring(
                                                    night.type.indexOf("(") + 1
                                                )}`
                                            }}
                                        </div>
                                    </th>
                                </tr>
                            </thead>
                            <tbody class="bg-white">
                                <tr
                                    class="
                                        bg-white
                                        text-left
                                        font-medium
                                        text-sm
                                    "
                                    :class="{
                                        'bg-gray-200': index % 2 === 0
                                    }"
                                    v-for="(room, name, index) in roomType"
                                    :key="index"
                                >
                                    <td class="pl-4 py-1">
                                        <div class="flex items-center">
                                            {{ room.name }}
                                        </div>
                                    </td>
                                    <td
                                        v-for="(ses, idx) in sessions"
                                        :key="idx"
                                        class="pl-4 py-1 text-center"
                                    >
                                        <div v-if="!room['session']"></div>
                                        <div v-else>
                                            <div
                                                v-for="(ses, i) in Object.keys(
                                                    room['session']
                                                ).filter((x) => x === ses.type)"
                                                :key="i"
                                            >
                                                <div
                                                    class="flex justify-center"
                                                >
                                                    <img
                                                        v-if="
                                                            room['session'][ses]
                                                                .dist_logo
                                                        "
                                                        :src="
                                                            room['session'][ses]
                                                                .dist_logo
                                                        "
                                                        class="
                                                            w-6
                                                            h-auto
                                                            object-contain
                                                            mr-1
                                                        "
                                                    />
                                                    <div>
                                                        {{
                                                            room["session"][ses]
                                                                .reservation_count[0]
                                                                .count
                                                        }}
                                                        <br />
                                                        <span
                                                            class="
                                                                border-t
                                                                border-black
                                                                px-2
                                                            "
                                                            >{{
                                                                room["session"][
                                                                    ses
                                                                ].availability
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        Object.keys(priceData)
                                                            .length > 0 &&
                                                        priceData[name] &&
                                                        priceData[name][
                                                            'session'
                                                        ] &&
                                                        priceData[name][
                                                            'session'
                                                        ][ses]
                                                    "
                                                    class="flex justify-center"
                                                >
                                                    <div
                                                        class="
                                                            text-white
                                                            px-4
                                                            py-0.5
                                                            bg-yellow-400
                                                            rounded-md
                                                        "
                                                    >
                                                        $
                                                        <span
                                                            class="font-medium"
                                                            >{{
                                                                priceData[name][
                                                                    "session"
                                                                ][ses].price
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td
                                        v-for="(ses, idx) in overnight"
                                        :key="idx"
                                        class="pl-4 py-1 text-center"
                                    >
                                        <div v-if="!room['overnight']"></div>
                                        <div v-else>
                                            <div
                                                v-for="(ses, i) in Object.keys(
                                                    room['overnight']
                                                ).filter((x) => x === ses.type)"
                                                :key="i"
                                            >
                                                <div
                                                    class="flex justify-center"
                                                >
                                                    <img
                                                        v-if="
                                                            room['overnight'][
                                                                ses
                                                            ].dist_logo
                                                        "
                                                        :src="
                                                            room['overnight'][
                                                                ses
                                                            ].dist_logo
                                                        "
                                                        class="
                                                            w-6
                                                            h-auto
                                                            object-contain
                                                            mr-1
                                                        "
                                                    />
                                                    <div>
                                                        {{
                                                            room["overnight"][
                                                                ses
                                                            ]
                                                                .reservation_count[0]
                                                                .count
                                                        }}
                                                        <br />
                                                        <span
                                                            class="
                                                                border-t
                                                                border-black
                                                                px-2
                                                            "
                                                            >{{
                                                                room[
                                                                    "overnight"
                                                                ][ses]
                                                                    .availability
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                                <div
                                                    v-if="
                                                        Object.keys(priceData)
                                                            .length > 0 &&
                                                        priceData[name] &&
                                                        priceData[name][
                                                            'overnight'
                                                        ] &&
                                                        priceData[name][
                                                            'overnight'
                                                        ][ses]
                                                    "
                                                    class="flex justify-center"
                                                >
                                                    <div
                                                        class="
                                                            text-white
                                                            px-4
                                                            py-0.5
                                                            bg-yellow-400
                                                            rounded-md
                                                        "
                                                    >
                                                        $
                                                        <span
                                                            class="font-medium"
                                                            >{{
                                                                priceData[name][
                                                                    "overnight"
                                                                ][ses].price
                                                            }}</span
                                                        >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <Legend :type="0" />
                </div>
            </div>
        </div>
        <div class="bg-white mt-8 p-4 lg:p-8 rounded-md shadow-lg">
            <div class="flex justify-between">
                <h2 class="flex font-medium text-lg lg:text-xl mb-5">
                    {{ $t("dashboard.custom_room_availability") }}
                </h2>
            </div>
            <!--            <div v-if="!isLoading">-->
            <!--                <AvailabilityTable-->
            <!--                    v-if="selectedDate && roomType"-->
            <!--                    :data="roomType"-->
            <!--                    :user="user"-->
            <!--                    :selected-space="selectedSpace"-->
            <!--                    :date="selectedDate"-->
            <!--                    @onSuccess="getAvailability"-->
            <!--                />-->
            <!--            </div>-->
            <!--            <div class="flex justify-center w-full" v-else>-->
            <!--                <Loader class="py-10" type="lg" />-->
            <!--            </div>-->
            <div v-if="!isLoading">
                <div class="flex justify-center">
                    <div class="w-full max-w-7xl">
                        <div class="flex flex-1 justify-end lg:-mx-4">
                            <button
                                class="
                                    px-2
                                    py-1
                                    text-white
                                    rounded-md
                                    bg-yellow-400
                                    shadow-lg
                                    hover:bg-yellow-300
                                    transition
                                    duration-300
                                    ease-in-out
                                "
                                @click="selectAllSpace"
                            >
                                {{
                                    selectAll
                                        ? $t("dashboard.unselect_all")
                                        : $t("dashboard.select_all")
                                }}
                            </button>
                            <button
                                class="
                                    ml-4
                                    px-2
                                    py-1
                                    text-white
                                    rounded-md
                                    bg-yellow-400
                                    shadow-lg
                                    hover:bg-yellow-300
                                    transition
                                    duration-300
                                    ease-in-out
                                "
                                @click="setAllZero"
                            >
                                {{ $t("dashboard.set_zero") }}
                            </button>
                        </div>
                        <div class="overflow-x-scroll -mx-4">
                            <table
                                class="table-auto min-w-full divide-gray-600"
                            >
                                <thead>
                                    <tr>
                                        <th
                                            class="
                                                pl-2
                                                sm:pl-4
                                                py-5
                                                text-left
                                                lg:text-2xl
                                                font-medium
                                                text-black
                                                tracking-wider
                                            "
                                        >
                                            {{ $t("dashboard.room_type") }}
                                        </th>
                                        <th
                                            v-for="(session, index) in flexible"
                                            :key="index"
                                            class="
                                                sm:pl-4
                                                py-3
                                                text-center
                                                lg:text-lg
                                                font-medium
                                                text-black
                                                tracking-wider
                                                align-baseline
                                            "
                                        >
                                            <div
                                                v-if="
                                                    (session.refund ||
                                                        session.refund === 0) &&
                                                    session.refund >= 0
                                                "
                                                class="flex justify-center"
                                            >
                                                <FreeCancel
                                                    class="text-green-600"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="flex justify-center"
                                            >
                                                <NoCancel />
                                            </div>
                                            <div
                                                @click="
                                                    checkAvailability(
                                                        1,
                                                        session
                                                    )
                                                "
                                                class="cursor-pointer"
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        v-model="
                                                            session.isSelected
                                                        "
                                                        @change="
                                                            checkAvailability(
                                                                0,
                                                                session
                                                            )
                                                        "
                                                        type="checkbox"
                                                        class="mr-1"
                                                    />
                                                    <span
                                                        class="
                                                            whitespace-nowrap
                                                        "
                                                        >{{
                                                            $t(
                                                                "dashboard.flexible"
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="mx-auto">
                                                    {{ session.type }}
                                                </div>
                                            </div>
                                        </th>
                                        <th
                                            v-for="(session, index) in sessions"
                                            :key="index"
                                            class="
                                                sm:pl-4
                                                py-3
                                                text-center
                                                lg:text-lg
                                                font-medium
                                                text-black
                                                tracking-wider
                                                align-baseline
                                            "
                                        >
                                            <div
                                                v-if="
                                                    (session.refund ||
                                                        session.refund === 0) &&
                                                    session.refund >= 0
                                                "
                                                class="flex justify-center"
                                            >
                                                <FreeCancel
                                                    class="text-green-600"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="flex justify-center"
                                            >
                                                <NoCancel />
                                            </div>
                                            <div
                                                @click="
                                                    checkAvailability(
                                                        1,
                                                        session
                                                    )
                                                "
                                                class="cursor-pointer"
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        v-model="
                                                            session.isSelected
                                                        "
                                                        @change="
                                                            checkAvailability(
                                                                0,
                                                                session
                                                            )
                                                        "
                                                        type="checkbox"
                                                        class="mr-1"
                                                    />
                                                    <span
                                                        class="
                                                            whitespace-nowrap
                                                        "
                                                        >{{
                                                            $t(
                                                                "dashboard.session"
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="mx-auto">
                                                    {{
                                                        formatTimeString(
                                                            session.type
                                                        )
                                                    }}
                                                    <br />
                                                    {{
                                                        `(${session.type.substring(
                                                            session.type.indexOf(
                                                                "("
                                                            ) + 1
                                                        )}`
                                                    }}
                                                </div>
                                            </div>
                                        </th>
                                        <th
                                            v-for="(
                                                session, index
                                            ) in overnight"
                                            :key="index"
                                            class="
                                                sm:pl-4
                                                py-3
                                                text-center
                                                lg:text-lg
                                                font-medium
                                                text-black
                                                tracking-wider
                                                align-baseline
                                            "
                                        >
                                            <div
                                                v-if="
                                                    (session.refund ||
                                                        session.refund === 0) &&
                                                    session.refund >= 0
                                                "
                                                class="flex justify-center"
                                            >
                                                <FreeCancel
                                                    class="text-green-600"
                                                />
                                            </div>
                                            <div
                                                v-else
                                                class="flex justify-center"
                                            >
                                                <NoCancel />
                                            </div>
                                            <div
                                                @click="
                                                    checkAvailability(
                                                        1,
                                                        session
                                                    )
                                                "
                                                class="cursor-pointer"
                                            >
                                                <div
                                                    class="
                                                        flex
                                                        justify-center
                                                        items-center
                                                    "
                                                >
                                                    <input
                                                        v-model="
                                                            session.isSelected
                                                        "
                                                        @change="
                                                            checkAvailability(
                                                                0,
                                                                session
                                                            )
                                                        "
                                                        type="checkbox"
                                                        class="mr-1"
                                                    />
                                                    <span
                                                        class="
                                                            whitespace-nowrap
                                                        "
                                                        >{{
                                                            $t(
                                                                "dashboard.overnight"
                                                            )
                                                        }}</span
                                                    >
                                                </div>
                                                <div class="mx-auto">
                                                    {{
                                                        formatTimeString(
                                                            session.type
                                                        )
                                                    }}
                                                    <br />
                                                    {{
                                                        `(${session.type.substring(
                                                            session.type.indexOf(
                                                                "("
                                                            ) + 1
                                                        )}`
                                                    }}
                                                </div>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>
                                <tbody class="bg-white">
                                    <tr
                                        class="
                                            bg-white
                                            text-left
                                            font-medium
                                            text-sm
                                        "
                                        :class="{
                                            'bg-gray-200': index % 2 === 0
                                        }"
                                        v-for="(room, name, index) in roomType"
                                        :key="index"
                                    >
                                        <td class="pl-2 sm:pl-4 py-1">
                                            <div class="flex items-center">
                                                <input
                                                    v-model="room.isSelected"
                                                    @change="
                                                        checkAvailability(
                                                            0,
                                                            room
                                                        )
                                                    "
                                                    type="checkbox"
                                                />
                                                <button
                                                    @click="
                                                        checkAvailability(
                                                            1,
                                                            room
                                                        )
                                                    "
                                                    class="
                                                        pl-2
                                                        py-1
                                                        text-sm
                                                        lg:text-base
                                                    "
                                                >
                                                    {{ room.name }}
                                                </button>
                                            </div>
                                        </td>
                                        <td
                                            v-for="(ses, idx) in flexible"
                                            :key="idx"
                                            class="sm:pl-4 py-1 text-center"
                                        >
                                            <div v-if="!room['hour']"></div>
                                            <div v-else>
                                                <div
                                                    v-for="(
                                                        h, i
                                                    ) in Object.keys(
                                                        room['hour']
                                                    ).filter(
                                                        (x) => x === ses.type
                                                    )"
                                                    :key="i"
                                                >
                                                    <div
                                                        v-if="
                                                            room.isSelected &&
                                                            ses.isSelected &&
                                                            room['hour'][h][0]
                                                                .rights !==
                                                                'readonly'
                                                        "
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{
                                                            room["hour"][h][0]
                                                                .availability
                                                        }}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-chevrons-right
                                                                stroke-current
                                                            "
                                                            :class="{
                                                                'text-black':
                                                                    !room[
                                                                        'hour'
                                                                    ][h][0]
                                                                        .new_availability,
                                                                'text-red-500':
                                                                    room[
                                                                        'hour'
                                                                    ][h][0]
                                                                        .new_availability
                                                            }"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#000000"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <polyline
                                                                points="7 7 12 12 7 17"
                                                            />
                                                            <polyline
                                                                points="13 7 18 12 13 17"
                                                            />
                                                        </svg>
                                                        <input
                                                            v-model="
                                                                room['hour'][
                                                                    h
                                                                ][0]
                                                                    .new_availability
                                                            "
                                                            @focus="
                                                                $event.target.select()
                                                            "
                                                            @keydown="
                                                                isNumber($event)
                                                            "
                                                            @change="
                                                                isHourChanged = true
                                                            "
                                                            maxlength="4"
                                                            class="
                                                                ml-1
                                                                pl-1
                                                                border-b
                                                                border-black
                                                                bg-transparent
                                                                w-12
                                                                focus:outline-none
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            v-for="(ses, idx) in sessions"
                                            :key="idx"
                                            class="sm:pl-4 py-1 text-center"
                                        >
                                            <div v-if="!room['session']"></div>
                                            <div v-else>
                                                <div
                                                    v-for="(
                                                        s, i
                                                    ) in Object.keys(
                                                        room['session']
                                                    ).filter(
                                                        (x) => x === ses.type
                                                    )"
                                                    :key="i"
                                                >
                                                    <div
                                                        v-if="
                                                            room.isSelected &&
                                                            ses.isSelected &&
                                                            room['session'][s]
                                                                .rights !==
                                                                'readonly'
                                                        "
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{
                                                            room["session"][s]
                                                                .availability
                                                        }}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-chevrons-right
                                                                stroke-current
                                                            "
                                                            :class="{
                                                                'text-black':
                                                                    !room[
                                                                        'session'
                                                                    ][s]
                                                                        .new_availability,
                                                                'text-red-500':
                                                                    room[
                                                                        'session'
                                                                    ][s]
                                                                        .new_availability
                                                            }"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#000000"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <polyline
                                                                points="7 7 12 12 7 17"
                                                            />
                                                            <polyline
                                                                points="13 7 18 12 13 17"
                                                            />
                                                        </svg>
                                                        <input
                                                            v-model="
                                                                room['session'][
                                                                    s
                                                                ]
                                                                    .new_availability
                                                            "
                                                            @focus="
                                                                $event.target.select()
                                                            "
                                                            @keydown="
                                                                isNumber($event)
                                                            "
                                                            @change="
                                                                isSessionChanged = true
                                                            "
                                                            maxlength="4"
                                                            class="
                                                                ml-1
                                                                pl-1
                                                                border-b
                                                                border-black
                                                                bg-transparent
                                                                w-12
                                                                focus:outline-none
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td
                                            v-for="(ses, idx) in overnight"
                                            :key="idx"
                                            class="sm:pl-4 py-1 text-center"
                                        >
                                            <div
                                                v-if="!room['overnight']"
                                            ></div>
                                            <div v-else>
                                                <div
                                                    v-for="(
                                                        o, i
                                                    ) in Object.keys(
                                                        room['overnight']
                                                    ).filter(
                                                        (x) => x === ses.type
                                                    )"
                                                    :key="i"
                                                >
                                                    <div
                                                        v-if="
                                                            room.isSelected &&
                                                            ses.isSelected &&
                                                            room['overnight'][o]
                                                                .rights !==
                                                                'readonly'
                                                        "
                                                        class="
                                                            flex flex-wrap
                                                            items-center
                                                            justify-center
                                                        "
                                                    >
                                                        {{
                                                            room["overnight"][o]
                                                                .availability
                                                        }}
                                                        <svg
                                                            xmlns="http://www.w3.org/2000/svg"
                                                            class="
                                                                icon
                                                                icon-tabler
                                                                icon-tabler-chevrons-right
                                                                stroke-current
                                                            "
                                                            :class="{
                                                                'text-black':
                                                                    !room[
                                                                        'overnight'
                                                                    ][o]
                                                                        .new_availability,
                                                                'text-red-500':
                                                                    room[
                                                                        'overnight'
                                                                    ][o]
                                                                        .new_availability
                                                            }"
                                                            width="24"
                                                            height="24"
                                                            viewBox="0 0 24 24"
                                                            stroke-width="1.5"
                                                            stroke="#000000"
                                                            fill="none"
                                                            stroke-linecap="round"
                                                            stroke-linejoin="round"
                                                        >
                                                            <path
                                                                stroke="none"
                                                                d="M0 0h24v24H0z"
                                                                fill="none"
                                                            />
                                                            <polyline
                                                                points="7 7 12 12 7 17"
                                                            />
                                                            <polyline
                                                                points="13 7 18 12 13 17"
                                                            />
                                                        </svg>
                                                        <input
                                                            v-model="
                                                                room[
                                                                    'overnight'
                                                                ][o]
                                                                    .new_availability
                                                            "
                                                            @focus="
                                                                $event.target.select()
                                                            "
                                                            @keydown="
                                                                isNumber($event)
                                                            "
                                                            @change="
                                                                isOvernightChanged = true
                                                            "
                                                            maxlength="4"
                                                            class="
                                                                ml-1
                                                                pl-1
                                                                border-b
                                                                border-black
                                                                bg-transparent
                                                                w-12
                                                                focus:outline-none
                                                            "
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                            <Legend :type="0" />
                            <div
                                v-if="
                                    this.flexible.some(
                                        (e) => e.isSelected === true
                                    )
                                "
                                class="
                                    border border-gray-400
                                    rounded-md
                                    p-4
                                    mx-2
                                    md:mx-0
                                "
                            >
                                <div class="text-sm lg:text-base font-bold">
                                    {{ $t("dashboard.flexible") }}
                                    <span class="text-red-600">{{
                                        $t("dashboard.change_warning")
                                    }}</span>
                                </div>
                                <div
                                    v-if="
                                        operation &&
                                        operation.start_time &&
                                        operation.end_time
                                    "
                                >
                                    (Operation time:
                                    {{ operation.start_time }}-{{
                                        operation.end_time
                                    }})
                                </div>
                                <div
                                    class="
                                        my-2
                                        flex
                                        items-center
                                        justify-between
                                    "
                                >
                                    <div class="flex items-center">
                                        <Switch
                                            :toggle="flexibleMode"
                                            @onPress="
                                                flexibleMode = !flexibleMode
                                            "
                                        />
                                        <span class="ml-2">{{
                                            flexibleMode
                                                ? "Multiple Days"
                                                : "Date Range"
                                        }}</span>
                                    </div>
                                    <div v-if="flexibleMode">
                                        <button
                                            :disabled="
                                                selectedDates.length <= 0
                                            "
                                            @click="selectedDates = []"
                                            class="
                                                bg-red-500
                                                text-white
                                                rounded-md
                                                shadow-md
                                                px-2
                                                py-1
                                            "
                                            :class="{
                                                'filter grayscale':
                                                    selectedDates.length <= 0
                                            }"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div
                                    class="
                                        grid grid-cols-2
                                        gap-4
                                        sm:gap-8
                                        m-2
                                        sm:mx-0 sm:my-2
                                    "
                                >
                                    <div v-if="!flexibleMode">
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.start_date") }}
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                border-b
                                                w-4/5
                                                sm:w-1/2
                                            "
                                        >
                                            {{
                                                startDateHour.replaceAll(
                                                    "/",
                                                    "-"
                                                )
                                            }}
                                            <button
                                                class="ml-3"
                                                @click="startHCalendar = true"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="
                                                        icon
                                                        icon-tabler
                                                        icon-tabler-calendar
                                                    "
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#000000"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="5"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="16"
                                                        y1="3"
                                                        x2="16"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="8"
                                                        y1="3"
                                                        x2="8"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="11"
                                                        x2="20"
                                                        y2="11"
                                                    />
                                                    <line
                                                        x1="11"
                                                        y1="15"
                                                        x2="12"
                                                        y2="15"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="15"
                                                        x2="12"
                                                        y2="18"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <CalendarModal
                                            v-if="startHCalendar"
                                            :sel-date="startDateHour"
                                            @onSelect="selectStartDateH"
                                            @onCancel="startHCalendar = false"
                                        />
                                        <!--                                    <input-->
                                        <!--                                        ref="start_date_hour"-->
                                        <!--                                        v-model="today"-->
                                        <!--                                        type="date"-->
                                        <!--                                        class="-->
                                        <!--                                            w-4/5-->
                                        <!--                                            sm:w-1/2-->
                                        <!--                                            border-b-->
                                        <!--                                            focus:outline-none-->
                                        <!--                                        "-->
                                        <!--                                    />-->
                                    </div>
                                    <div v-if="!flexibleMode">
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.end_date") }}
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                border-b
                                                w-4/5
                                                sm:w-1/2
                                            "
                                        >
                                            {{
                                                endDateHour.replaceAll("/", "-")
                                            }}
                                            <button
                                                class="ml-3"
                                                @click="endHCalendar = true"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="
                                                        icon
                                                        icon-tabler
                                                        icon-tabler-calendar
                                                    "
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#000000"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="5"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="16"
                                                        y1="3"
                                                        x2="16"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="8"
                                                        y1="3"
                                                        x2="8"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="11"
                                                        x2="20"
                                                        y2="11"
                                                    />
                                                    <line
                                                        x1="11"
                                                        y1="15"
                                                        x2="12"
                                                        y2="15"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="15"
                                                        x2="12"
                                                        y2="18"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <CalendarModal
                                            v-if="endHCalendar"
                                            :sel-date="endDateHour"
                                            :min-date="startDateHour"
                                            @onSelect="selectEndDateH"
                                            @onCancel="endHCalendar = false"
                                        />
                                        <!--                                    <input-->
                                        <!--                                        ref="end_date_hour"-->
                                        <!--                                        type="date"-->
                                        <!--                                        class="-->
                                        <!--                                            w-4/5-->
                                        <!--                                            sm:w-1/2-->
                                        <!--                                            border-b-->
                                        <!--                                            focus:outline-none-->
                                        <!--                                        "-->
                                        <!--                                    />-->
                                    </div>
                                    <div
                                        v-if="flexibleMode"
                                        class="col-span-2 py-2"
                                    >
                                        <div class="flex flex-wrap">
                                            <button
                                                @click="
                                                    selectedDates.splice(
                                                        selectedDates.indexOf(
                                                            date
                                                        ),
                                                        1
                                                    )
                                                "
                                                class="
                                                    bg-yellow-400
                                                    rounded-full
                                                    text-white
                                                    mr-2
                                                    px-1
                                                    text-sm
                                                    px-2
                                                    py-1
                                                    transition
                                                    mb-1.5
                                                    duration-200
                                                    ease-linear
                                                "
                                                v-for="(
                                                    date, index
                                                ) in selectedDates"
                                                :key="index"
                                            >
                                                {{ date }}
                                            </button>
                                        </div>
                                        <div class="flex justify-center">
                                            <Calendar
                                                @onLoad="getHolidayDate"
                                                @onChange="getHolidayDate"
                                                :is-toggle="true"
                                                :cal-holiday="calHoliday"
                                                :selected-dates="selectedDates"
                                                @onSelect="selectMultiDays"
                                                class="
                                                    w-full
                                                    bg-gray-200
                                                    rounded-md
                                                "
                                            />
                                        </div>
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.start_time") }}
                                        </div>
                                        <TimePicker
                                            :time="currentTime"
                                            @onChange="checkPassMidnight"
                                        />
                                        <input
                                            ref="start_time"
                                            class="
                                                w-4/5
                                                sm:w-1/2
                                                border-b
                                                hidden
                                                focus:outline-none
                                            "
                                            type="time"
                                            max="24:00"
                                            min="00:00"
                                            step="900"
                                            maxlength="5"
                                        />
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.end_time") }}
                                        </div>
                                        <TimePicker
                                            :time="currentTime"
                                            @onChange="checkPassMidnightEnd"
                                        />
                                        <input
                                            ref="end_time"
                                            class="
                                                w-4/5
                                                sm:w-1/2
                                                border-b
                                                hidden
                                                focus:outline-none
                                            "
                                            type="time"
                                            max="24:00"
                                            min="00:00"
                                            step="900"
                                            maxlength="5"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div
                                v-if="
                                    this.sessions.some(
                                        (e) => e.isSelected === true
                                    ) ||
                                    this.overnight.some(
                                        (e) => e.isSelected === true
                                    )
                                "
                                class="
                                    text-sm
                                    sm:text-base
                                    border border-gray-400
                                    rounded-md
                                    p-4
                                    mx-2
                                    md:mx-0
                                "
                                :class="{
                                    'mt-4': this.flexible.some(
                                        (e) => e.isSelected === true
                                    )
                                }"
                            >
                                <span
                                    v-if="
                                        this.sessions.some(
                                            (e) => e.isSelected === true
                                        ) &&
                                        this.overnight.some(
                                            (e) => e.isSelected === true
                                        )
                                    "
                                    class="font-bold"
                                    >{{ $t("dashboard.session") }} /
                                    {{ $t("dashboard.overnight") }}</span
                                >
                                <span
                                    v-else-if="
                                        this.sessions.some(
                                            (e) => e.isSelected === true
                                        )
                                    "
                                    class="font-bold"
                                    >{{ $t("dashboard.session") }}</span
                                >
                                <span v-else class="font-bold">{{
                                    $t("dashboard.overnight")
                                }}</span>
                                <div
                                    class="
                                        my-2
                                        flex
                                        items-center
                                        justify-between
                                    "
                                >
                                    <div class="flex items-center">
                                        <Switch
                                            :toggle="sessionMode"
                                            @onPress="
                                                sessionMode = !sessionMode
                                            "
                                        />
                                        <span class="ml-2">{{
                                            sessionMode
                                                ? "Multiple Days"
                                                : "Date Range"
                                        }}</span>
                                    </div>
                                    <div v-if="sessionMode">
                                        <button
                                            :disabled="
                                                selectedDatesSes.length <= 0
                                            "
                                            @click="selectedDatesSes = []"
                                            class="
                                                bg-red-500
                                                text-white
                                                rounded-md
                                                shadow-md
                                                px-2
                                                py-1
                                            "
                                            :class="{
                                                'filter grayscale':
                                                    selectedDatesSes.length <= 0
                                            }"
                                        >
                                            Clear
                                        </button>
                                    </div>
                                </div>
                                <div
                                    v-if="!sessionMode"
                                    class="
                                        grid grid-cols-2
                                        gap-4
                                        sm:gap-8
                                        m-2
                                        sm:mx-0 sm:my-2
                                    "
                                >
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.start_date") }}
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                border-b
                                                w-4/5
                                                sm:w-1/2
                                            "
                                        >
                                            {{ startDate.replaceAll("/", "-") }}
                                            <button
                                                class="ml-3"
                                                @click="startCalendar = true"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="
                                                        icon
                                                        icon-tabler
                                                        icon-tabler-calendar
                                                    "
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#000000"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="5"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="16"
                                                        y1="3"
                                                        x2="16"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="8"
                                                        y1="3"
                                                        x2="8"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="11"
                                                        x2="20"
                                                        y2="11"
                                                    />
                                                    <line
                                                        x1="11"
                                                        y1="15"
                                                        x2="12"
                                                        y2="15"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="15"
                                                        x2="12"
                                                        y2="18"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <CalendarModal
                                            v-if="startCalendar"
                                            :sel-date="startDate"
                                            @onSelect="selectStartDate"
                                            @onCancel="startCalendar = false"
                                        />
                                        <!--                                    <input-->
                                        <!--                                        ref="start_date"-->
                                        <!--                                        v-model="today"-->
                                        <!--                                        type="date"-->
                                        <!--                                        class="-->
                                        <!--                                            w-4/5-->
                                        <!--                                            sm:w-1/2-->
                                        <!--                                            border-b-->
                                        <!--                                            focus:outline-none-->
                                        <!--                                        "-->
                                        <!--                                    />-->
                                    </div>
                                    <div>
                                        <div class="text-sm text-gray-500">
                                            {{ $t("dashboard.end_date") }}
                                        </div>
                                        <div
                                            class="
                                                flex
                                                items-center
                                                border-b
                                                w-4/5
                                                sm:w-1/2
                                            "
                                        >
                                            {{ endDate.replaceAll("/", "-") }}
                                            <button
                                                class="ml-3"
                                                @click="endCalendar = true"
                                            >
                                                <svg
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    class="
                                                        icon
                                                        icon-tabler
                                                        icon-tabler-calendar
                                                    "
                                                    width="28"
                                                    height="28"
                                                    viewBox="0 0 24 24"
                                                    stroke-width="1.5"
                                                    stroke="#000000"
                                                    fill="none"
                                                    stroke-linecap="round"
                                                    stroke-linejoin="round"
                                                >
                                                    <path
                                                        stroke="none"
                                                        d="M0 0h24v24H0z"
                                                        fill="none"
                                                    />
                                                    <rect
                                                        x="4"
                                                        y="5"
                                                        width="16"
                                                        height="16"
                                                        rx="2"
                                                    />
                                                    <line
                                                        x1="16"
                                                        y1="3"
                                                        x2="16"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="8"
                                                        y1="3"
                                                        x2="8"
                                                        y2="7"
                                                    />
                                                    <line
                                                        x1="4"
                                                        y1="11"
                                                        x2="20"
                                                        y2="11"
                                                    />
                                                    <line
                                                        x1="11"
                                                        y1="15"
                                                        x2="12"
                                                        y2="15"
                                                    />
                                                    <line
                                                        x1="12"
                                                        y1="15"
                                                        x2="12"
                                                        y2="18"
                                                    />
                                                </svg>
                                            </button>
                                        </div>
                                        <CalendarModal
                                            v-if="endCalendar"
                                            :sel-date="endDate"
                                            :min-date="startDate"
                                            @onSelect="selectEndDate"
                                            @onCancel="endCalendar = false"
                                        />
                                        <!--                                    <input-->
                                        <!--                                        ref="end_date"-->
                                        <!--                                        type="date"-->
                                        <!--                                        class="-->
                                        <!--                                            w-4/5-->
                                        <!--                                            sm:w-1/2-->
                                        <!--                                            border-b-->
                                        <!--                                            focus:outline-none-->
                                        <!--                                        "-->
                                        <!--                                    />-->
                                    </div>
                                </div>
                                <div
                                    v-else
                                    class="col-span-2 py-2 px-2 md:px-0"
                                >
                                    <div class="flex flex-wrap">
                                        <button
                                            @click="
                                                selectedDatesSes.splice(
                                                    selectedDatesSes.indexOf(
                                                        date
                                                    ),
                                                    1
                                                )
                                            "
                                            class="
                                                bg-yellow-400
                                                rounded-full
                                                text-white
                                                mr-2
                                                px-1
                                                text-sm
                                                px-2
                                                py-1
                                                transition
                                                mb-1.5
                                                duration-200
                                                ease-linear
                                            "
                                            v-for="(
                                                date, index
                                            ) in selectedDatesSes"
                                            :key="index"
                                        >
                                            {{ date }}
                                        </button>
                                    </div>
                                    <div class="flex justify-center">
                                        <Calendar
                                            @onLoad="getHolidayDate"
                                            @onChange="getHolidayDate"
                                            :is-toggle="true"
                                            :cal-holiday="calHoliday"
                                            :selected-dates="selectedDatesSes"
                                            @onSelect="selectMultiDaysSes"
                                            class="
                                                w-full
                                                bg-gray-200
                                                rounded-md
                                            "
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="mt-4 flex flex-1 justify-end">
                            <button
                                @click="updateAvailability"
                                :disabled="updateBtn"
                                class="px-5 py-2 cfn-btn"
                                :class="{ 'filter grayscale': updateBtn }"
                            >
                                {{ $t("dashboard.save") }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="flex justify-center w-full" v-else>
                <Loader class="py-10" type="lg" />
            </div>
        </div>
        <AuditLog
            v-if="isReady"
            has-container
            :type="'availability'"
            :user="user"
            :selected-space="selectedSpace"
            :date="this.selectedDate.replaceAll('/', '-')"
        />
    </div>
</template>

<script>
import Calendar from "@/components/Calendar"
import Loader from "@/components/Loader"
import { GoogleCharts } from "google-charts"
import CalendarModal from "@/components/CalendarModal"
import Switch from "@/components/Switch"
import TimePicker from "@/components/TimePicker"
import AvailabilityTable from "@/components/AvailabilityTable"
import Legend from "@/components/Legend"
import FreeCancel from "@/components/Svg/FreeCancel"
import NoCancel from "@/components/Svg/NoCancel"
import PriceComparison from "@/components/PriceComparison"
import AuditLog from "@/components/AuditLog"

export default {
    name: "Dashboard",
    components: {
        AuditLog,
        PriceComparison,
        NoCancel,
        FreeCancel,
        Legend,
        AvailabilityTable,
        TimePicker,
        Switch,
        CalendarModal,
        Loader,
        Calendar
    },
    props: {
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number }
    },
    data() {
        return {
            lang: this.$i18n.locale,
            isLoading: true,
            openIndex: [],
            today: "",
            currentTime: "",
            flexEndTime: 0,
            updateList: [],
            selectedDates: [],
            selectedDatesSes: [],
            resizeTimer: null,
            tempMonth: {},
            showTab: false,
            selectAll: false,
            changeMonthBtn: true,
            changeDateBtn: true,
            isReady: false,
            isHourChanged: false,
            isSessionChanged: false,
            isOvernightChanged: false,
            selectedTab: 0,
            flexibleMode: false,
            sessionMode: false,
            selectedDate: "",
            displayDate: "",
            operation: null,
            flexible: [],
            sessions: [],
            overnight: [],
            showCalendar: false,
            startHCalendar: false,
            endHCalendar: false,
            startDateHour: "",
            endDateHour: "",
            startCalendar: false,
            endCalendar: false,
            startDate: "",
            endDate: "",
            roomType: [],
            month_names: [
                this.$t("calendar.january"),
                this.$t("calendar.february"),
                this.$t("calendar.march"),
                this.$t("calendar.april"),
                this.$t("calendar.may"),
                this.$t("calendar.june"),
                this.$t("calendar.july"),
                this.$t("calendar.august"),
                this.$t("calendar.september"),
                this.$t("calendar.october"),
                this.$t("calendar.november"),
                this.$t("calendar.december")
            ],
            days: [
                this.$t("calendar.sun"),
                this.$t("calendar.mon"),
                this.$t("calendar.tue"),
                this.$t("calendar.wed"),
                this.$t("calendar.thu"),
                this.$t("calendar.fri"),
                this.$t("calendar.sat")
            ],
            calHoliday: [],
            updateBtn: false,
            priceData: {},
            prevReq: null
        }
    },
    mounted() {
        this.formatDate()
        this.isReady = true
        this.getAvailability()
        this.getPriceData()
        window.onbeforeunload = () => {
            if (
                this.isHourChanged ||
                this.isSessionChanged ||
                this.isOvernightChanged
            ) {
                return true
            } else return null
        }
    },
    methods: {
        getType() {
            let fCount = 0
            let oCount = 0
            let sCount = 0
            for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                if (this.roomType[Object.keys(this.roomType)[i]].hour) {
                    fCount++
                    this.sortHour(i)
                }
                if (this.roomType[Object.keys(this.roomType)[i]].session) {
                    sCount++
                    this.sortSession(i)
                }
                if (this.roomType[Object.keys(this.roomType)[i]].overnight) {
                    oCount++
                    this.sortOvernight(i)
                }
            }
            if (fCount > 0 && (sCount > 0 || oCount > 0)) {
                this.showTab = true
            } else if (fCount > 0) {
                this.showTab = false
                this.selectedTab = 0
            } else {
                this.showTab = false
                this.selectedTab = 1
            }
            this.flexible.sort((a, b) => a.type.localeCompare(b.type))
            this.sessions.sort((a, b) => {
                const a1 = a.type
                const b1 = b.type
                if (a1 < b1) return -1
                if (a1 > b1) return 1
            })
            this.overnight.sort((a, b) => a.type.localeCompare(b.type))
        },
        sortHour(i) {
            for (let a in Object.keys(
                this.roomType[Object.keys(this.roomType)[i]].hour
            )) {
                if (
                    !this.flexible.some(
                        (e) =>
                            e.type ===
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .hour
                            )[a]
                    )
                ) {
                    this.flexible.push({
                        type: Object.keys(
                            this.roomType[Object.keys(this.roomType)[i]].hour
                        )[a],
                        refund: this.roomType[Object.keys(this.roomType)[i]]
                            .hour[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .hour
                            )[a]
                        ][0].refund_min,
                        dist_logo: this.roomType[Object.keys(this.roomType)[i]]
                            .hour[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .hour
                            )[a]
                        ][0].dist_logo
                            ? this.roomType[Object.keys(this.roomType)[i]].hour[
                                  Object.keys(
                                      this.roomType[
                                          Object.keys(this.roomType)[i]
                                      ].hour
                                  )[a]
                              ][0].dist_logo
                            : null
                    })
                }
            }
        },
        sortSession(i) {
            for (let a in Object.keys(
                this.roomType[Object.keys(this.roomType)[i]].session
            )) {
                let val = Object.keys(
                    this.roomType[Object.keys(this.roomType)[i]].session
                )[a]
                if (!this.sessions.some((e) => e.type === val)) {
                    this.sessions.push({
                        type: val,
                        refund: this.roomType[Object.keys(this.roomType)[i]]
                            .session[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .session
                            )[a]
                        ].refund_min,
                        dist_logo: this.roomType[Object.keys(this.roomType)[i]]
                            .session[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .session
                            )[a]
                        ].dist_logo
                            ? this.roomType[Object.keys(this.roomType)[i]]
                                  .session[
                                  Object.keys(
                                      this.roomType[
                                          Object.keys(this.roomType)[i]
                                      ].session
                                  )[a]
                              ].dist_logo
                            : null
                    })
                }
            }
        },
        sortOvernight(i) {
            for (let a in Object.keys(
                this.roomType[Object.keys(this.roomType)[i]].overnight
            )) {
                let val = Object.keys(
                    this.roomType[Object.keys(this.roomType)[i]].overnight
                )[a]
                if (!this.overnight.some((e) => e.type === val)) {
                    this.overnight.push({
                        type: val,
                        refund: this.roomType[Object.keys(this.roomType)[i]]
                            .overnight[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .overnight
                            )[a]
                        ].refund_min,
                        dist_logo: this.roomType[Object.keys(this.roomType)[i]]
                            .overnight[
                            Object.keys(
                                this.roomType[Object.keys(this.roomType)[i]]
                                    .overnight
                            )[a]
                        ].dist_logo
                            ? this.roomType[Object.keys(this.roomType)[i]]
                                  .overnight[
                                  Object.keys(
                                      this.roomType[
                                          Object.keys(this.roomType)[i]
                                      ].overnight
                                  )[a]
                              ].dist_logo
                            : null
                    })
                }
            }
        },
        formatDate() {
            let d = new Date()
            let year = d.getFullYear()
            let month = d.getMonth()
            let date = d.getDate()
            let day = d.getDay()
            let hour = d.getHours()
            let min = d.getMinutes()
            this.displayDate =
                this.$i18n.locale === "en"
                    ? `${this.month_names[month]} ${
                          date >= 10 ? date : "0" + date
                      }, ${year} (${this.days[day]})`
                    : `${year}年${this.month_names[month]}${
                          date >= 10 ? date + "日" : "0" + date + "日"
                      } (${this.days[day]})`
            month += 1
            this.selectedDate = `${year}/${month >= 10 ? month : "0" + month}/${
                date >= 10 ? date : "0" + date
            }`
            this.today = `${year}-${month >= 10 ? month : "0" + month}-${
                date >= 10 ? date : "0" + date
            }`
            this.startDateHour =
                this.endDateHour =
                this.startDate =
                this.endDate =
                    this.today
            this.currentTime = `${hour}:${min >= 10 ? min : "0" + min}`
        },
        openAccordion(idx) {
            if (!this.openIndex.includes(idx)) {
                this.openIndex.push(idx)
            } else this.openIndex.splice(this.openIndex.indexOf(idx), 1)
            this.drawChart(idx)
        },
        drawAllChart() {
            for (let val = 0; val < this.openIndex.length; val++) {
                this.drawChart(this.openIndex[val])
            }
        },
        drawChart(val) {
            let date = this.getDate()
            let title_1 = this.$t("dashboard.booked_room")
            let title_2 = this.$t("dashboard.total_room")
            let state = this.openIndex.includes(val)
            let sorted_hourly = this.sortObj(
                this.roomType[Object.keys(this.roomType)[val]].hour
            )
            for (let z = 0; z < Object.keys(sorted_hourly).length; z++) {
                let room = sorted_hourly[Object.keys(sorted_hourly)[z]]
                if (room.length > 0) {
                    GoogleCharts.load(
                        () => {
                            {
                                // Standard google charts functionality is available as GoogleCharts.api after load
                                const data =
                                    new GoogleCharts.api.visualization.DataTable()
                                data.addColumn("date", "Date")
                                data.addColumn("number", title_1)
                                data.addColumn("number", title_2)
                                let max = room[0].availability
                                let start = new Date(`${date}T00:00:00`)
                                let end = new Date(`${date}T23:59:59`)
                                let slices = []

                                while (end >= start) {
                                    start = new Date(
                                        start.getTime() + 15 * 60 * 1000
                                    )
                                    slices.push([start, 0, 0])
                                }
                                for (let x = 0; x < room.length; x++) {
                                    if (room[x].availability > max) {
                                        max = room[x].availability
                                    }
                                    if (
                                        !room[x].start_date &&
                                        !room[x].end_date
                                    ) {
                                        slices.forEach(
                                            (e) => (e[2] = room[x].availability)
                                        )
                                    }
                                }
                                for (let e = 0; e < slices.length; e++) {
                                    slices[e][1] =
                                        room[0].reservation_count[0].count
                                    for (let x = 0; x < room.length; x++) {
                                        if (
                                            room[x].start_date &&
                                            room[x].end_date &&
                                            new Date(room[x].start_date) <=
                                                new Date(date) &&
                                            new Date(room[x].end_date) >=
                                                new Date(date) &&
                                            new Date(
                                                `${room[x].start_date}T${room[x].start_time}`
                                            ) <= slices[e][0] &&
                                            ((new Date(
                                                `${room[x].end_date}T${room[x].end_time}`
                                            ) >= slices[e][0] &&
                                                !room.some(
                                                    (e) =>
                                                        e.start_date ===
                                                            room[x].end_date &&
                                                        e.start_time ===
                                                            room[x].end_time
                                                )) ||
                                                new Date(
                                                    `${room[x].end_date}T${room[x].end_time}`
                                                ) > slices[e][0])
                                        ) {
                                            if (
                                                room[x].range_start &&
                                                room[x].range_end &&
                                                room[x].range_start <
                                                    room[x].range_end &&
                                                (new Date(
                                                    `${date}T${room[x].range_start}`
                                                ) > slices[e][0] ||
                                                    new Date(
                                                        `${date}T${room[x].range_end}`
                                                    ) < slices[e][0])
                                            ) {
                                                slices[e][2] = 0
                                            } else if (
                                                room[x].range_start &&
                                                room[x].range_end &&
                                                room[x].range_start >
                                                    room[x].range_end &&
                                                slices[e][0] <
                                                    new Date(
                                                        `${date}T${room[x].range_start}`
                                                    ) &&
                                                slices[e][0] >
                                                    new Date(
                                                        `${date}T${room[x].range_end}`
                                                    )
                                            ) {
                                                slices[e][2] = 0
                                            } else {
                                                slices[e][2] =
                                                    room[x].availability
                                            }
                                        } else if (
                                            room[x].range_start &&
                                            room[x].range_end
                                        ) {
                                            if (
                                                room[x].range_start <
                                                    room[x].range_end &&
                                                (new Date(
                                                    `${date}T${room[x].range_start}`
                                                ) > slices[e][0] ||
                                                    new Date(
                                                        `${date}T${room[x].range_end}`
                                                    ) < slices[e][0])
                                            ) {
                                                slices[e][2] = 0
                                            } else if (
                                                room[x].range_start >
                                                    room[x].range_end &&
                                                slices[e][0] <
                                                    new Date(
                                                        `${date}T${room[x].range_start}`
                                                    ) &&
                                                slices[e][0] >
                                                    new Date(
                                                        `${date}T${room[x].range_end}`
                                                    )
                                            ) {
                                                slices[e][2] = 0
                                            }
                                        } else if (
                                            room[x].start_time &&
                                            room[x].end_time &&
                                            !room[x].start_date &&
                                            !room[x].end_date &&
                                            new Date(
                                                `${date}T${room[x].start_time}`
                                            ) < slices[e][0] &&
                                            new Date(
                                                `${date}T${room[x].end_time}`
                                            ) > slices[e][0]
                                        ) {
                                            slices[e][2] = room[x].availability
                                        }
                                    }
                                    for (
                                        let x = 0;
                                        x < room[0].reservation_count.length;
                                        x++
                                    ) {
                                        if (
                                            room[0].reservation_count[x].time &&
                                            slices[e][0] >=
                                                new Date(
                                                    room[0].reservation_count[
                                                        x
                                                    ].time
                                                )
                                        ) {
                                            slices[e][1] =
                                                room[0].reservation_count[
                                                    x
                                                ].count
                                        }
                                    }
                                }
                                data.addRows(slices)
                                const chart =
                                    new GoogleCharts.api.visualization.AnnotationChart(
                                        document.getElementById(
                                            `chart_${val}_${z}`
                                        )
                                    )
                                let options = {
                                    displayAnnotations: false,
                                    colors: ["red", "blue"],
                                    displayZoomButtons: false,
                                    dateFormat: "YYYY-MM-dd HH:mm",
                                    scaleFormat: "#",
                                    max: max * 2
                                }
                                chart.draw(data, options)
                                let timer
                                if (state) {
                                    window.addEventListener("resize", () => {
                                        clearTimeout(timer)
                                        timer = setTimeout(
                                            chart.draw(data, options),
                                            200
                                        )
                                    })
                                } else {
                                    window.removeEventListener("resize", () => {
                                        clearTimeout(timer)
                                        timer = setTimeout(
                                            chart.draw(data, options),
                                            200
                                        )
                                    })
                                }
                            }
                        },
                        { packages: ["annotationchart"] }
                    )
                }
            }
        },
        async getAvailability() {
            this.isLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/home/getVenueTypeList/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&lang=${this.$i18n.locale}&date=${this.selectedDate.replaceAll(
                "/",
                "-"
            )}`
            this.roomType = []
            this.flexible = []
            this.sessions = []
            this.overnight = []
            this.isHourChanged = false
            this.isSessionChanged = false
            this.isOvernightChanged = false
            this.$axios.get(url).then((res) => {
                this.roomType = Object.keys(res.data.data)
                    .sort()
                    .reduce((obj, key) => {
                        obj[key] = res.data.data[key]
                        return obj
                    }, {})
                if (res.data.data.operation) {
                    this.operation = res.data.data.operation
                    delete this.roomType.operation
                }
                this.getType()
                this.roomType = Object.fromEntries(
                    Object.entries(this.roomType).sort((x, y) =>
                        x[1].name.localeCompare(y[1].name)
                    )
                )
                if (res.data.Success) {
                    this.isLoading = false
                    this.drawAllChart()
                }
            })
        },
        getDate(type) {
            let yourDate = new Date(this.selectedDate)
            if (type) {
                yourDate = new Date(yourDate)
                yourDate.setDate(yourDate.getDate() + type)
            }
            const offset = yourDate.getTimezoneOffset()
            yourDate = new Date(yourDate.getTime() - offset * 60 * 1000)
            return yourDate.toISOString().split("T")[0]
        },
        selectTab() {
            this.selectedTab = 1
            let roomType = this.roomType
            this.openIndex = []
        },
        selectAllSpace() {
            this.selectAll = !this.selectAll
            for (let i = 0; i < this.flexible.length; i++) {
                this.flexible[i].isSelected = this.selectAll
            }
            for (let i = 0; i < this.sessions.length; i++) {
                this.sessions[i].isSelected = this.selectAll
            }
            for (let i = 0; i < this.overnight.length; i++) {
                this.overnight[i].isSelected = this.selectAll
            }
            for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                this.roomType[Object.keys(this.roomType)[i]].isSelected =
                    this.selectAll
                this.checkAvailability(
                    0,
                    this.roomType[Object.keys(this.roomType)[i]]
                )
            }
            if (!this.selectAll) {
                this.isHourChanged = false
                this.isSessionChanged = false
                this.isOvernightChanged = false
            }
        },
        setAllZero() {
            for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                this.checkAvailability(
                    2,
                    this.roomType[Object.keys(this.roomType)[i]]
                )
            }
        },
        selectDate(val) {
            let sDate = new Date(`${val.year}/${val.month}/${val.date}`)
            let d = new Date(sDate.getTime())
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            if (
                parseInt(val.year) === year &&
                val.month === month &&
                val.date === date
            ) {
                this.selectedDate = `${val.year}/${
                    val.month >= 10 ? val.month : "0" + val.month
                }/${val.date >= 10 ? val.date : "0" + val.date}`
                let day = new Date(
                    `${val.year}/${val.month}/${val.date}`
                ).getDay()
                this.displayDate =
                    this.$i18n.locale === "en"
                        ? `${this.month_names[val.month - 1]} ${
                              val.date >= 10 ? val.date : "0" + val.date
                          }, ${val.year} (${this.days[day]})`
                        : `${val.year}年${this.month_names[val.month - 1]}${
                              val.date >= 10
                                  ? val.date + "日"
                                  : "0" + val.date + "日"
                          } (${this.days[day]})`
                // this.displayDate = `${this.month_names[val.month - 1]} ${
                //     val.date >= 10 ? val.date : "0" + val.date
                // }, ${val.year} (${this.days[day]})`
                this.startDateHour =
                    this.endDateHour =
                    this.startDate =
                    this.endDate =
                        this.selectedDate.replaceAll("/", "-")
                this.getAvailability()
                this.getPriceData()
            } else {
                let nDate = new Date(`${val.year}/${val.month + 1}/0`).getDate()
                this.selectedDate = `${val.year}/${
                    val.month >= 10 ? val.month : "0" + val.month
                }/${nDate >= 10 ? nDate : "0" + nDate}`
                let day = new Date(`${val.year}/${val.month}/${nDate}`).getDay()
                this.displayDate =
                    this.$i18n.locale === "en"
                        ? `${this.month_names[val.month - 1]} ${
                              val.date >= 10 ? val.date : "0" + val.date
                          }, ${val.year} (${this.days[day]})`
                        : `${val.year}年${this.month_names[val.month - 1]}${
                              val.date >= 10
                                  ? val.date + "日"
                                  : "0" + val.date + "日"
                          } (${this.days[day]})`
                // this.displayDate = `${this.month_names[val.month - 1]} ${
                //     nDate >= 10 ? nDate : "0" + nDate
                // }, ${val.year} (${this.days[day]})`
                this.getAvailability()
                this.getPriceData()
            }
        },
        changeMonth(type) {
            if (this.changeMonthBtn) {
                this.changeMonthBtn = false
                let year = parseInt(this.selectedDate.split("/")[0])
                let month = parseInt(this.selectedDate.split("/")[1])
                let date = parseInt(this.selectedDate.split("/")[2])
                if (type === "prev") {
                    const d = new Date(`${year}/${month === 12 ? 1 : month}/1`)
                    d.setDate(0)
                    let maxD = d.getDate()
                    if (date > maxD) {
                        date = maxD
                    }
                    if (month === 1) {
                        month = 12
                        year--
                    } else {
                        month--
                    }
                }
                if (type === "next") {
                    if (month === 12) {
                        month = 1
                        year++
                    } else {
                        month++
                    }
                    const d = new Date(
                        `${year}/${month === 12 ? 1 : month + 1}/1`
                    )
                    d.setDate(0)
                    let maxD = d.getDate()
                    if (date > maxD) {
                        date = maxD
                    }
                }
                let newD = {
                    date: date,
                    month: month,
                    year: year
                }
                this.selectDate(newD)
                setTimeout(() => {
                    this.changeMonthBtn = true
                }, 100)
            }
        },
        changeDate(type) {
            if (this.changeDateBtn) {
                this.changeDateBtn = false
                let year = parseInt(this.selectedDate.split("/")[0])
                let month = parseInt(this.selectedDate.split("/")[1])
                let date = parseInt(this.selectedDate.split("/")[2])
                if (type === "prev") {
                    if (date === 1) {
                        //set the date to the last day of the month
                        const d = new Date(
                            `${year}/${month === 12 ? 1 : month}/1`
                        )
                        d.setDate(0)
                        date = d.getDate()
                        if (month === 1) {
                            month = 12
                            year--
                        } else {
                            month--
                        }
                    } else {
                        date--
                    }
                }
                if (type === "next") {
                    const d = new Date(
                        `${year}/${month === 12 ? 1 : month + 1}/1`
                    )
                    d.setDate(0)
                    let maxD = d.getDate()
                    if (date === maxD) {
                        //set the date to the first day of the month
                        if (month === 12) {
                            month = 1
                            year++
                        } else {
                            month++
                        }
                        date = 1
                    } else {
                        date++
                    }
                }
                let d = {
                    date: date,
                    month: month,
                    year: year
                }
                this.selectDate(d)
                setTimeout(() => {
                    this.changeDateBtn = true
                }, 100)
            }
        },
        isNumber(e) {
            let char = e.key
            if (
                /^[0-9]+$/.test(char) ||
                e.keyCode === 8 ||
                e.keyCode === 9 ||
                e.keyCode === 46
            )
                return true
            else e.preventDefault()
        },
        checkAvailability(type, obj) {
            if (type === 1) {
                obj.isSelected = !obj.isSelected
            }
            for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                if (this.roomType[Object.keys(this.roomType)[i]].hour) {
                    let room = this.roomType[Object.keys(this.roomType)[i]].hour
                    for (let j = 0; j < Object.keys(room).length; j++) {
                        if (
                            this.roomType[Object.keys(this.roomType)[i]]
                                .isSelected &&
                            this.flexible[
                                this.flexible.findIndex(
                                    (e) => e.type === Object.keys(room)[j]
                                )
                            ].isSelected &&
                            room[Object.keys(room)[j]][0].rights !== "readonly"
                        ) {
                            if (
                                !(
                                    room[Object.keys(room)[j]][0]
                                        .new_availability >= 0
                                ) ||
                                type === 2
                            ) {
                                if (
                                    !this.updateList.includes(
                                        room[Object.keys(room)[j]][0]
                                    )
                                ) {
                                    this.updateList.push(
                                        room[Object.keys(room)[j]][0]
                                    )
                                }
                                room[
                                    Object.keys(room)[j]
                                ][0].new_availability = 0
                            }
                        } else if (
                            this.updateList.includes(
                                room[Object.keys(room)[j]][0]
                            )
                        ) {
                            let i = this.updateList.indexOf(
                                room[Object.keys(room)[j]][0]
                            )
                            delete this.updateList[i].new_availability
                            this.updateList.splice(i, 1)
                        }
                    }
                }
                if (this.roomType[Object.keys(this.roomType)[i]].session) {
                    let room =
                        this.roomType[Object.keys(this.roomType)[i]].session
                    for (let j = 0; j < Object.keys(room).length; j++) {
                        if (
                            this.roomType[Object.keys(this.roomType)[i]]
                                .isSelected &&
                            this.sessions[
                                this.sessions.findIndex(
                                    (e) => e.type === Object.keys(room)[j]
                                )
                            ].isSelected &&
                            room[Object.keys(room)[j]].rights !== "readonly"
                        ) {
                            if (
                                !(
                                    room[Object.keys(room)[j]]
                                        .new_availability >= 0
                                ) ||
                                type === 2
                            ) {
                                if (
                                    !this.updateList.includes(
                                        room[Object.keys(room)[j]]
                                    )
                                ) {
                                    this.updateList.push(
                                        room[Object.keys(room)[j]]
                                    )
                                }
                                room[Object.keys(room)[j]].new_availability = 0
                            }
                        } else if (
                            this.updateList.includes(room[Object.keys(room)[j]])
                        ) {
                            let i = this.updateList.indexOf(
                                room[Object.keys(room)[j]]
                            )
                            delete this.updateList[i].new_availability
                            this.updateList.splice(i, 1)
                        }
                    }
                }
                if (this.roomType[Object.keys(this.roomType)[i]].overnight) {
                    let room =
                        this.roomType[Object.keys(this.roomType)[i]].overnight
                    for (let j = 0; j < Object.keys(room).length; j++) {
                        if (
                            this.roomType[Object.keys(this.roomType)[i]]
                                .isSelected &&
                            this.overnight[
                                this.overnight.findIndex(
                                    (e) => e.type === Object.keys(room)[j]
                                )
                            ].isSelected &&
                            room[Object.keys(room)[j]].rights !== "readonly"
                        ) {
                            if (
                                !(
                                    room[Object.keys(room)[j]]
                                        .new_availability >= 0
                                ) ||
                                type === 2
                            ) {
                                if (
                                    !this.updateList.includes(
                                        room[Object.keys(room)[j]]
                                    )
                                ) {
                                    this.updateList.push(
                                        room[Object.keys(room)[j]]
                                    )
                                }
                                room[Object.keys(room)[j]].new_availability = 0
                            }
                        } else if (
                            this.updateList.includes(room[Object.keys(room)[j]])
                        ) {
                            let i = this.updateList.indexOf(
                                room[Object.keys(room)[j]]
                            )
                            delete this.updateList[i].new_availability
                            this.updateList.splice(i, 1)
                        }
                    }
                }
            }
        },
        validateChanges() {
            if (
                this.flexible.some((e) => e.isSelected === true) ||
                this.sessions.some((e) => e.isSelected === true) ||
                this.overnight.some((e) => e.isSelected === true)
            ) {
                if (
                    this.flexible.some((e) => e.isSelected === true) &&
                    (!this.startDateHour ||
                        !this.endDateHour ||
                        !this.$refs.start_time.value ||
                        !this.$refs.end_time.value)
                ) {
                    alert("Please select at least one day.")
                    return false
                }
                if (
                    this.flexible.some((e) => e.isSelected === true) &&
                    !this.flexibleMode
                ) {
                    if (
                        new Date(
                            `${this.endDateHour.replaceAll("/", "-")}T${
                                this.$refs.end_time.value
                            }`
                        ) <=
                        new Date(
                            `${this.startDateHour.replaceAll("/", "-")}T${
                                this.$refs.start_time.value
                            }`
                        )
                    ) {
                        alert("End date must be after start date.")
                        return false
                    }
                }
                if (
                    this.sessions.some((e) => e.isSelected === true) ||
                    this.overnight.some((e) => e.isSelected === true)
                ) {
                    if (
                        !this.sessionMode &&
                        (!this.startDate || !this.endDate)
                    ) {
                        alert("Please select at least one day.")
                        return false
                    } else if (
                        !this.sessionMode &&
                        this.startDate.replaceAll("/", "-") >
                            this.endDate.replaceAll("/", "-")
                    ) {
                        alert("End date must be after start date.")
                        return false
                    }
                }
                return true
            } else {
                alert("Please select at least one book type.")
                return false
            }
        },
        updateAvailability() {
            let list = []
            this.updateBtn = true
            if (this.validateChanges()) {
                if (
                    this.flexibleMode &&
                    this.$refs.start_time.value > this.$refs.end_time.value
                ) {
                    this.updateBtn = false
                    return alert("End time must be after start time")
                }
                if (
                    this.flexibleMode &&
                    !this.validateSelection(this.selectedDates)
                ) {
                    this.updateBtn = false
                    return alert("Please select at least one day.")
                }
                if (
                    this.sessionMode &&
                    !this.validateSelection(this.selectedDatesSes)
                ) {
                    this.updateBtn = false
                    return alert("Please select at least one day.")
                }
                for (let i = 0; i < Object.keys(this.roomType).length; i++) {
                    if (this.roomType[Object.keys(this.roomType)[i]].hour) {
                        if (this.availabilityHCheck(i)) {
                            list = list.concat(this.availabilityHCheck(i))
                        }
                    }
                    if (this.roomType[Object.keys(this.roomType)[i]].session) {
                        if (this.availabilitySCheck(i)) {
                            list = list.concat(this.availabilitySCheck(i))
                        }
                    }
                    if (
                        this.roomType[Object.keys(this.roomType)[i]].overnight
                    ) {
                        if (this.availabilityOCheck(i)) {
                            list = list.concat(this.availabilityOCheck(i))
                        }
                    }
                }
                const data = {
                    modify_list: list
                }
                if (data.modify_list.length > 0) {
                    this.updateAvailabilityReq(data)
                } else {
                    this.updateBtn = false
                    return alert("Please change something first.")
                }
            } else this.updateBtn = false
        },
        validateSelection(data) {
            return data.length !== 0
        },
        availabilityHCheck(i) {
            let l = []
            let room = this.roomType[Object.keys(this.roomType)[i]].hour
            for (let i = 0; i < Object.keys(room).length; i++) {
                if (room[Object.keys(room)[i]][0].new_availability >= 0) {
                    const r = room[Object.keys(room)[i]][0]
                    if (this.flexibleMode) {
                        l.push({
                            mode: 0,
                            space_id: r.space_id,
                            space_type: r.space_type,
                            available_book_id: r.available_book_id,
                            start_date_list: this.selectedDates,
                            start_time: `${this.$refs.start_time.value}:00`,
                            end_time: `${this.$refs.end_time.value}:00`,
                            availability:
                                r.new_availability !== "" &&
                                !isNaN(r.new_availability)
                                    ? parseInt(r.new_availability)
                                    : parseInt(r.availability),
                            venue_type: r.venue_type,
                            book_type: r.book_type,
                            cross_day: r.cross_day
                        })
                    } else {
                        l.push({
                            mode: 1,
                            space_id: r.space_id,
                            space_type: r.space_type,
                            available_book_id: r.available_book_id,
                            start_date: this.startDateHour.replaceAll("/", "-"),
                            end_date: this.endDateHour.replaceAll("/", "-"),
                            start_time: `${this.$refs.start_time.value}:00`,
                            end_time: `${this.$refs.end_time.value}:00`,
                            availability:
                                r.new_availability !== "" &&
                                !isNaN(r.new_availability)
                                    ? parseInt(r.new_availability)
                                    : parseInt(r.availability),
                            venue_type: r.venue_type,
                            book_type: r.book_type,
                            cross_day: r.cross_day,
                            date: null
                        })
                    }
                }
            }
            if (l.length > 0) return l
        },
        availabilitySCheck(i) {
            let l = []
            let room = this.roomType[Object.keys(this.roomType)[i]].session
            for (let i = 0; i < Object.keys(room).length; i++) {
                if (room[Object.keys(room)[i]].new_availability >= 0) {
                    const r = room[Object.keys(room)[i]]
                    this.sessionMode
                        ? l.push({
                              mode: 0,
                              space_id: r.space_id,
                              space_type: r.space_type,
                              available_book_id: r.available_book_id,
                              start_date_list: this.selectedDatesSes,
                              start_time: r.start_time
                                  ? r.start_time
                                  : r.default_start_time,
                              end_time: r.end_time
                                  ? r.end_time
                                  : r.default_end_time,
                              availability:
                                  r.new_availability !== "" &&
                                  !isNaN(r.new_availability)
                                      ? parseInt(r.new_availability)
                                      : parseInt(r.availability),
                              venue_type: r.venue_type,
                              book_type: r.book_type,
                              cross_day: r.cross_day
                          })
                        : l.push({
                              mode: 1,
                              space_id: r.space_id,
                              space_type: r.space_type,
                              available_book_id: r.available_book_id,
                              start_date: this.startDate.replaceAll("/", "-"),
                              end_date: this.endDate.replaceAll("/", "-"),
                              start_time: r.start_time
                                  ? r.start_time
                                  : r.default_start_time,
                              end_time: r.end_time
                                  ? r.end_time
                                  : r.default_end_time,
                              availability:
                                  r.new_availability !== "" &&
                                  !isNaN(r.new_availability)
                                      ? parseInt(r.new_availability)
                                      : parseInt(r.availability),
                              venue_type: r.venue_type,
                              book_type: r.book_type,
                              cross_day: r.cross_day
                          })
                }
            }
            if (l.length > 0) return l
        },
        availabilityOCheck(i) {
            let l = []
            let room = this.roomType[Object.keys(this.roomType)[i]].overnight
            for (let i = 0; i < Object.keys(room).length; i++) {
                if (room[Object.keys(room)[i]].new_availability >= 0) {
                    const r = room[Object.keys(room)[i]]
                    this.sessionMode
                        ? l.push({
                              mode: 0,
                              space_id: r.space_id,
                              space_type: r.space_type,
                              available_book_id: r.available_book_id,
                              start_date_list: this.selectedDatesSes,
                              start_time: r.start_time
                                  ? r.start_time
                                  : r.default_start_time,
                              end_time: r.end_time
                                  ? r.end_time
                                  : r.default_end_time,
                              availability:
                                  r.new_availability !== "" &&
                                  !isNaN(r.new_availability)
                                      ? parseInt(r.new_availability)
                                      : parseInt(r.availability),
                              venue_type: r.venue_type,
                              book_type: r.book_type,
                              cross_day: r.cross_day
                          })
                        : l.push({
                              mode: 1,
                              space_id: r.space_id,
                              space_type: r.space_type,
                              available_book_id: r.available_book_id,
                              start_date: this.startDate.replaceAll("/", "-"),
                              end_date: this.endDate.replaceAll("/", "-"),
                              start_time: r.start_time
                                  ? r.start_time
                                  : r.default_start_time,
                              end_time: r.end_time
                                  ? r.end_time
                                  : r.default_end_time,
                              availability:
                                  r.new_availability !== "" &&
                                  !isNaN(r.new_availability)
                                      ? parseInt(r.new_availability)
                                      : parseInt(r.availability),
                              venue_type: r.venue_type,
                              book_type: r.book_type,
                              cross_day: r.cross_day
                          })
                }
            }
            if (l.length > 0) return l
        },
        getSpaceName(obj) {
            if (Object.keys(obj).includes("overnight")) {
                return obj.overnight[Object.keys(obj.overnight)[0]][
                    `name_${this.lang}`
                ]
            } else if (Object.keys(obj).includes("session")) {
                return obj.session[Object.keys(obj.session)[0]][
                    `name_${this.lang}`
                ]
            } else {
                return obj.hour[Object.keys(obj.hour)[0]][0][
                    `name_${this.lang}`
                ]
            }
        },
        getDaysBetween(str, end) {
            const listDate = []
            const startDate = str.replaceAll("/", "-")
            const endDate = end.replaceAll("/", "-")
            const dateMove = new Date(startDate)
            let strDate = startDate

            if (strDate === endDate) {
                strDate = dateMove.toISOString().slice(0, 10)
                listDate.push(strDate)
            }

            while (strDate < endDate) {
                strDate = dateMove.toISOString().slice(0, 10)
                listDate.push(strDate)
                dateMove.setDate(dateMove.getDate() + 1)
            }
            return listDate
        },
        reformat(event) {
            if (
                event.target.value.length === 2 &&
                event.keyCode !== 8 &&
                event.keyCode !== 46
            ) {
                event.target.value += ":"
            }
        },
        updateAvailabilityReq(data) {
            const url = `${process.env.VUE_APP_API_BASE_URL}/home/updateRoomAvailability`
            this.$axios
                .put(url, data)
                .then((r) => {
                    if (r.data.Success) {
                        this.isHourChanged = false
                        this.isSessionChanged = false
                        this.isOvernightChanged = false
                        this.resetMode()
                        this.getAvailability()
                        this.updateBtn = false
                        alert("Success!")
                    } else {
                        alert("An error has occured. Please try again.")
                        this.updateBtn = false
                    }
                })
                .catch((error) => {
                    this.updateBtn = false
                    if (error.response) {
                        error.response.data.data.error_msg
                            ? alert(error.response.data.data.error_msg)
                            : alert(error)
                        // Request made and server responded
                    } else if (error.request) {
                        // The request was made but no response was received
                    } else {
                        // Something happened in setting up the request that triggered an Error
                    }
                })
        },
        async getHolidayDate(val) {
            this.tempMonth = val
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/utils/getPublicHoliday?month=${val.month}&year=${
                val.year
            }&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }`
            const res = await this.$axios.get(url)
            this.calHoliday = res.data.data.public_holiday
        },
        selectStartDateH(val) {
            this.startDateHour = val
            this.startDateHour > this.endDateHour
                ? (this.endDateHour = val)
                : null
        },
        selectEndDateH(val) {
            this.endDateHour = val
        },
        checkPassMidnight(val) {
            this.$refs.start_time.value = val
            if (
                this.$refs.start_time.value &&
                this.$refs.end_time.value &&
                this.$refs.end_time.value < this.$refs.start_time.value
            ) {
                this.flexEndTime = 1
            } else this.flexEndTime = 0
        },
        checkPassMidnightEnd(val) {
            this.$refs.end_time.value = val
            if (
                this.$refs.start_time.value &&
                this.$refs.end_time.value &&
                this.$refs.end_time.value < this.$refs.start_time.value
            ) {
                this.flexEndTime = 1
            } else this.flexEndTime = 0
        },
        selectStartDate(val) {
            this.startDate = val
            this.startDate > this.endDate ? (this.endDate = val) : null
        },
        selectEndDate(val) {
            this.endDate = val
        },
        formatTimeString(str) {
            const timeStr = str.split("(")[0]
            let p1 = timeStr.split("-")[0].split(":", 2).join(":")
            let p2 = timeStr.split("-")[1].split(":", 2).join(":")
            return p1 + "-" + p2
        },
        async selectMultiDays(val) {
            let date = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }-${val.date > 9 ? val.date : "0" + val.date}`
            if (this.selectedDates.includes(date)) {
                this.selectedDates.splice(this.selectedDates.indexOf(date), 1)
            } else this.selectedDates.push(date)
            this.selectedDates.sort()
        },
        async selectMultiDaysSes(val) {
            let date = `${val.year}-${
                val.month > 9 ? val.month : "0" + val.month
            }-${val.date > 9 ? val.date : "0" + val.date}`
            if (this.selectedDatesSes.includes(date)) {
                this.selectedDatesSes.splice(
                    this.selectedDatesSes.indexOf(date),
                    1
                )
            } else this.selectedDatesSes.push(date)
            this.selectedDatesSes.sort()
        },
        resetMode() {
            this.flexibleMode = false
            this.sessionMode = false
            this.selectedDates = []
            this.selectedDatesSes = []
        },
        async getPriceData() {
            if (this.prevReq) {
                this.prevReq.abort()
            }
            this.prevReq = new AbortController()
            this.priceData = {}
            this.tableLoading = true
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/price/getPricePerDay/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&mode=${this.user.space_info_list[this.selectedSpace].mode}&date=${
                this.selectedDate
            }&lang=${this.$i18n.locale}&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }`
            const res = await this.$axios.get(url, {
                signal: this.prevReq.signal
            })
            this.priceData = res.data.data
        },
        sortObj(data) {
            return Object.keys(data)
                .sort()
                .reduce((obj, key) => {
                    obj[key] = data[key]
                    return obj
                }, {})
        }
    },
    watch: {
        selectedSpace(to, from) {
            this.selectAll = false
            this.showCalendar = false
            this.openIndex = []
            this.formatDate()
            if (this.showCalendar) {
                this.getHolidayDate(this.tempMonth)
            }
            this.resetMode()
            this.getAvailability()
            this.getPriceData()
        }
    },
    beforeRouteLeave(to, from, next) {
        if (
            this.isHourChanged ||
            this.isSessionChanged ||
            this.isOvernightChanged
        ) {
            const answer = window.confirm(
                "Changes are not saved yet. Do you want to leave this page?"
            )
            if (answer) {
                next()
            } else {
                next(false)
            }
        } else next()
    }
}
</script>

<style scoped></style>
