<template>
    <span class="text-red-600">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            class="
                stroke-current
                icon icon-tabler icon-tabler-cash-banknote-off
            "
            width="24"
            height="24"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#000000"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
        >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path
                d="M9.88 9.878a3 3 0 1 0 4.242 4.243m.58 -3.425a3.012 3.012 0 0 0 -1.412 -1.405"
            />
            <path
                d="M10 6h9a2 2 0 0 1 2 2v8c0 .294 -.064 .574 -.178 .825m-2.822 1.175h-13a2 2 0 0 1 -2 -2v-8a2 2 0 0 1 2 -2h1"
            />
            <line x1="18" y1="12" x2="18.01" y2="12" />
            <line x1="6" y1="12" x2="6.01" y2="12" />
            <line x1="3" y1="3" x2="21" y2="21" />
        </svg>
    </span>
</template>

<script>
export default {
    name: "NoCancel"
}
</script>

<style scoped></style>
