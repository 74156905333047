<template>
    <div class="p-8 mt-4 lg:mt-0 lg:px-20 desktop:px-36 bg-gray-100">
        <h2 class="flex font-medium text-2xl mb-5">
            {{ $t("booking.upcoming_booking") }}
        </h2>
        <div class="flex justify-center">
            <div
                class="bg-white mt-8 p-8 w-full max-w-7xl rounded-md shadow-lg"
            >
                <div
                    class="
                        hidden
                        lg:grid
                        grid-cols-2
                        w-full
                        grid-flow-row
                        gap-x-20
                    "
                >
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            {{ $t("booking.room_type") }}
                        </h2>
                        <select
                            v-model="roomType"
                            @change="getBookings"
                            class="select-dropdown w-full"
                        >
                            <option value="all">{{ $t("booking.all") }}</option>
                            <option
                                v-for="(type, index) in user.space_info_list[
                                    selectedSpace
                                ].venue_type_list"
                                :key="index"
                                :value="type.venue_type"
                            >
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            {{ $t("booking.book_type") }}
                        </h2>
                        <select
                            v-model="bookType"
                            @change="getBookings"
                            class="select-dropdown w-full"
                        >
                            <option value="all">{{ $t("booking.all") }}</option>
                            <option
                                v-for="(type, index) in user.space_info_list[
                                    selectedSpace
                                ].book_type_list"
                                :key="index"
                                :value="type"
                            >
                                {{ getBookType(type) }}
                            </option>
                        </select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            Distribution Type
                        </h2>
                        <select
                            v-model="distributionType"
                            @change="changeDistribution"
                            class="select-dropdown w-full"
                        >
                            <option selected value="all">
                                {{ $t("booking.all") }}
                            </option>
                            <option value="flow">Flow</option>
                            <option value="external">External</option>
                        </select>
                    </div>
                </div>
                <div class="block lg:hidden w-full">
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            {{ $t("booking.room_type") }}
                        </h2>
                        <select
                            v-model="roomType"
                            @change="getBookings"
                            class="select-dropdown w-full"
                        >
                            <option value="all">{{ $t("booking.all") }}</option>
                            <option
                                v-for="(type, index) in user.space_info_list[
                                    selectedSpace
                                ].venue_type_list"
                                :key="index"
                                :value="type.venue_type"
                            >
                                {{ type.name }}
                            </option>
                        </select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            {{ $t("booking.book_type") }}
                        </h2>
                        <select
                            v-model="bookType"
                            @change="getBookings"
                            class="select-dropdown w-full"
                        >
                            <option value="all">{{ $t("booking.all") }}</option>
                            <option
                                v-for="(type, index) in user.space_info_list[
                                    selectedSpace
                                ].book_type_list"
                                :key="index"
                                :value="type"
                            >
                                {{ getBookType(type) }}
                            </option>
                        </select>
                    </div>
                    <div class="font-medium text-xl mb-5">
                        <h2 class="border-b border-gray-200 mb-4">
                            Distribution Type
                        </h2>
                        <select
                            v-model="distributionType"
                            @change="getBookings"
                            class="select-dropdown w-full"
                        >
                            <option selected value="all">
                                {{ $t("booking.all") }}
                            </option>
                            <option value="flow">Flow</option>
                            <option value="external">External</option>
                        </select>
                    </div>
                </div>
                <Legend :type="1" />
                <div v-if="!isLoading">
                    <div
                        class="border-gray-300 py-4"
                        :class="{
                            'border-b':
                                index < bookings.length - 1 &&
                                !booking.property_collect &&
                                !booking.distributor,
                            'bg-blue-100 my-2 py-2': booking.distributor,
                            'bg-red-100 my-2 py-2': booking.property_collect
                        }"
                        v-for="(booking, index) in bookings"
                        :key="index"
                    >
                        <div class="hidden lg:grid grid-cols-5 items-center">
                            <div class="flex items-center">
                                <div class="font-medium text-3xl mr-6">
                                    {{ index + 1 }}
                                </div>
                                <div>
                                    <div>
                                        {{ parseDate(booking.occupy_in) }}
                                    </div>
                                    <div>
                                        {{ parseDate(booking.occupy_out) }}
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon icon-tabler icon-tabler-user
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <circle cx="12" cy="7" r="4" />
                                        <path
                                            d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                        /></svg
                                    >{{
                                        booking.given_name || booking.surname
                                            ? `${booking.given_name} ${booking.surname}`
                                            : "User"
                                    }}
                                </div>
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon icon-tabler icon-tabler-bed
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"
                                        />
                                        <circle cx="7" cy="10" r="1" /></svg
                                    >{{ booking.name }}
                                </div>
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon
                                            icon-tabler
                                            icon-tabler-calendar-time
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
                                        />
                                        <circle cx="18" cy="18" r="4" />
                                        <path d="M15 3v4" />
                                        <path d="M7 3v4" />
                                        <path d="M3 11h16" />
                                        <path d="M18 16.496v1.504l1 1" /></svg
                                    >{{ booking.book_type_name }}
                                </div>
                            </div>
                            <div class="flex items-center justify-center">
                                <div
                                    v-if="
                                        (booking.refund_min ||
                                            booking.refund_min === 0) &&
                                        booking.refund_min >= 0
                                    "
                                >
                                    <FreeCancel class="text-green-600" />
                                </div>
                                <div v-else><NoCancel /></div>
                                <div v-if="booking.promos" class="ml-1">
                                    <button
                                        @click="upgradeIndex = index"
                                        class="
                                            p-0.5
                                            border border-orange-400
                                            text-orange-400
                                            hover:border-orange-300
                                            hover:text-orange-300
                                            shadow-md
                                            rounded-md
                                            transition
                                            duration-200
                                            ease-linear
                                        "
                                    >
                                        <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            class="
                                                stroke-current
                                                icon
                                                icon-tabler
                                                icon-tabler-arrow-up-circle
                                            "
                                            width="24"
                                            height="24"
                                            viewBox="0 0 24 24"
                                            stroke-width="1.5"
                                            stroke="#000000"
                                            fill="none"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                        >
                                            <path
                                                stroke="none"
                                                d="M0 0h24v24H0z"
                                                fill="none"
                                            />
                                            <circle cx="12" cy="12" r="9" />
                                            <line
                                                x1="12"
                                                y1="8"
                                                x2="8"
                                                y2="12"
                                            />
                                            <line
                                                x1="12"
                                                y1="8"
                                                x2="12"
                                                y2="16"
                                            />
                                            <line
                                                x1="16"
                                                y1="12"
                                                x2="12"
                                                y2="8"
                                            />
                                        </svg>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div
                                    class="flex flex-col mx-8"
                                    v-if="
                                        booking.state === '0' &&
                                        !booking.distributor
                                    "
                                >
                                    <button
                                        @click="actionPending(booking, 1)"
                                        :disabled="
                                            actionPendingBtn.includes(
                                                booking.reserve_id
                                            )
                                        "
                                        class="
                                            bg-green-500
                                            text-gray-100
                                            py-1
                                            px-2
                                            mb-2
                                            rounded-md
                                            shadow-md
                                            hover:bg-green-400 hover:text-white
                                            transition
                                            duration-300
                                            ease-in-out
                                        "
                                        :class="{
                                            'filter grayscale':
                                                actionPendingBtn.includes(
                                                    booking.reserve_id
                                                )
                                        }"
                                    >
                                        {{ $t("booking.accept") }}
                                    </button>
                                    <button
                                        @click="actionPending(booking, 2)"
                                        :disabled="
                                            actionPendingBtn.includes(
                                                booking.reserve_id
                                            )
                                        "
                                        class="
                                            bg-red-400
                                            text-gray-100
                                            py-1
                                            px-2
                                            rounded-md
                                            shadow-md
                                            hover:bg-red-300 hover:text-white
                                            transition
                                            duration-300
                                            ease-in-out
                                        "
                                        :class="{
                                            'filter grayscale':
                                                actionPendingBtn.includes(
                                                    booking.reserve_id
                                                )
                                        }"
                                    >
                                        {{ $t("booking.reject") }}
                                    </button>
                                </div>
                                <div
                                    class="flex flex-col mx-8"
                                    v-else-if="
                                        booking.state === '0' &&
                                        booking.distributor
                                    "
                                >
                                    <span
                                        class="
                                            bg-gray-500
                                            text-white
                                            px-2
                                            py-1
                                            rounded-md
                                            text-sm text-center
                                        "
                                        >Await for confirmation</span
                                    >
                                </div>
                                <div
                                    v-if="
                                        booking.state === '1' &&
                                        !booking.distributor
                                    "
                                    class="flex flex-col mx-8"
                                >
                                    <button
                                        v-if="mode && mode === 1"
                                        :disabled="
                                            !canCheckIn(
                                                booking.occupy_in,
                                                booking.occupy_out
                                            ) ||
                                            booking.check_in ||
                                            actionConfirmedBtn.includes(
                                                booking.reserve_id
                                            )
                                        "
                                        @click="actionConfirmed(booking, 1)"
                                        class="
                                            bg-yellow-400
                                            text-gray-100
                                            py-1
                                            px-2
                                            mb-2
                                            rounded-md
                                            shadow-md
                                            hover:bg-yellow-300 hover:text-white
                                            transition
                                            duration-300
                                            ease-in-out
                                        "
                                        :class="{
                                            'filter grayscale':
                                                !canCheckIn(
                                                    booking.occupy_in,
                                                    booking.occupy_out
                                                ) ||
                                                booking.check_in ||
                                                actionConfirmedBtn.includes(
                                                    booking.reserve_id
                                                )
                                        }"
                                    >
                                        CHECK-IN
                                    </button>
                                    <button
                                        v-if="mode && mode === 1"
                                        :disabled="
                                            !canCheckOut(
                                                booking.occupy_in,
                                                booking.occupy_out
                                            ) ||
                                            booking.check_out ||
                                            actionConfirmedBtn.includes(
                                                booking.reserve_id
                                            )
                                        "
                                        @click="actionConfirmed(booking, 2)"
                                        class="
                                            bg-yellow-400
                                            text-gray-100
                                            py-1
                                            px-2
                                            mb-2
                                            rounded-md
                                            shadow-md
                                            hover:bg-yellow-300 hover:text-white
                                            transition
                                            duration-300
                                            ease-in-out
                                        "
                                        :class="{
                                            'filter grayscale':
                                                !canCheckOut(
                                                    booking.occupy_in,
                                                    booking.occupy_out
                                                ) ||
                                                booking.check_out ||
                                                actionConfirmedBtn.includes(
                                                    booking.reserve_id
                                                )
                                        }"
                                    >
                                        EARLY CHECK-OUT
                                    </button>
                                </div>
                                <div
                                    class="flex justify-center"
                                    v-if="
                                        booking.state !== '1' &&
                                        booking.state !== '0'
                                    "
                                >
                                    <span
                                        class="
                                            text-center
                                            px-5
                                            py-2
                                            rounded-md
                                            shadow-md
                                            bg-gray-500
                                            text-white
                                        "
                                        >{{
                                            getBookingState(booking.state)
                                        }}</span
                                    >
                                </div>
                            </div>
                            <div class="flex flex-col items-center">
                                <img
                                    v-if="!booking.is_ourroom"
                                    class="h-14 w-auto"
                                    alt="platform logo"
                                    src="https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                                />
                                <img
                                    v-if="booking.is_ourroom"
                                    class="h-14 w-auto"
                                    alt="platform logo"
                                    src="https://site.flowtheroom.com/hotel/public/assets/img/ouroom%20logo-square%201.png"
                                />
                                <div
                                    class="text-blue-500"
                                    v-if="
                                        booking.state !== 0 &&
                                        !booking.distributor
                                    "
                                >
                                    {{ booking.booking_id }} /
                                    {{ booking.confirmation_code }}
                                </div>
                                <div
                                    class="text-blue-500"
                                    v-if="
                                        booking.state !== 0 &&
                                        booking.distributor
                                    "
                                >
                                    {{ booking.confirmation_code }} /
                                    {{ booking.booking_id }}
                                </div>
                                <div class="text-blue-500" v-else>TBC</div>
                                <div class="flex">
                                    <div
                                        class="
                                            bg-red-400
                                            text-white
                                            rounded-md
                                            px-2
                                        "
                                        :class="{
                                            'filter grayscale line-through':
                                                booking.state !== '0' &&
                                                booking.state !== '1'
                                        }"
                                    >
                                        ${{ booking.book_price }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <!--                        Mobile-->
                        <div class="block lg:hidden">
                            <div class="flex items-center">
                                <span class="mr-2 text-3xl font-medium">{{
                                    index + 1
                                }}</span
                                >{{ parseDate(booking.occupy_in) }} -
                                {{ parseDate(booking.occupy_out) }}
                            </div>
                            <div class="mt-4">
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon icon-tabler icon-tabler-user
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <circle cx="12" cy="7" r="4" />
                                        <path
                                            d="M6 21v-2a4 4 0 0 1 4 -4h4a4 4 0 0 1 4 4v2"
                                        /></svg
                                    >{{
                                        booking.given_name || booking.surname
                                            ? `${booking.given_name} ${booking.surname}`
                                            : "User"
                                    }}
                                </div>
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon icon-tabler icon-tabler-bed
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6"
                                        />
                                        <circle cx="7" cy="10" r="1" /></svg
                                    >{{ booking.name }}
                                </div>
                                <div class="flex items-center">
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        class="
                                            icon
                                            icon-tabler
                                            icon-tabler-calendar-time
                                            mr-2
                                        "
                                        width="20"
                                        height="20"
                                        viewBox="0 0 24 24"
                                        stroke-width="1.5"
                                        stroke="#000000"
                                        fill="none"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                    >
                                        <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                        />
                                        <path
                                            d="M11.795 21h-6.795a2 2 0 0 1 -2 -2v-12a2 2 0 0 1 2 -2h12a2 2 0 0 1 2 2v4"
                                        />
                                        <circle cx="18" cy="18" r="4" />
                                        <path d="M15 3v4" />
                                        <path d="M7 3v4" />
                                        <path d="M3 11h16" />
                                        <path d="M18 16.496v1.504l1 1" /></svg
                                    >{{ booking.book_type_name }}
                                </div>
                                <div class="flex items-center mt-1">
                                    <div
                                        v-if="
                                            (booking.refund_min ||
                                                booking.refund_min === 0) &&
                                            booking.refund_min >= 0
                                        "
                                    >
                                        <FreeCancel class="text-green-600" />
                                    </div>
                                    <div v-else><NoCancel /></div>
                                    <div v-if="booking.promos" class="ml-1">
                                        <button
                                            @click="upgradeIndex = index"
                                            class="
                                                p-0.5
                                                border border-orange-400
                                                text-orange-400
                                                hover:border-orange-300
                                                hover:text-orange-300
                                                shadow-md
                                                rounded-md
                                                transition
                                                duration-200
                                                ease-linear
                                            "
                                        >
                                            <svg
                                                xmlns="http://www.w3.org/2000/svg"
                                                class="
                                                    stroke-current
                                                    icon
                                                    icon-tabler
                                                    icon-tabler-arrow-up-circle
                                                "
                                                width="24"
                                                height="24"
                                                viewBox="0 0 24 24"
                                                stroke-width="1.5"
                                                stroke="#000000"
                                                fill="none"
                                                stroke-linecap="round"
                                                stroke-linejoin="round"
                                            >
                                                <path
                                                    stroke="none"
                                                    d="M0 0h24v24H0z"
                                                    fill="none"
                                                />
                                                <circle cx="12" cy="12" r="9" />
                                                <line
                                                    x1="12"
                                                    y1="8"
                                                    x2="8"
                                                    y2="12"
                                                />
                                                <line
                                                    x1="12"
                                                    y1="8"
                                                    x2="12"
                                                    y2="16"
                                                />
                                                <line
                                                    x1="16"
                                                    y1="12"
                                                    x2="12"
                                                    y2="8"
                                                />
                                            </svg>
                                        </button>
                                    </div>
                                </div>
                                <div class="my-3">
                                    <div
                                        class="flex flex-col mx-8"
                                        v-if="
                                            booking.state === '0' &&
                                            !booking.distributor
                                        "
                                    >
                                        <button
                                            @click="actionPending(booking, 1)"
                                            class="
                                                bg-green-500
                                                text-gray-100
                                                py-1
                                                px-2
                                                mb-2
                                                rounded-md
                                                shadow-md
                                                hover:bg-green-400
                                                hover:text-white
                                                transition
                                                duration-300
                                                ease-in-out
                                            "
                                        >
                                            {{ $t("booking.accept") }}
                                        </button>
                                        <button
                                            @click="actionPending(booking, 2)"
                                            class="
                                                bg-red-400
                                                text-gray-100
                                                py-1
                                                px-2
                                                rounded-md
                                                shadow-md
                                                hover:bg-red-300
                                                hover:text-white
                                                transition
                                                duration-300
                                                ease-in-out
                                            "
                                        >
                                            {{ $t("booking.reject") }}
                                        </button>
                                    </div>
                                    <div
                                        class="flex flex-col mx-8"
                                        v-else-if="
                                            booking.state === '0' &&
                                            booking.distributor
                                        "
                                    >
                                        <span
                                            class="
                                                bg-gray-500
                                                text-white
                                                px-2
                                                py-1
                                                rounded-md
                                                text-sm text-center
                                            "
                                            >Await for confirmation</span
                                        >
                                    </div>
                                    <div
                                        v-if="
                                            booking.state === '1' &&
                                            !booking.distributor
                                        "
                                        class="flex flex-col mx-8"
                                    >
                                        <button
                                            v-if="mode && mode === 1"
                                            :disabled="
                                                !canCheckIn(
                                                    booking.occupy_in,
                                                    booking.occupy_out
                                                ) || booking.check_in
                                            "
                                            @click="actionConfirmed(booking, 1)"
                                            class="
                                                bg-yellow-400
                                                text-gray-100
                                                py-1
                                                px-2
                                                mb-2
                                                rounded-md
                                                shadow-md
                                                hover:bg-yellow-300
                                                hover:text-white
                                                transition
                                                duration-300
                                                ease-in-out
                                            "
                                            :class="{
                                                'filter grayscale':
                                                    !canCheckIn(
                                                        booking.occupy_in,
                                                        booking.occupy_out
                                                    ) || booking.check_in
                                            }"
                                        >
                                            CHECK-IN
                                        </button>
                                        <button
                                            v-if="mode && mode === 1"
                                            :disabled="
                                                !canCheckOut(
                                                    booking.occupy_in,
                                                    booking.occupy_out
                                                ) || booking.check_out
                                            "
                                            @click="actionConfirmed(booking, 2)"
                                            class="
                                                bg-yellow-400
                                                text-gray-100
                                                py-1
                                                px-2
                                                mb-2
                                                rounded-md
                                                shadow-md
                                                hover:bg-yellow-300
                                                hover:text-white
                                                transition
                                                duration-300
                                                ease-in-out
                                            "
                                            :class="{
                                                'filter grayscale':
                                                    !canCheckOut(
                                                        booking.occupy_in,
                                                        booking.occupy_out
                                                    ) || booking.check_out
                                            }"
                                        >
                                            EARLY CHECK-OUT
                                        </button>
                                    </div>
                                    <div
                                        class="flex justify-center"
                                        v-if="
                                            booking.state !== '1' &&
                                            booking.state !== '0'
                                        "
                                    >
                                        <span
                                            class="
                                                text-center
                                                px-5
                                                py-2
                                                rounded-md
                                                shadow-md
                                                bg-gray-500
                                                text-white
                                            "
                                            >{{
                                                getBookingState(booking.state)
                                            }}</span
                                        >
                                    </div>
                                </div>

                                <div class="flex flex-col items-center">
                                    <img
                                        v-if="!booking.is_ourroom"
                                        class="h-12 w-auto"
                                        alt="platform logo"
                                        src="https://site.flowtheroom.com/hotel/public/assets/img/246x0w.jpg"
                                    />
                                    <img
                                        v-if="booking.is_ourroom"
                                        class="h-12 w-auto"
                                        alt="platform logo"
                                        src="https://site.flowtheroom.com/hotel/public/assets/img/ouroom%20logo-square%201.png"
                                    />
                                    <div
                                        class="text-blue-500"
                                        v-if="
                                            booking.state !== 0 &&
                                            !booking.distributor
                                        "
                                    >
                                        {{ booking.booking_id }} /
                                        {{ booking.confirmation_code }}
                                    </div>
                                    <div
                                        class="text-blue-500"
                                        v-if="
                                            booking.state !== 0 &&
                                            booking.distributor
                                        "
                                    >
                                        {{ booking.confirmation_code }} /
                                        {{ booking.booking_id }}
                                    </div>
                                    <div class="text-blue-500" v-else>TBC</div>
                                    <div
                                        class="text-white rounded-md px-2"
                                        :class="{
                                            'bg-gray-500 line-through':
                                                booking.state !== '0' &&
                                                booking.state !== '1',
                                            '                                        bg-red-400':
                                                booking.state === '0' ||
                                                booking.state === '1'
                                        }"
                                    >
                                        ${{ booking.book_price }}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            v-if="booking.distributor"
                            class="text-blue-600 mt-3 -mb-2 lg:mt-0 lg:-my-2"
                        >
                            ** This booking information has been automatically
                            imported to hotel
                        </div>
                        <div
                            v-if="booking.property_collect"
                            class="text-red-700 mt-3 -mb-2 lg:mt-0 lg:-my-2"
                        >
                            ** This is a Property Collect booking. Please
                            collect ${{ booking.book_price.toFixed(2) }}
                            from the guest directly upon check-in .
                        </div>
                    </div>
                </div>
                <div
                    v-else
                    class="relative flex justify-center items-center py-8"
                >
                    <Loader :type="'lg'" />
                </div>
                <div class="flex justify-center items-center">
                    <button
                        @click="pageAction(0)"
                        class="px-1 rounded-l border border-gray-400"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-chevron-left"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#9e9e9e"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="15 6 9 12 15 18" />
                        </svg></button
                    ><span class="px-3 border-gray-400 border-t border-b">{{
                        currentPage
                    }}</span>
                    <button
                        @click="pageAction(1)"
                        class="px-1 rounded-r border border-gray-400"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="icon icon-tabler icon-tabler-chevron-right"
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            stroke-width="1.5"
                            stroke="#9e9e9e"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <polyline points="9 6 15 12 9 18" />
                        </svg>
                    </button>
                </div>
            </div>
        </div>
        <Modal v-if="upgradeIndex >= 0"
            ><slot
                ><div>
                    <h2 class="border-b border-gray-200 mb-4 text-xl">
                        Promotions:
                    </h2>
                    {{ bookings[upgradeIndex].promos.free_upgrade }}
                    <div class="flex mt-2">
                        <button
                            @click="upgradeIndex = -1"
                            class="cfn-btn px-4 py-2 w-full"
                            :class="{ 'filter grayscale': noshowBtn }"
                            :disabled="noshowBtn"
                        >
                            OK
                        </button>
                    </div>
                </div></slot
            ></Modal
        >
    </div>
    <!--    <UpcomingBookingLoader v-else />-->
</template>

<script>
import UpcomingBookingLoader from "@/components/SkeletonLoader/UpcomingBookingLoader"
import Loader from "@/components/Loader"
import Legend from "@/components/Legend"
import FreeCancel from "@/components/Svg/FreeCancel"
import Modal from "@/components/Modal"
import NoCancel from "@/components/Svg/NoCancel"
export default {
    name: "UpcomingBooking",
    components: {
        NoCancel,
        Modal,
        FreeCancel,
        Legend,
        Loader,
        UpcomingBookingLoader
    },
    props: {
        user: { required: true, type: Object },
        selectedSpace: { required: true, type: Number },
        mode: { required: false, type: Number }
    },
    data() {
        return {
            isLoading: true,
            currentPage: 1,
            bookType: "all",
            roomType: "all",
            distributionType: "all",
            bookings: [
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 0,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: null,
                //     booking_id: null,
                //     price: 210
                // },
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 1,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: "48ET",
                //     booking_id: "30914235",
                //     price: 210
                // },
                // {
                //     occupy_in: "Nov 13, 2021 12:15 AM",
                //     occupy_out: "Nov 13, 2021 02:15 AM",
                //     user: "Test test",
                //     room_type: "Double Room without Windows",
                //     session_type: "Flexible Session ( 2 Hours )",
                //     state: 2,
                //     payment_url:
                //         "https://site.flowhk.app/hotel/public/assets/img/246x0w.jpg",
                //     confirmation_code: "48ET",
                //     booking_id: "30914235",
                //     price: 210
                // }
            ],
            upgradeIndex: -1,
            actionPendingBtn: [],
            actionConfirmedBtn: []
        }
    },
    methods: {
        async getBookings() {
            const url = `${
                process.env.VUE_APP_API_BASE_URL
            }/upcomingBooking/getUpcomingBooking/${
                this.user.space_info_list[this.selectedSpace].space_id
            }?space_type=${
                this.user.space_info_list[this.selectedSpace].space_type
            }&country_code=${
                this.user.space_info_list[this.selectedSpace].country_code
            }&lang=${this.$i18n.locale}&page_size=50&current_page=${
                this.currentPage
            }${this.roomType !== "all" ? `&room_type=${this.roomType}` : ""}${
                this.bookType !== "all" ? `&book_type=${this.bookType}` : ""
            }${
                this.distributionType !== "all"
                    ? `&distributor=${this.distributionType}`
                    : ""
            }`
            this.isLoading = true
            const res = await this.$axios.get(url)
            this.bookings = res.data.data
            this.isLoading = false
            const url2 = `${
                process.env.VUE_APP_API_BASE_URL
            }/utils/removeNewReservation?space_id_list=${
                this.user.space_info_list[this.selectedSpace].space_id
            }`
            const res2 = await this.$axios.put(url2)
        },
        getBookingState(num) {
            num = parseInt(num)
            switch (num) {
                case 0:
                    return "Pending"
                case 1:
                    return "Confirmed"
                case 3:
                case 4:
                    return this.$t("booking.rejected")
                case 9:
                    return "Cancelled"
                case 12:
                    return "No show"
            }
        },
        showAlert: () => {
            alert("Success")
        },
        getBookType(string) {
            string = string.toLowerCase()
            switch (string) {
                case "hour":
                    return "Flexible Session"
                case "session":
                    return "Session"
                case "overnight":
                    return "Overnight"
                case "hourly":
                    return "Hourly"
                case "day":
                    return "Day Pass"
            }
        },
        async actionPending(booking, action) {
            if (this.mode === 2)
                return alert(
                    "Not enabled for party room yet. Please use telegram for now."
                )
            this.actionPendingBtn.push(booking.reserve_id)
            const url = `${process.env.VUE_APP_API_BASE_URL}/upcomingBooking/acceptOrRejectUpcomingBooking`
            const data = {
                country_code: booking.country_code,
                space_type: booking.space_type,
                action: action, // 1: accept 2: reject
                reserve_id: booking.reserve_id,
                space_id: booking.space_id,
                occupy_in: booking.occupy_in,
                occupy_out: booking.occupy_out,
                break_finish: booking.break_finish
            }
            const res = await this.$axios.put(url, data).catch((error) => {
                this.actionPendingBtn.splice(
                    this.actionPendingBtn.indexOf(booking.reserve_id),
                    1
                )
                if (error.response) {
                    // Request made and server responded
                    alert(error.response.data.data.err_message)
                } else if (error.request) {
                    // The request was made but no response was received
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
            if (res.data.Success) {
                this.actionPendingBtn.splice(
                    this.actionPendingBtn.indexOf(booking.reserve_id),
                    1
                )
                alert("Success")
                await this.getBookings()
            } else {
                this.actionPendingBtn.splice(
                    this.actionPendingBtn.indexOf(booking.reserve_id),
                    1
                )
            }
        },
        async actionConfirmed(booking, action) {
            if (this.mode === 2) return alert("Not enabled for party room yet.")
            this.actionConfirmedBtn.push(booking.reserve_id)
            const url = `${process.env.VUE_APP_API_BASE_URL}/upcomingBooking/checkInOrEarlyCheckOut`
            const data = {
                country_code: booking.country_code,
                space_type: booking.space_type,
                action: action, //1:checkin  2: early checkout
                reserve_id: booking.reserve_id,
                space_id: booking.space_id
            }
            const res = await this.$axios.put(url, data).catch((error) => {
                this.actionConfirmedBtn.splice(
                    this.actionConfirmedBtn.indexOf(booking.reserve_id),
                    1
                )
                if (error.response) {
                    // Request made and server responded
                    alert(error.response.data.data.err_message)
                } else if (error.request) {
                    // The request was made but no response was received
                } else {
                    // Something happened in setting up the request that triggered an Error
                }
            })
            if (res.data.Success) {
                this.actionConfirmedBtn.splice(
                    this.actionConfirmedBtn.indexOf(booking.reserve_id),
                    1
                )
                alert("Success")
                await this.getBookings()
            } else {
                this.actionConfirmedBtn.splice(
                    this.actionConfirmedBtn.indexOf(booking.reserve_id),
                    1
                )
            }
        },
        parseDate(time) {
            const d = new Date(time.replaceAll(" ", "T"))
            let year = d.getFullYear()
            let month = d.getMonth() + 1
            let date = d.getDate()
            let hour = d.getHours()
            let minute = d.getMinutes()
            month < 10 ? (month = "0" + month) : month
            date < 10 ? (date = "0" + date) : date
            hour < 10 ? (hour = "0" + hour) : hour
            minute < 10 ? (minute = "0" + minute) : minute
            return `${year}-${month}-${date} ${hour}:${minute}`
        },
        canCheckIn(time, time2) {
            let originTime = new Date(time).getTime()
            let OneHour = new Date(time).getTime() - 60 * 60 * 1000
            let accessTime = new Date(this.user.access_time).getTime()
            let checkoutTime = new Date(time2).getTime()

            if (accessTime >= OneHour && checkoutTime >= accessTime) {
                return true
            }
        },
        canCheckOut(time1, time2) {
            let originTime = new Date(time1).getTime()
            let checkoutTime = new Date(time2).getTime()
            let accessTime = new Date(this.user.access_time).getTime()
            if (checkoutTime >= accessTime && accessTime >= originTime) {
                return true
            }
        },
        pageAction(action) {
            if (action === 0) {
                if (this.currentPage > 1) {
                    this.currentPage--
                    this.getBookings()
                }
            }
            if (action === 1) {
                this.currentPage++
                this.getBookings()
            }
        },
        changeDistribution() {
            this.saveDistributionType()
            this.getBookings()
        },
        saveDistributionType() {
            return localStorage.setItem(
                "Upcoming-Distribution",
                this.distributionType
            )
        },
        getDistributionType() {
            return localStorage.getItem("Upcoming-Distribution")
        }
    },
    mounted() {
        let distribution = this.getDistributionType()
        if (distribution) {
            this.distributionType = distribution
        }
        this.getBookings()
    },
    watch: {
        selectedSpace(to, from) {
            this.getBookings()
        }
    }
}
</script>

<style scoped></style>
